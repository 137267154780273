// frontend/src/context/AuthContext.js

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {jwtDecode} from 'jwt-decode'; // Correção na importação
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';

// Criação do contexto de autenticação
export const AuthContext = createContext();

// Hook personalizado para acessar o contexto de autenticação
export const useAuth = () => useContext(AuthContext);

// Estilos para o Modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// Provedor do contexto de autenticação
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: null,
    token: null,
  });

  const [loading, setLoading] = useState(true); // Estado de carregamento

  // Estados para o Modal e Snackbar de aviso
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Referências para os temporizadores
  const idleTimerRef = useRef(null);
  const logoutTimerRef = useRef(null);

  // Função para logout
  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setAuth({
      isAuthenticated: false,
      user: null,
      token: null,
    });
    console.log('🔓 [AuthContext]: Usuário deslogado e contexto resetado.');
    navigate('/login'); // Redireciona para a página de login após logout
  }, [navigate]);

  // Função para atualizar qualquer campo do usuário
  const updateUser = (updatedFields) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      user: {
        ...prevAuth.user,
        ...updatedFields,
      },
    }));
  };

  // Funções específicas que utilizam a função genérica
  const updateUserName = (newName) => updateUser({ nome: newName });
  const updateUserSurname = (newSurname) => updateUser({ sobrenome: newSurname });
  const updateUserEmail = (newEmail) => updateUser({ email: newEmail });

  // Função para atualizar o status da assinatura do usuário
  const updateSubscriptionStatus = (status) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      user: {
        ...prevAuth.user,
        status: status,
      },
    }));
  };

  // Função para atualizar o token de autenticação
  const updateAuthToken = (newToken) => {
    try {
      const decoded = jwtDecode(newToken);
      console.log('🔄 [AuthContext]: Decoded token:', decoded);
      setAuth((prevAuth) => ({
        ...prevAuth,
        token: newToken,
        user: {
          ...prevAuth.user,
          userId: decoded.userId || decoded.id, // Mapeando 'id' para 'userId'
          email: decoded.email,
          funcao: decoded.funcao,
          nome: decoded.nome,
          sobrenome: decoded.sobrenome,
          status: decoded.status || 'free', // Status 'free' por padrão
        },
        isAuthenticated: true,
      }));
      localStorage.setItem('token', newToken);
      console.log(
        '✅ [AuthContext]: Token atualizado e contexto de autenticação atualizado.'
      );
    } catch (error) {
      console.error(
        '❌ [AuthContext]: Erro ao decodificar o novo token:',
        error
      );
      logout();
    }
  };

  // Função para buscar dados atualizados do usuário no backend
  const refreshUser = async (token) => {
    try {
      const response = await fetch('https://medchoices.com.br:3001/me', {
        // Substitua pela sua rota real
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.success && data.user) {
        // Mapear 'id' para 'userId' no usuário retornado
        const updatedUser = {
          userId: data.user.id, // Mapeando 'id' para 'userId'
          email: data.user.email,
          funcao: data.user.funcao,
          nome: data.user.nome,
          sobrenome: data.user.sobrenome,
          status: data.user.status || 'free', // Status 'free' por padrão
          // Adicione outros campos se necessário
        };

        setAuth((prevAuth) => ({
          ...prevAuth,
          isAuthenticated: true,
          user: updatedUser, // Atualiza os dados do usuário no contexto
          token: token,
        }));
        console.log('✅ [AuthContext]: Dados do usuário atualizados:', updatedUser);
      } else {
        console.warn(
          '⚠️ [AuthContext]: Falha ao buscar dados do usuário:',
          data.message
        );
        logout();
      }
    } catch (error) {
      console.error('❌ [AuthContext]: Erro ao buscar dados do usuário:', error);
      logout();
    }
  };

  // Função para login
  const login = (token) => {
    try {
      const decoded = jwtDecode(token);
      console.log('🔄 [AuthContext]: Decoded login token:', decoded);
      setAuth({
        isAuthenticated: true,
        user: {
          userId: decoded.userId || decoded.id, // Mapeando 'id' para 'userId'
          email: decoded.email,
          funcao: decoded.funcao,
          nome: decoded.nome,
          sobrenome: decoded.sobrenome,
          status: decoded.status || 'free', // Status 'free' por padrão
        },
        token: token,
      });
      localStorage.setItem('token', token);
      console.log('✅ [AuthContext]: Usuário logado e contexto atualizado.');
      // Após login, buscar dados atualizados
      refreshUser(token);
    } catch (error) {
      console.error(
        '❌ [AuthContext]: Erro ao decodificar o token durante o login:',
        error
      );
      logout();
    }
  };

  // Função para estender a sessão (resetar o temporizador)
  const extendSession = () => {
    setOpenModal(false);
    resetIdleTimer();
    localStorage.setItem('extend-session', Date.now()); // Sincroniza entre abas
  };

  // Função para mostrar o modal de aviso
  const showIdleWarning = () => {
    setOpenModal(true);
  };

  // Função para efetuar o logout após aviso
  const performLogout = () => {
    setOpenModal(false);
    logout();
    setOpenSnackbar(true); // Exibe o snackbar informando sobre o logout automático
  };

  // Função para resetar o temporizador de inatividade
  const resetIdleTimer = () => {
    if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
    if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current);

    // Configura o temporizador para mostrar o aviso após 4 minutos e 30 segundos
    idleTimerRef.current = setTimeout(() => {
      showIdleWarning();
      // Configura o temporizador para efetuar o logout após mais 30 segundos
      logoutTimerRef.current = setTimeout(() => {
        performLogout();
      }, 30 * 1000); // 30 segundos
    }, 4.5 * 60 * 1000); // 4 minutos e 30 segundos
  };

  // useEffect para implementar o auto-logout com aviso
  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

    const handleActivity = () => {
      resetIdleTimer();
      // Atualiza o tempo de sessão nas outras abas
      localStorage.setItem('extend-session', Date.now());
    };

    if (auth.isAuthenticated) {
      // Adiciona os listeners de eventos
      events.forEach((event) => {
        window.addEventListener(event, handleActivity);
      });

      // Inicia o temporizador
      resetIdleTimer();
    }

    // Cleanup: remove os listeners e limpa os temporizadores
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
      if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, logout]);

  // useEffect para sincronizar a extensão da sessão entre abas
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'extend-session') {
        resetIdleTimer();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect para verificar o token no carregamento inicial
  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decoded = jwtDecode(token);
          console.log('🔄 [AuthContext]: Decoded initialize token:', decoded);
          const currentTime = Date.now() / 1000;

          if (decoded.exp < currentTime) {
            console.warn('⏰ [AuthContext]: Token expirado. Realizando logout.');
            logout();
          } else {
            console.log(
              '🔑 [AuthContext]: Token válido. Atualizando contexto de autenticação.'
            );
            setAuth({
              isAuthenticated: true,
              user: {
                userId: decoded.userId || decoded.id, // Mapeando 'id' para 'userId'
                email: decoded.email,
                funcao: decoded.funcao,
                nome: decoded.nome,
                sobrenome: decoded.sobrenome,
                status: decoded.status || 'free', // Status 'free' por padrão
              },
              token: token,
            });
            // Chama a função para buscar dados atualizados do usuário
            await refreshUser(token);
          }
        } catch (error) {
          console.error('❌ [AuthContext]: Erro ao decodificar o token:', error);
          logout();
        }
      }
      setLoading(false); // Finaliza o carregamento após a verificação
    };

    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        updateUser,
        updateUserName,
        updateUserSurname,
        updateUserEmail,
        updateSubscriptionStatus, // Incluímos a função de atualização de status
        updateAuthToken, // Incluímos a função para atualizar o token
        refreshUser,
        loading,
      }}
    >
      {children}

      <Modal
  open={openModal}
  onClose={() => {}}
  aria-labelledby="modal-inatividade-title"
  aria-describedby="modal-inatividade-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      borderRadius: 3,
      boxShadow: 24,
      p: 4,
      width: '90%',
      maxWidth: 400,
    }}
  >
    <Typography
      id="modal-inatividade-title"
      variant="h5"
      component="h2"
      sx={{
        fontWeight: 600,
        textAlign: 'center',
        color: 'primary.main',
        mb: 2,
      }}
    >
      ⚠️ Inatividade Detectada
    </Typography>
    <Typography
      id="modal-inatividade-description"
      sx={{
        textAlign: 'center',
        fontSize: '1rem',
        color: 'text.secondary',
        mb: 4,
      }}
    >
      Você será desconectado em <strong>30 segundos</strong> devido à inatividade.  
      Deseja continuar conectado?
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={extendSession}
        sx={{
          px: 3,
          textTransform: 'none',
          fontWeight: 600,
          boxShadow: 'none',
          '&:hover': { boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)' },
        }}
      >
        Estender Sessão
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={performLogout}
        sx={{
          px: 3,
          textTransform: 'none',
          fontWeight: 600,
          borderColor: 'secondary.main',
          '&:hover': { borderColor: 'secondary.dark' },
        }}
      >
        Desconectar
      </Button>
    </Box>
  </Box>
</Modal>


      {/* Snackbar de Logout Automático */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="info"
          sx={{ width: '100%' }}
        >
          Você foi desconectado devido à inatividade.
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};

// Validação de Props
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
