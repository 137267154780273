// frontend/src/components/Navbar.js

import React, { useState, useContext } from 'react';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Button,
  Avatar,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';

import {
  Menu as MenuIcon,
  Book as BookIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  ExitToApp as ExitToAppIcon,
  History as HistoryIcon,
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  School as SchoolIcon,
  Assignment as AssignmentIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Announcement as AnnouncementIcon,
  Person as PersonIcon,
  Home as HomeIcon,
  ExpandLess,
  ExpandMore,
  AccountBalance as AccountBalanceIcon,
  ErrorOutline as ErrorOutlineIcon,
  Settings as SettingsIcon, // Ícone para Configurações
  Subscriptions as SubscriptionsIcon, // Novo ícone para Gerenciamento de Assinaturas
} from '@mui/icons-material';

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LogoMedChoices from '../logomedchoices.svg';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, logout } = useContext(AuthContext); // Usando o contexto de autenticação
  const [mobileOpen, setMobileOpen] = useState(false);
  
  // Estados para o menu Admin
  const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  
  // **Novo: Estados para o menu Editor**
  const [editorMenuAnchor, setEditorMenuAnchor] = useState(null);
  const [editorDropdownOpen, setEditorDropdownOpen] = useState(false);
  
  const [configMenuAnchor, setConfigMenuAnchor] = useState(null);
  const [configDropdownOpen, setConfigDropdownOpen] = useState(false);
  const isPremium = (
    auth?.user?.status === 'premium' ||
    auth?.user?.status === 'premium_cancelado'
  );


  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleAdminMenuOpen = (event) => setAdminMenuAnchor(event.currentTarget);
  const handleAdminMenuClose = () => setAdminMenuAnchor(null);
  const handleAdminDropdownToggle = () => setAdminDropdownOpen(!adminDropdownOpen);
  
  // **Novo: Funções para o menu Editor**
  const handleEditorMenuOpen = (event) => setEditorMenuAnchor(event.currentTarget);
  const handleEditorMenuClose = () => setEditorMenuAnchor(null);
  const handleEditorDropdownToggle = () => setEditorDropdownOpen(!editorDropdownOpen);
  
  const handleConfigMenuOpen = (event) => setConfigMenuAnchor(event.currentTarget);
  const handleConfigMenuClose = () => setConfigMenuAnchor(null);
  const handleConfigDropdownToggle = () => setConfigDropdownOpen(!configDropdownOpen);

  const handleLogout = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Se por algum motivo não tiver token, apenas faz logout local
      logout();
      navigate('/');
      return;
    }

    fetch('https://medchoices.com.br:3001/logout', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log('Resposta do logout no servidor:', data);
        // Independente do resultado, efetua logout local
        logout(); 
        localStorage.removeItem('token');
        navigate('/login');
      })
      .catch(err => {
        console.error('Erro ao fazer logout no servidor:', err);
        // Se der erro no servidor, ainda assim efetua logout local
        logout();
        localStorage.removeItem('token');
        navigate('/login');
      });
  };

  // Definindo todos os itens do menu com uma flag opcional para premium
  const menuItems = [
    { text: 'Início', path: '/inicio', icon: <HomeIcon />, requiresPremium: false },
    // Itens de Configurações agora não estão aqui, foram movidos para o dropdown
    { text: 'Provas', path: '/provas', icon: <BookIcon />, requiresPremium: false },
    { text: 'Materias', path: '/materiasdisponiveis', icon: <QuestionAnswerIcon />, requiresPremium: false },
    { text: 'Histórico', path: '/historico', icon: <HistoryIcon />, requiresPremium: true },
    // { text: 'Relatórios', path: '/relatorios', icon: <HistoryIcon />, requiresPremium: true },
    { text: 'Assinatura', path: '/assinatura', icon: <SubscriptionsIcon /> , requiresPremium: false } ,
  
  ];

  const adminMenuItems = [
    { text: 'Relatórios', path: '/admin', icon: <DashboardIcon /> },
    { text: 'Usuários', path: '/admin/users', icon: <GroupIcon /> },
    { text: 'Assinaturas', path: '/admin/subscriptions', icon: <SubscriptionsIcon /> }, // Novo item
    { text: 'Matérias', path: '/admin/materias', icon: <SchoolIcon /> },
    { text: 'Provas', path: '/admin/provas', icon: <AssignmentIcon /> },
    { text: 'Questões', path: '/admin/questoes', icon: <QuestionAnswerIcon /> },
    { text: 'Avisos', path: '/admin/avisos', icon: <AnnouncementIcon /> },
    { text: 'Histórico de Usuários', path: '/admin/historico', icon: <HistoryIcon /> },
    { text: 'Faculdades', path: '/admin/faculdades', icon: <AccountBalanceIcon /> },
    { text: 'Reportes de Questões', path: '/admin/reportes-questoes', icon: <ErrorOutlineIcon /> },
    
  ];

  // **Novo: Itens do menu Editor**
  const editorMenuItems = [
    { text: 'Matérias', path: '/admin/materias', icon: <SchoolIcon /> },
    { text: 'Provas', path: '/admin/provas', icon: <AssignmentIcon /> },
    { text: 'Questões', path: '/admin/questoes', icon: <QuestionAnswerIcon /> },
    { text: 'Avisos', path: '/admin/avisos', icon: <AnnouncementIcon /> },
    { text: 'Reportes de Questões', path: '/admin/reportes-questoes', icon: <ErrorOutlineIcon /> },
  ];

  const configuracoesMenuItems = [
    { text: 'Perfil', path: '/perfil', icon: <PersonIcon /> },
    { text: 'Suporte', path: '/suporte', icon: <DashboardIcon /> },
    // { text: 'Avisos', path: '/configuracoes/avisos', icon: <AnnouncementIcon /> },
    // { text: 'Relatórios', path: '/configuracoes/relatorios', icon: <DashboardIcon /> },
  ];

  // Função para fechar o Drawer (sidebar)
  const closeDrawer = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  // Função para renderizar os itens do menu
  const renderMenuItems = (items) =>
    items.map((item) => {
      if (item.requiresPremium) {
        return (
          <ListItemButton
            key={item.text}
            onClick={() => {
              closeDrawer();
              if (isPremium) {
                navigate(item.path, { replace: true });
              } else {
                navigate('/upgrade', { replace: true });
              }
            }}
            sx={{
              borderRadius: 1,
              mx: 1,
              mb: 0.5,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        );
      }

      return (
        <ListItemButton
          key={item.text}
          onClick={() => {
            closeDrawer();
            navigate(item.path, { replace: true });
          }}
          selected={location.pathname === item.path}
          sx={{
            borderRadius: 1,
            mx: 1,
            mb: 0.5,
            '&.Mui-selected': {
              bgcolor: theme.palette.action.selected,
            },
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      );
    });

  // Função para renderizar os itens do menu de administração no Drawer
  const renderAdminMenuItems = (items) =>
    items.map((item) => (
      <ListItemButton
        key={item.text}
        onClick={() => {
          closeDrawer();
          navigate(item.path, { replace: true });
        }}
        sx={{ pl: 4 }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    ));
  
  // **Novo: Função para renderizar os itens do menu Editor no Drawer**
  const renderEditorMenuItems = (items) =>
    items.map((item) => (
      <ListItemButton
        key={item.text}
        onClick={() => {
          closeDrawer();
          navigate(item.path, { replace: true });
        }}
        sx={{ pl: 4 }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    ));

  // Função para renderizar os itens do menu de configurações no Drawer
  const renderConfiguracoesMenuItems = (items) =>
    items.map((item) => (
      <ListItemButton
        key={item.text}
        onClick={() => {
          closeDrawer();
          navigate(item.path, { replace: true });
        }}
        sx={{ pl: 4 }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    ));

  const drawerContent = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          bgcolor: theme.palette.background.default,
        }}
      >
        {/* Verifique se auth.user está disponível antes de acessar suas propriedades */}
        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
          {auth.user?.nome?.[0] || 'M'}
        </Avatar>
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            {auth.user?.nome || 'MedChoices'}
          </Typography>
          {/* Verifique se auth.user está disponível antes de acessar email */}
          <Typography variant="body2" color="text.secondary">
            {auth.user?.email || 'usuario@exemplo.com'}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <List sx={{ flex: 1 }}>
        {renderMenuItems(menuItems)}
        {auth.user?.funcao === 'admin' && (
          <>
            <ListItemButton onClick={handleAdminDropdownToggle}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Administração" />
              {adminDropdownOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={adminDropdownOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderAdminMenuItems(adminMenuItems)}
              </List>
            </Collapse>
          </>
        )}
        
        {/* **Novo: Menu Editor no Drawer** */}
        {auth.user?.funcao === 'editor' && (
          <>
            <ListItemButton onClick={handleEditorDropdownToggle}>
              <ListItemIcon>
                <PersonIcon /> {/* Você pode escolher um ícone mais adequado */}
              </ListItemIcon>
              <ListItemText primary="Editor" />
              {editorDropdownOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={editorDropdownOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderEditorMenuItems(editorMenuItems)}
              </List>
            </Collapse>
          </>
        )}

        {/* Dropdown de Configurações no Drawer */}
        <ListItemButton onClick={handleConfigDropdownToggle}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
          {configDropdownOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={configDropdownOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderConfiguracoesMenuItems(configuracoesMenuItems)}
            <ListItemButton
              onClick={() => {
                handleLogout();
                closeDrawer();
              }}
              sx={{ pl: 4, color: theme.palette.error.main }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider />
    </Box>
  );

  // Não exibe o Navbar se o usuário não estiver autenticado
  if (!auth.isAuthenticated) return null;

  return (
    <>
      <AppBar
        position="sticky" // Garantir que o AppBar fique fixo no topo
        sx={{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          width: '100%', // Garantir 100% de largura
          boxShadow: 'none', // Remover sombra se necessário
        }}
      >
        <Toolbar
          sx={{
            // px: { xs: 1, sm: 2 }, // Ajustar padding para dispositivos móveis
            minHeight: '64px', // Garantir altura consistente
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ fontSize: 36 }} /> {/* Aumentando o tamanho do ícone */}
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start', // Centraliza no mobile
              alignItems: 'center',
            }}
          >
            <RouterLink to="/inicio" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img
                src={LogoMedChoices}
                alt="MedChoices Logo"
                style={{
                  height: '60px', // Ajuste o tamanho da logo conforme necessário
                  objectFit: 'contain',
                }}
              />
            </RouterLink>
          </Box>

          {/* Status e Nome do Usuário */}
          {auth.isAuthenticated && auth.user ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Você pode adicionar informações adicionais aqui, se necessário */}
            </Box>
          ) : (
            <Button color="inherit" onClick={() => navigate('/login')}>
              Login
            </Button>
          )}

          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {menuItems.map((item) => {
                if (item.requiresPremium) {
                  return (
                    <Button
                      key={item.text}
                      color="inherit"
                      startIcon={item.icon}
                      onClick={() => {
                        if (isPremium) {
                          navigate(item.path);
                        } else {
                          navigate('/upgrade');
                        }
                      }}
                      sx={{
                        textTransform: 'none',
                        // Removendo a opacidade reduzida
                      }}
                    >
                      {item.text}
                    </Button>
                  );
                }

                return (
                  <Button
                    key={item.text}
                    color="inherit"
                    component={RouterLink}
                    to={item.path}
                    startIcon={item.icon}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    {item.text}
                  </Button>
                );
              })}

              {auth.user?.funcao === 'admin' && (
                <>
                  <Button
                    color="inherit"
                    startIcon={<AdminPanelSettingsIcon />}
                    onClick={handleAdminMenuOpen}
                    endIcon={Boolean(adminMenuAnchor) ? <ExpandLess /> : <ExpandMore />}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    Administração
                  </Button>
                  <Menu
                    anchorEl={adminMenuAnchor}
                    open={Boolean(adminMenuAnchor)}
                    onClose={handleAdminMenuClose}
                    sx={{
                      '& .MuiPaper-root': {
                        mt: 1,
                        minWidth: 200,
                      },
                    }}
                  >
                    {adminMenuItems.map((item) => (
                      <MenuItem
                        key={item.text}
                        component={RouterLink}
                        to={item.path}
                        onClick={() => {
                          handleAdminMenuClose();
                          closeDrawer(); // Fecha o Drawer após clicar no item
                        }}
                        sx={{
                          gap: 2,
                          minHeight: 48,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}

              {/* **Novo: Botão do Menu Editor no AppBar** */}
              {auth.user?.funcao === 'editor' && (
                <>
                  {/* Comentário removido ou posicionado corretamente */}
                  <Button
                    color="inherit"
                    startIcon={<PersonIcon />}
                    onClick={handleEditorMenuOpen}
                    endIcon={Boolean(editorMenuAnchor) ? <ExpandLess /> : <ExpandMore />}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    Editor
                  </Button>
                  <Menu
                    anchorEl={editorMenuAnchor}
                    open={Boolean(editorMenuAnchor)}
                    onClose={handleEditorMenuClose}
                    sx={{
                      '& .MuiPaper-root': {
                        mt: 1,
                        minWidth: 200,
                      },
                    }}
                  >
                    {editorMenuItems.map((item) => (
                      <MenuItem
                        key={item.text}
                        component={RouterLink}
                        to={item.path}
                        onClick={() => {
                          handleEditorMenuClose();
                          closeDrawer(); // Fecha o Drawer após clicar no item
                        }}
                        sx={{
                          gap: 2,
                          minHeight: 48,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}

              {/* Dropdown de Configurações no AppBar */}
              <Button
                color="inherit"
                startIcon={<SettingsIcon />}
                onClick={handleConfigMenuOpen}
                endIcon={Boolean(configMenuAnchor) ? <ExpandLess /> : <ExpandMore />}
                sx={{
                  textTransform: 'none',
                }}
              >
                Configurações
              </Button>
              <Menu
                anchorEl={configMenuAnchor}
                open={Boolean(configMenuAnchor)}
                onClose={handleConfigMenuClose}
                sx={{
                  '& .MuiPaper-root': {
                    mt: 1,
                    minWidth: 200,
                  },
                }}
              >
                {configuracoesMenuItems.map((item) => (
                  <MenuItem
                    key={item.text}
                    component={RouterLink}
                    to={item.path}
                    onClick={() => {
                      handleConfigMenuClose();
                      closeDrawer(); // Fecha o Drawer após clicar no item
                    }}
                    sx={{
                      gap: 2,
                      minHeight: 48,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    handleLogout();
                    handleConfigMenuClose();
                    closeDrawer();
                  }}
                  sx={{
                    gap: 2,
                    minHeight: 48,
                    color: theme.palette.error.main,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sair" />
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Melhor performance em dispositivos móveis
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 280,
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Navbar;
