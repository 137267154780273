import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Alert,
  Container,
  useTheme,
  useMediaQuery,
  Paper,
  Divider,
  Chip,
  Stack,
  Zoom,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../context/AuthContext';
import { Check, X, Crown, Zap, Clock, Calendar } from 'lucide-react';

// Carregar Stripe fora do componente para evitar recarregamentos
// const stripePromise = loadStripe('pk_live_51HFoeRLmrBt62Vbvxp45rfrZCIJkMa0QNm4DhIt50p13q59qr4qoJOZqADl8u0bxEFrUX4wjxTJc025rJUHebvlh00UqRjCFRe');

const stripePromise = loadStripe('pk_test_51HFoeRLmrBt62Vbvs2lyS0JtYffjRQJPVqdibKydBqkuMsCKu0xuln3RUGCZQDrbvR0qVQzY3p0nCdjhGsN8hL0W00J6ZLoLYR');
const features = [
  { name: 'Acesso a todos os recursos', basic: false, premium: true },
  { name: 'Suporte prioritário', basic: false, premium: true },
  { name: 'Atualizações exclusivas', basic: false, premium: true },
  { name: 'Recursos avançados', basic: false, premium: true },
  { name: 'Recursos básicos', basic: true, premium: true },
];

const PricingCard = ({ 
  title, 
  price, 
  period, 
  priceId, 
  description, 
  isPopular,
  icon: Icon,
  handleUpgrade,
  loading 
}) => {
  const theme = useTheme();
  
  return (
    <Zoom in style={{ transitionDelay: '100ms' }}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          transform: isPopular ? 'scale(1.05)' : 'none',
          border: isPopular ? `2px solid ${theme.palette.primary.main}` : 'none',
          '&:hover': {
            boxShadow: theme.shadows[10],
            transform: isPopular ? 'scale(1.07)' : 'scale(1.02)',
            transition: 'all 0.3s ease-in-out',
          },
        }}
      >
        {isPopular && (
          <Chip
            label="Mais Popular"
            color="primary"
            sx={{
              position: 'absolute',
              top: 12,
              right: 20,
              fontWeight: 'bold',
            }}
          />
        )}
        <CardContent sx={{ flexGrow: 1, p: 3 }}>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Icon size={24} />
            <Typography variant="h5" component="div" fontWeight="bold">
              {title}
            </Typography>
          </Box>
          <Typography variant="h4" component="div" fontWeight="bold" sx={{ mb: 2 }}>
            R${price}
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 2 }}>
            {period}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            {description}
          </Typography>
        </CardContent>
        <CardActions sx={{ p: 3, pt: 0 }}>
          <Button
            variant={isPopular ? "contained" : "outlined"}
            color="primary"
            onClick={() => handleUpgrade(priceId)}
            fullWidth
            size="large"
            disabled={loading}
            sx={{
              py: 1.5,
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Assinar Agora'}
          </Button>
        </CardActions>
      </Card>
    </Zoom>
  );
};

const FeatureComparison = () => {
  return (
    <TableContainer component={Paper} sx={{ mt: 6, mb: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Recurso</TableCell>
            <TableCell align="center">Básico</TableCell>
            <TableCell align="center">Premium</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature) => (
            <TableRow key={feature.name}>
              <TableCell component="th" scope="row">
                {feature.name}
              </TableCell>
              <TableCell align="center">
                {feature.basic ? <Check color="green" size={20} /> : <X color="red" size={20} />}
              </TableCell>
              <TableCell align="center">
                {feature.premium ? <Check color="green" size={20} /> : <X color="red" size={20} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UpgradePage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { auth } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleUpgrade = async (priceId) => {
    if (loading) return;
    setLoading(true);
    setError(null);
    const stripe = await stripePromise;

    try {
      const userId = auth.user?.userId;
      if (!userId) {
        throw new Error('Usuário não autenticado.');
      }

      const response = await fetch('https://medchoices.com.br:3001/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
        },
        body: JSON.stringify({ priceId, userId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao criar sessão de checkout');
      }

      const session = await response.json();

      if (session.id) {
        const { error: stripeError } = await stripe.redirectToCheckout({ sessionId: session.id });
        if (stripeError) {
          throw new Error('Falha ao redirecionar para o Checkout.');
        }
      } else {
        throw new Error('Falha ao criar sessão de checkout.');
      }
    } catch (error) {
      setError(`Erro: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const plans = [
    {
      title: 'Mensal',
      price: '15',
      period: 'por mês',
      priceId: 'price_1QhBELLmrBt62VbvnTjcwj69',
      description: 'Perfeito para começar com todos os recursos premium',
      icon: Clock,
      isPopular: false,
    },
    {
      title: 'Semestral',
      price: '85',
      period: 'por 6 meses',
      priceId: 'price_1QX8RNLmrBt62VbvggoyJ9tW',
      description: 'Nossa escolha mais popular com ótimo custo-benefício',
      icon: Crown,
      isPopular: true,
    },
    {
      title: 'Anual',
      price: '160',
      period: 'por ano',
      priceId: 'price_1QXkG6LmrBt62Vbvj0kn4WW7',
      description: 'Melhor valor com economia máxima',
      icon: Calendar,
      isPopular: false,
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ py: 8 }}>
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography
          variant="h3"
          component="h1"
          fontWeight="bold"
          sx={{ mb: 2 }}
        >
          Escolha seu plano Premium
        </Typography>
        <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
          Desbloqueie todos os recursos e eleve sua experiência ao próximo nível
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.title}>
            <PricingCard
              {...plan}
              handleUpgrade={handleUpgrade}
              loading={loading}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Typography variant="body2" color="text.secondary">
          Os planos adquiridos não são passíveis de reembolso.
        </Typography>
      </Box>

      {/* <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 4,
            mt: 6,
          }}
        >
          <video
            width={isMobile ? '100%' : '70%'}
            controls
          >
            <source src="https://example.com/demo-video.mp4" type="video/mp4" />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        </Box>
      </Box> */}
      <FeatureComparison />
    </Container>
  );
};

export default UpgradePage;