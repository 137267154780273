// src/pages/Admin/UserPanel.js

import React, { useState } from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import UserList from './components/UserList';
import UserForm from './components/UserForm';

const UserPanel = () => {
  const [openCreate, setOpenCreate] = useState(false);

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  return (
    <Grid container>
      {/* Conteúdo Principal */}
      <Grid item xs={12}>
        <Container maxWidth="xl">
          <Box sx={{ mt: 4, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
       
          </Box>
          <UserList />

          {/* Formulário para Criar Novo Usuário */}
          <UserForm 
            open={openCreate} 
            handleClose={handleCreateClose} 
            refreshUsers={() => { /* Implementar atualização após criação */ }} 
            isCreate={true}
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export default UserPanel;
