import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';

const ReportarErro = ({ questaoId, onFeedback }) => {
  const [open, setOpen] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [motivo, setMotivo] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setMensagem('');
    setMotivo('');
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!motivo) {
      // Envia feedback ao pai
      onFeedback('Por favor, selecione um motivo para o reporte.', 'warning');
      return;
    }

    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await fetch('https://medchoices.com.br:3001/admin/reportes-questoes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          questao_id: questaoId,
          mensagem: mensagem || '',
          motivo,
        }),
      });

      const data = await response.json();
      console.log('Resposta da API (ReportarErro):', data);

      if (data.success) {
        onFeedback('Reporte enviado com sucesso!', 'success');
        handleClose();
      } else {
        onFeedback('Erro ao enviar reporte: ' + (data.message || 'Erro desconhecido'), 'error');
      }
    } catch (error) {
      console.error('Erro ao enviar reporte:', error);
      onFeedback('Erro no servidor ao enviar reporte.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="text" color="error" fullWidth onClick={handleOpen}>
        Reportar Erro
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Reportar Erro na Questão</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="motivo-label">Motivo do Reporte</InputLabel>
            <Select
              labelId="motivo-label"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
              label="Motivo do Reporte"
              required
            >
              <MenuItem value="respostas_erradas">Questão com respostas erradas</MenuItem>
              <MenuItem value="falta_alternativas">Falta alternativas</MenuItem>
              <MenuItem value="falta_imagem">Falta imagem da questão</MenuItem>
              <MenuItem value="alternativas_erradas">Alternativas não são dessa questão</MenuItem>
              <MenuItem value="questao_repetida">Questão repetida</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Mensagem (opcional)"
            placeholder="Descreva detalhes adicionais do problema (opcional)."
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose} disabled={loading}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Enviando...' : 'Enviar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportarErro;
