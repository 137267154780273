// src/pages/LandingPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  useTheme,
  AppBar,
  Toolbar,
  Stack,
  Chip,
  Divider,
  TextField,
  IconButton,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fab,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
  ListItemButton,
} from '@mui/material';
import {
  School as SchoolIcon,
  Timeline as TimelineIcon,
  Group as GroupIcon,
  Security as SecurityIcon,
  CheckCircle as CheckCircleIcon,
  Login as LoginIcon,
  AppRegistration as AppRegistrationIcon,
  Star as StarIcon,
  WhatsApp as WhatsAppIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { Stethoscope } from 'lucide-react';
import { useForm } from 'react-hook-form';
import CountUp from 'react-countup';
import LogoMedChoices from '../logomedchoices.svg';

const features = [
  {
    icon: SchoolIcon,
    title: 'Banco de Perguntas Extenso',
    description: 'Mais de 10.000 perguntas atualizadas e validadas por especialistas médicos.',
  },
  {
    icon: TimelineIcon,
    title: 'Acompanhamento de Progresso',
    description: 'Análise detalhada do seu desempenho e áreas para melhorar.',
  },
  {
    icon: GroupIcon,
    title: 'Comunidade Médica',
    description: 'Conecte-se com outros estudantes e profissionais da medicina.',
  },
  {
    icon: SecurityIcon,
    title: 'Simulações Realistas',
    description: 'Exames que replicam o formato oficial das provas médicas.',
  },
];

const planos = [
  {
    title: 'Plano Básico',
    price: '29,99',
    period: '/mês',
    features: [
      'Acesso a 1000 perguntas',
      '2 simulações mensais',
      'Estatísticas básicas',
      'Suporte por email',
    ],
    buttonText: 'Começar Grátis',
    popular: false,
  },
  {
    title: 'Plano Premium',
    price: '49,99',
    period: '/mês',
    features: [
      'Acesso ilimitado a perguntas',
      'Simulações ilimitadas',
      'Estatísticas avançadas',
      'Suporte prioritário 24/7',
      'Grupos de estudo',
      'Recursos para download',
    ],
    buttonText: 'Começar Agora',
    popular: true,
  },
  {
    title: 'Plano Institucional',
    price: '99,99',
    period: '/mês',
    features: [
      'Tudo do Plano Premium',
      'Portal administrativo',
      'Relatórios personalizados',
      'API de integração',
      'Suporte dedicado',
      'Treinamento personalizado',
    ],
    buttonText: 'Contato Comercial',
    popular: false,
  },
];

const depoimentos = [
  {
    name: 'Dr. Carlos Rodrigues',
    role: 'Médico Residente',
    image: 'https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?w=400&auto=format&fit=crop&q=60',
    content: 'MedChoices me ajudou a aprovar meu exame de residência com uma pontuação excelente. A qualidade das perguntas e as explicações detalhadas foram fundamentais.',
  },
  {
    name: 'Dra. Ana Martins',
    role: 'Especialista em Pediatria',
    image: 'https://images.unsplash.com/photo-1594824476967-48c8b964273f?w=400&auto=format&fit=crop&q=60',
    content: 'A plataforma é intuitiva e o conteúdo está muito bem organizado. As simulações são muito realistas e me ajudaram a me preparar adequadamente.',
  },
  {
    name: 'Dr. João Pereira',
    role: 'Estudante de Medicina',
    image: 'https://images.unsplash.com/photo-1622253692010-333f2da6031d?w=400&auto=format&fit=crop&q=60',
    content: 'Excelente ferramenta de estudo. O acompanhamento de progresso e as estatísticas me ajudaram a identificar minhas áreas fracas e melhorá-las.',
  },
];

const estatisticas = [
  { label: 'Estudantes Ativos', value: 1000 },
  { label: 'Perguntas Disponíveis', value: 5000 },
  { label: 'Taxa de Aprovação', value: 95 },
  { label: 'Anos de Experiência', value: 2 },
];

function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogContatoAberto, setDialogContatoAberto] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [menuMobileAberto, setMenuMobileAberto] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    setDialogContatoAberto(false);
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100%' }}>
      {/* Navbar */}
      <AppBar position="fixed" sx={{ bgcolor: theme.palette.primary.main }}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            px: { xs: 2, md: 3 },
          }}
        >
          <Box
            component="img"
            src={LogoMedChoices}
            alt="Logo"
            sx={{ height: 50, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
          {isMobile ? (
            <IconButton color="inherit" onClick={() => setMenuMobileAberto(true)}>
              <MenuIcon />
            </IconButton>
          ) : (
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<LoginIcon />}
                onClick={() => navigate('/login')}
                sx={{
                  textTransform: 'none',
                  color: theme.palette.primary.contrastText,
                }}
              >
                Entrar
              </Button>
              <Button
                variant="contained"
                startIcon={<AppRegistrationIcon />}
                onClick={() => navigate('/register')}
                sx={{
                  textTransform: 'none',
                  bgcolor: theme.palette.secondary.main,
                  ':hover': { bgcolor: theme.palette.secondary.dark },
                }}
              >
                Registrar-se
              </Button>
            </Stack>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer Mobile */}
      <Drawer
        anchor="right"
        open={menuMobileAberto}
        onClose={() => setMenuMobileAberto(false)}
      >
        <Box sx={{ width: 250, p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Menu
          </Typography>
          <List>
            <ListItemButton onClick={() => { navigate('/login'); setMenuMobileAberto(false); }}>
              <ListItemIcon><LoginIcon /></ListItemIcon>
              <ListItemText primary="Entrar" />
            </ListItemButton>
            <ListItemButton onClick={() => { navigate('/register'); setMenuMobileAberto(false); }}>
              <ListItemIcon><AppRegistrationIcon /></ListItemIcon>
              <ListItemText primary="Registrar-se" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>

      {/* Seção Hero */}
      <Box sx={{ 
        pt: { xs: 14, md: 12 }, 
        pb: { xs: 8, md: 12 },
        textAlign: 'center', 
        background: `linear-gradient(135deg, ${theme.palette.primary.main}15 0%, ${theme.palette.secondary.main}15 100%)`, 
        position: 'relative', 
        overflow: 'hidden',
        px: { xs: 2, md: 0 }
      }}>
        <Container maxWidth="xl">
          <Grid container spacing={{ xs: 3, md: 4 }} alignItems="center">
            <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              <Typography 
                variant="h3" 
                gutterBottom
                sx={{
                  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  mb: 3,
                  fontSize: { xs: '2rem', md: '3rem' },
                  lineHeight: 1.2
                }}
              >
                Seu sucesso no exame médico começa aqui
              </Typography>
              <Typography variant="h5" color="text.secondary" sx={{ mb: 4, fontSize: { xs: '1rem', md: '1.25rem' }, lineHeight: 1.4 }}>
                A plataforma líder na Argentina para a preparação de exames médicos.
                Mais de 15.000 estudantes confiam em nós.
              </Typography>
              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={2} 
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => navigate('/register')}
                  startIcon={<ArrowForwardIcon />}
                >
                  Registrar-se
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => navigate('/login')}
                >
                  Entrar
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?w=800&auto=format&fit=crop&q=60"
                alt="Estudantes de medicina estudando"
                sx={{
                  width: '100%',
                  maxWidth: { xs: '100%', md: 600 },
                  height: 'auto',
                  borderRadius: 4,
                  boxShadow: theme.shadows[4],
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Seção de Características */}
      {/* <Container sx={{ py: { xs: 6, md: 8 }, px: { xs: 2, md: 0 } }}>
        <Typography variant="h4" align="center" sx={{ mb: 6, fontSize: { xs: '1.5rem', md: '2rem' } }}>
          Principais Características
        </Typography>
        <Grid container spacing={{ xs: 3, md: 4 }}>
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <Grid item xs={12} sm={6} md={3} key={index} >
                <Card
                  sx={{
                    height: '100%',
                    p: { xs: 3, md: 3 },
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', mb:2}}>
                    <Icon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
                    <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.1rem', md: '1.25rem' } }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.9rem', md: '1rem' }, lineHeight: 1.4 }}>
                      {feature.description}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container> */}

      {/* Seção Estatísticas */}
      {/* <Container sx={{ py: { xs: 6, md: 8 }, px: { xs: 2, md: 0 } }}>
        <Grid container spacing={{ xs: 3, md: 4 }}>
          {estatisticas.map((stat, index) => (
            <Grid item xs={6} md={3} key={index}>
              <Paper elevation={0} sx={{ p: 3, textAlign: 'center', bgcolor: 'background.default' }}>
                <Typography variant="h3" color="primary" sx={{ mb: 1, fontWeight: 'bold', fontSize: { xs: '1.8rem', md: '2.5rem' } }}>
                  <CountUp end={stat.value} suffix={stat.label.includes('Taxa') ? '%' : '+'} duration={2.5} />
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
                  {stat.label}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container> */}


      {/* Seção de Depoimentos */}
      {/* <Box sx={{ bgcolor: 'background.paper', py: { xs: 6, md: 8, mb: 10 }, px: { xs: 2, md: 0 } }}>
        <Container>
          <Typography variant="h4" align="center" sx={{ mb: 6, fontSize: { xs: '1.5rem', md: '2rem' } }}>
            O Que Nossos Usuários Dizem
          </Typography>
          <Grid container spacing={{ xs: 3, md: 4 }}>
            {depoimentos.map((depoimento, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ height: '100%', p: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      src={depoimento.image}
                      sx={{ width: 56, height: 56, mr: 2 }}
                    />
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold" sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }}>
                        {depoimento.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.85rem', md: '1rem' } }}>
                        {depoimento.role}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1" color="text.secondary" sx={{ fontSize: { xs: '0.9rem', md: '1rem' }, lineHeight: 1.4 }}>
                    "{depoimento.content}"
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}

      {/* Seção de Preços */}
  
      {/* <Box sx={{ py: { xs: 6, md: 8 }, px: { xs: 2, md: 0 } }}>
        <Container>
          <Typography variant="h4" align="center" sx={{ mb: 6, fontSize: { xs: '1.5rem', md: '2rem' } }}>
            Planos e Preços
          </Typography>
          <Grid container spacing={{ xs: 3, md: 4 }} alignItems="stretch">
            {planos.map((plano, index) => (
              <Grid item xs={12} md={4} mb={10} key={index}>
                <Card
                  sx={{
                    p: { xs: 3, md: 4 },
                    height: '100%',
                    position: 'relative',
                    transition: 'transform 0.2s ease-in-out',
                    ...(plano.popular && {
                      transform: 'scale(1.05)',
                      border: `2px solid ${theme.palette.primary.main}`,
                    }),
                  }}
                >
                  {plano.popular && (
                    <Chip
                      icon={<StarIcon />}
                      label="Mais Popular"
                      color="primary"
                      sx={{ mb: 2 }}
                    />
                  )}
                  <Typography variant="h5" gutterBottom fontWeight="bold" sx={{ fontSize: { xs: '1.3rem', md: '1.5rem' } }}>
                    {plano.title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2 }}>
                    <Typography variant="h3" component="span" fontWeight="bold" sx={{ fontSize: { xs: '2rem', md: '3rem' } }}>
                      R$ {plano.price}
                    </Typography>
                    <Typography variant="subtitle1" component="span" color="text.secondary" sx={{ ml: 1, fontSize: { xs: '1rem', md: '1.25rem' } }}>
                      {plano.period}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Stack spacing={2} sx={{ mb: 4 }}>
                    {plano.features.map((feature, idx) => (
                      <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CheckCircleIcon sx={{ color: 'success.main' }} />
                        <Typography variant="body2" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>{feature}</Typography>
                      </Box>
                    ))}
                  </Stack>
                  <Button
                    variant={plano.popular ? 'contained' : 'outlined'}
                    fullWidth
                    size="large"
                    onClick={() => navigate('/register')}
                  >
                    {plano.buttonText}
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}
      

      {/* Seção de Contato */}
      {/* <Box sx={{ bgcolor: 'background.paper', py: { xs: 6, md: 8 }, px: { xs: 2, md: 0 } }}>
        <Container>
          <Grid container spacing={{ xs: 3, md: 4 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                Contate-Nos
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph sx={{ fontSize: { xs: '0.95rem', md: '1rem' }, lineHeight: 1.4 }}>
                Estamos aqui para ajudar. Contate-nos por qualquer um desses meios:
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Email"
                    secondary="contato@medchoices.com"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Telefone"
                    secondary="+54 11 1234-5678"
                  />
                </ListItem>
                {/* <ListItem>
                  <ListItemIcon>
                    <LocationIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Endereço"
                    secondary="Av. Corrientes 1234, CABA, Argentina"
                  />
                </ListItem> 
              </List>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <IconButton color="primary">
                  <FacebookIcon />
                </IconButton>
                <IconButton color="primary">
                  <TwitterIcon />
                </IconButton>
                <IconButton color="primary">
                  <InstagramIcon />
                </IconButton>
                <IconButton color="primary">
                  <LinkedInIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: { xs: 3, md: 4 } }}>
                <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
                  Envie-nos uma mensagem
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={3} sx={{ mt: 2 }}>
                    <TextField
                      label="Nome completo"
                      fullWidth
                      {...register('name', { required: 'Este campo é obrigatório' })}
                      error={!!errors.name}
                      helperText={errors.name?.message?.toString()}
                    />
                    <TextField
                      label="Email"
                      fullWidth
                      type="email"
                      {...register('email', { 
                        required: 'Este campo é obrigatório',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Email inválido'
                        }
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message?.toString()}
                    />
                    <TextField
                      label="Mensagem"
                      fullWidth
                      multiline
                      rows={4}
                      {...register('message', { required: 'Este campo é obrigatório' })}
                      error={!!errors.message}
                      helperText={errors.message?.message?.toString()}
                    />
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box> */}

      {/* Rodapé */}
      <Box sx={{ bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider', py: { xs: 4, md: 4 }, px: { xs: 2, md: 0 } }}>
        <Container>
          {/* <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Stethoscope size={24} color={theme.palette.primary.main} />
                <Typography variant="h6" color="primary" sx={{ fontWeight: 700 }}>
                  MedChoices
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.9rem', md: '1rem' }, lineHeight: 1.4 }}>
                A plataforma líder em preparação para exames médicos na Argentina.
                Ajudamos futuros médicos a alcançar suas metas.
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={8}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }}>
                    Produtos
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Banco de Perguntas" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Simulações" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Recursos" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }}>
                    Empresa
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Sobre Nós" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Blog" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Carreiras" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }}>
                    Legal
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Termos de Uso" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Privacidade" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Cookies" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>

          </Grid> 
          <Divider sx={{ my: { xs: 3, md: 4 } }} />*/}
          <Typography variant="body2" color="text.secondary" align="center" sx={{ fontSize: { xs: '0.85rem', md: '1rem' }, lineHeight: 1.4 }}>
            © {new Date().getFullYear()} MedChoices. Todos os direitos reservados.
          </Typography>
        </Container>
      </Box>

      {/* Botão WhatsApp */}
      <Fab
        color="success"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        onClick={() => window.open('https://wa.me/5491112345678', '_blank')}
      >
        <WhatsAppIcon />
      </Fab>

      {/* Diálogo de Contato */}
      <Dialog 
        open={dialogContatoAberto} 
        onClose={() => setDialogContatoAberto(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Contate-Nos
          <IconButton
            onClick={() => setDialogContatoAberto(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ mt: 2 }}>
              <TextField
                label="Nome completo"
                fullWidth
                {...register('name', { required: 'Este campo é obrigatório' })}
                error={!!errors.name}
                helperText={errors.name?.message?.toString()}
              />
              <TextField
                label="Email"
                fullWidth
                type="email"
                {...register('email', { 
                  required: 'Este campo é obrigatório',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email inválido'
                  }
                })}
                error={!!errors.email}
                helperText={errors.email?.message?.toString()}
              />
              <TextField
                label="Mensagem"
                fullWidth
                multiline
                rows={4}
                {...register('message', { required: 'Este campo é obrigatório' })}
                error={!!errors.message}
                helperText={errors.message?.message?.toString()}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setDialogContatoAberto(false)}>Cancelar</Button>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default LandingPage;
