// frontend/src/App.js

import React, { useState } from 'react'; // Importação corrigida

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Box, Typography, CssBaseline } from '@mui/material'; // Adiciona 'Typography'
import { AuthProvider } from './context/AuthContext';

// Importação de Páginas
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import AdminDashboard from './pages/Admin/AdminDashboard';
import UserPanel from './pages/Admin/UserPanel';
import MateriasList from './pages/Admin/Materias/MateriasList';
import MateriasForm from './pages/Admin/Materias/MateriasForm';
import ProvasList from './pages/Admin/Provas/ProvasList';
import ProvasForm from './pages/Admin/Provas/ProvasForm';
import QuestoesList from './pages/Admin/Questoes/QuestoesList';
import QuestoesForm from './pages/Admin/Questoes/QuestoesForm';
import Choices from './pages/Choices';
import AvisosList from './pages/Admin/Avisos/AvisosList';
import AvisosForm from './pages/Admin/Avisos/AvisosForm';
import HistoricoUsuarios from './pages/Admin/Historico/HistoricoUsuarios';
import DetalhesProva from './pages/Admin/Historico/DetalhesProva';
import HistoricoUsuario from './pages/HistoricoUsuario';
import Admin from './pages/AdminPage';
import LandingPage from './pages/LandingPage';
import RecuperarSenha from './components/RecuperarSenha';
import RedefinirSenha from './components/RedefinirSenha';
import VerifyEmail from './VerifyEmail';
import ResendVerification from './ResendVerification';
import Faculdades from './pages/Faculdades';
import MateriasDisponiveis from './pages/MateriasDisponiveis';
import UserReportPage from './pages/UserReportPage';
import SupportArea from './pages/SupportArea';
import Navbar from './components/Navbar'; 
import NetworkStatus from './pages/NetworkStatus'; // Importe o NetworkStatus

// Novas Importações
import PremiumFeature from './pages/PremiumFeature';
import UpgradePage from './pages/UpgradePage';
import BloggerReferencesPage from './pages/BloggerReferencesPage';
import SubscriptionPage from './pages/SubscriptionPage';
import Checkout from './pages/Checkout';

// Páginas de Sucesso e Cancelamento
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';

// Componente AdminReportes para gerenciar os reportes de erro
import AdminReportes from './pages/AdminReportes';

// Importação do Novo Componente de Gerenciamento de Assinaturas
import SubscriptionList from './components/SubscriptionList';

// Importação de Rotas Protegidas
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';


function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleOpenProva = (provaId) => {
    console.log(`Abrir prova com ID: ${provaId}`);
  };


  return (
    <Router>
      <AuthProvider>
        <CssBaseline /> {/* Reseta estilos padrões do navegador */}
        <Navbar />
        <NetworkStatus /> {/* Adicione o NetworkStatus aqui */}
        <Box
          sx={{
            height: '100%',
            overflow: 'auto', // Permite scroll
            WebkitOverflowScrolling: 'touch', // Scroll suave em dispositivos móveis
          }}
        >
          <Routes>
            {/* Página Inicial - Landing Page */}
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />

            {/* Rotas Públicas */}
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path="/recuperar-senha"
              element={
                <PublicRoute>
                  <RecuperarSenha />
                </PublicRoute>
              }
            />
            <Route
              path="/redefinir-senha/:token"
              element={
                <PublicRoute>
                  <RedefinirSenha />
                </PublicRoute>
              }
            />
            <Route
              path="/verificar-email/:token"
              element={
                <PublicRoute>
                  <VerifyEmail />
                </PublicRoute>
              }
            />
            <Route
              path="/reenviar-verificacao"
              element={
                <PublicRoute>
                  <ResendVerification />
                </PublicRoute>
              }
            />

            {/* Rotas Públicas para Sucesso e Cancelamento do Checkout */}
            <Route
              path="/success"
              element={
                <PrivateRoute>
                  <SuccessPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/cancel"
              element={
                <PrivateRoute>
                  <CancelPage />
                </PrivateRoute>
              }
            />

            {/* Rotas Protegidas para Usuários Autenticados */}
            <Route
              path="/inicio"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/perfil"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/provas"
              element={
                <PrivateRoute>
                  <Choices />
                </PrivateRoute>
              }
            />
            <Route
              path="/materiasdisponiveis"
              element={
                <PrivateRoute>
                  <MateriasDisponiveis
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleOpenProva={handleOpenProva}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/historico"
              element={
                <PrivateRoute>
                  <HistoricoUsuario />
                </PrivateRoute>
              }
            />

<Route
              path="/suporte"
              element={
                <PrivateRoute>
                  <SupportArea />
                </PrivateRoute>
              }
            />
            <Route
              path="/relatorios"
              element={
                <PrivateRoute>
                  <UserReportPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/historico/:resultadoId"
              element={
                <PrivateRoute>
                  <DetalhesProva />
                </PrivateRoute>
              }
            />

            {/* Rotas Administrativas Protegidas para Admins */}
            <Route
              path="/admin"
              element={
                <PrivateRoute requiredRole="admin">
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/relatorios"
              element={
                <PrivateRoute requiredRole="admin">
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRoute requiredRole="admin">
                  <UserPanel />
                </PrivateRoute>
              }
            />

            {/* Matérias */}
            <Route
              path="/admin/materias"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <MateriasList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/materias/create"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <MateriasForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/materias/edit/:id"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <MateriasForm />
                </PrivateRoute>
              }
            />

            {/* Provas */}
            <Route
              path="/admin/provas"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <ProvasList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/provas/create"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <ProvasForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/provas/edit/:id"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <ProvasForm />
                </PrivateRoute>
              }
            />

            {/* Questões */}
            <Route
              path="/admin/questoes"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <QuestoesList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/questoes/create"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <QuestoesForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/questoes/edit/:id"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <QuestoesForm />
                </PrivateRoute>
              }
            />

            {/* Avisos */}
            <Route
              path="/admin/avisos"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <AvisosList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/avisos/create"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <AvisosForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/avisos/edit/:id"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <AvisosForm />
                </PrivateRoute>
              }
            />

            {/* Faculdades */}
            <Route
              path="/admin/faculdades"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <Faculdades />
                </PrivateRoute>
              }
            />

            {/* Histórico Admin */}
            <Route
              path="/admin/historico"
              element={
                <PrivateRoute requiredRole="admin">
                  <HistoricoUsuarios />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/historico/:resultadoId"
              element={
                <PrivateRoute requiredRole="admin">
                  <DetalhesProva />
                </PrivateRoute>
              }
            />

            {/* Reportes de Questões (Admin) */}
            <Route
              path="/admin/reportes-questoes"
              element={
                <PrivateRoute requiredRoles={['admin', 'editor']}>
                  <AdminReportes />
                </PrivateRoute>
              }
            />

            {/* Rotas para Assinaturas Premium */}
            {/* Rota Protegida para Funcionalidades Premium */}
            <Route
              path="/premium-feature"
              element={
                <PrivateRoute requiredStatus="premium">
                  <PremiumFeature /> {/* Crie este componente conforme necessário */}
                </PrivateRoute>
              }
            />

            {/* Página de Upgrade para Usuários Free */}
            <Route
              path="/upgrade"
              element={
                <PrivateRoute>
                  <UpgradePage /> {/* Crie esta página para orientar o usuário a se tornar premium */}
                </PrivateRoute>
              }
            />

{/* Página de referencia */}
<Route
              path="/referencias"
              element={
                <PrivateRoute>
                  <BloggerReferencesPage /> {/* Crie esta página para orientar o usuário a se tornar premium */}
                </PrivateRoute>
              }
            />

            {/* Página de Subscription */}
            <Route
              path="/assinatura"
              element={
                <PrivateRoute>
                  <SubscriptionPage /> {/* Crie esta página para orientar o usuário a se tornar premium */}
                </PrivateRoute>
              }
            />

            {/* Checkout para Upgrade */}
            <Route
              path="/checkout"
              element={
                <PrivateRoute>
                  <Checkout />
                </PrivateRoute>
              }
            />

            {/* Rotas Administrativas para Gerenciamento de Assinaturas */}
            <Route
              path="/admin/subscriptions"
              element={
                <PrivateRoute requiredRole="admin">
                  <SubscriptionList />
                </PrivateRoute>
              }
            />

            {/* Página 404 */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Box>
      </AuthProvider>
    </Router>

  );
}

export default App;
