// frontend/src/components/UserForm.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Box,
  Typography,
  IconButton,
  Stack,
  useTheme,
  Divider,
  CircularProgress
} from '@mui/material';
import { Close as CloseIcon, PersonAdd, Save } from '@mui/icons-material';

const UserForm = ({ open, handleClose, refreshUsers, user }) => {
  const theme = useTheme();
  
  // Estados para os campos do formulário
  const [nome, setNome] = useState(user ? user.nome : '');
  const [sobrenome, setSobrenome] = useState(user ? user.sobrenome : '');
  const [email, setEmail] = useState(user ? user.email : '');
  const [senha, setSenha] = useState('');
  const [funcao, setFuncao] = useState(user ? user.funcao : 'usuario');
  const [faculdadeId, setFaculdadeId] = useState(user ? user.faculdadeId : '');
  const [status, setStatus] = useState(user ? user.status : 'free'); // Campo de status (somente criação)
  
  // Estados para gerenciar faculdades
  const [faculdades, setFaculdades] = useState([]);
  const [faculdadesLoading, setFaculdadesLoading] = useState(false);
  const [faculdadesError, setFaculdadesError] = useState('');
  
  // Estados para mensagens de erro e sucesso
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Estado para gerenciar o carregamento do formulário
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      // Resetar mensagens e estado de carregamento ao abrir o modal
      setError('');
      setSuccess('');
      setLoading(false);
      
      if (!user) {
        // Se estiver criando um novo usuário, buscar faculdades
        fetchFaculdades();
      } else {
        // Se estiver editando, garantir que os estados estejam atualizados
        setFuncao(user.funcao);
        setFaculdadeId(user.faculdadeId || '');
        // Nota: O campo `status` não será usado ao editar
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // Função para buscar faculdades
  const fetchFaculdades = async () => {
    setFaculdadesLoading(true);
    setFaculdadesError('');
    try {
      const response = await fetch('https://medchoices.com.br:3001/faculdades', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setFaculdades(data.faculdades);
      } else {
        setFaculdadesError(data.message || 'Erro ao buscar faculdades.');
      }
    } catch (err) {
      console.error('Erro ao buscar faculdades:', err);
      setFaculdadesError('Erro ao buscar faculdades.');
    } finally {
      setFaculdadesLoading(false);
    }
  };

  // Função para resetar o formulário
  const resetForm = () => {
    setNome('');
    setSobrenome('');
    setEmail('');
    setSenha('');
    setFuncao('usuario');
    setFaculdadeId('');
    setStatus('free'); // Apenas relevante para criação
    setError('');
    setSuccess('');
    setLoading(false);
    
    if (!user) {
      fetchFaculdades();
    }
  };

  // Função para lidar com o envio do formulário
  const handleSubmit = async () => {
    // Validações
    if (!nome || !sobrenome || !email || !funcao) {
      setError(user 
        ? 'Nome, sobrenome, email e função são obrigatórios.'
        : 'Nome, sobrenome, email, função, status e faculdade são obrigatórios.');
      return;
    }

    if (!user && !senha) {
      setError('Senha é obrigatória para criação de usuário.');
      return;
    }

    if (!user && !faculdadeId) {
      setError('Selecione uma faculdade para o usuário.');
      return;
    }

    setLoading(true);
    try {
      const method = user ? 'PUT' : 'POST';
      const url = user 
        ? `https://medchoices.com.br:3001/admin/users/${user.id}` 
        : 'https://medchoices.com.br:3001/admin/users';

      // Construir o corpo da requisição
      const body = user
        ? JSON.stringify({
            nome,
            sobrenome,
            email,
            funcao,
            // Apenas incluir senha se ela for fornecida
            ...(senha && { senha })
          })
        : JSON.stringify({ nome, sobrenome, email, senha, funcao, faculdadeId, status }); // Incluir status apenas na criação

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body,
      });

      // Verificar se a resposta é JSON
      const contentType = response.headers.get('content-type');
      let data;
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        const text = await response.text();
        throw new Error(`Resposta inesperada do servidor: ${text}`);
      }

      if (data.success) {
        // Definir mensagem de sucesso
        const successMessage = data.message || (user ? 'Usuário atualizado com sucesso!' : 'Usuário criado com sucesso!');
        setSuccess(successMessage);
        setError('');
        refreshUsers();
        resetForm();
        handleClose();
      } else {
        setError(data.message || 'Erro ao salvar usuário.');
        setSuccess('');
      }
    } catch (err) {
      console.error('Erro ao salvar usuário:', err);
      setError('Erro ao salvar usuário.');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: theme.shadows[5],
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        bgcolor: theme.palette.primary.main,
        color: 'white',
        py: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {user ? <Save /> : <PersonAdd />}
          <Typography variant="h6">
            {user ? 'Editar Usuário' : 'Criar Novo Usuário'}
          </Typography>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mt: 2 }}>
        <Stack spacing={2}>
          {(error || success) && (
            <Box sx={{ mt: 1 }}>
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              autoFocus
              label="Nome"
              type="text"
              fullWidth
              variant="outlined"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              sx={{ flex: 1 }}
            />
            <TextField
              label="Sobrenome"
              type="text"
              fullWidth
              variant="outlined"
              value={sobrenome}
              onChange={(e) => setSobrenome(e.target.value)}
              sx={{ flex: 1 }}
            />
          </Box>

          <TextField
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            label={user ? "Nova Senha" : "Senha"}
            type="password"
            fullWidth
            variant="outlined"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            helperText={user ? "Deixe em branco para não alterar a senha." : ""}
          />

          {/* Campo de Função sempre visível */}
          <FormControl fullWidth>
            <InputLabel>Função</InputLabel>
            <Select
              value={funcao}
              label="Função"
              onChange={(e) => setFuncao(e.target.value)}
            >
              <MenuItem value="usuario">Usuário</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="editor">Editor</MenuItem> {/* **Novo: Opção Editor Adicionada** */}
            </Select>
          </FormControl>

          {/* Campo de Status visível apenas para criação */}
          {!user && (
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                label="Status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="premium">Premium</MenuItem>
                <MenuItem value="premium_cancelado">Premium Cancelado</MenuItem> {/* Nova opção adicionada */}
              </Select>
            </FormControl>
          )}

          {/* Campo de Faculdade visível apenas para criação */}
          {!user && (
            <FormControl fullWidth>
              <InputLabel>Faculdade</InputLabel>
              <Select
                value={faculdadeId}
                label="Faculdade"
                onChange={(e) => setFaculdadeId(e.target.value)}
                disabled={faculdadesLoading || faculdadesError}
              >
                {faculdadesLoading && (
                  <MenuItem value="">
                    <em>Carregando...</em>
                  </MenuItem>
                )}
                {faculdadesError && (
                  <MenuItem value="">
                    <em>Erro ao carregar faculdades</em>
                  </MenuItem>
                )}
                {!faculdadesLoading && !faculdadesError && faculdades.length === 0 && (
                  <MenuItem value="">
                    <em>Nenhuma faculdade disponível</em>
                  </MenuItem>
                )}
                {!faculdadesLoading && !faculdadesError && faculdades.map((faculdade) => (
                  <MenuItem key={faculdade.id} value={faculdade.id}>
                    {faculdade.nome}
                  </MenuItem>
                ))}
              </Select>
              {faculdadesError && (
                <Typography variant="caption" color="error">
                  {faculdadesError}
                </Typography>
              )}
            </FormControl>
          )}
        </Stack>
      </DialogContent>

      <Divider />
      
      <DialogActions sx={{ p: 2.5 }}>
        <Button 
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          startIcon={loading ? null : (user ? <Save /> : <PersonAdd />)}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : (user ? 'Salvar Alterações' : 'Criar Usuário')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserForm;
