// frontend/src/pages/Admin/Questoes/QuestoesList.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  CircularProgress,
  Alert,
  Snackbar,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Stack,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  Card,
  CardContent,
  TableSortLabel,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Image as ImageIcon,
  Preview as PreviewIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  Search as SearchIcon,
  Add as AddIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { Breadcrumbs, Link as MuiLink } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';

const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => {
  console.log('[DeleteConfirmationDialog] Props:', { open, title, description });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} color="inherit" variant="outlined">
          Cancelar
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          variant="contained"
          autoFocus
          sx={{ ml: 2 }}
        >
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteSelectedConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => {
  console.log('[DeleteSelectedConfirmationDialog] Props:', { open, title, description });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} color="inherit" variant="outlined">
          Cancelar
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          variant="contained"
          autoFocus
          sx={{ ml: 2 }}
        >
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const QuestoesList = () => {
  console.log('[QuestoesList] Renderizando componente...');

  const [questoes, setQuestoes] = useState([]);
  const [prova, setProva] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [totalQuestoes, setTotalQuestoes] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedQuestoes, setSelectedQuestoes] = useState([]);
  const [search, setSearch] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewQuestao, setPreviewQuestao] = useState(null);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [handleDuplicates, setHandleDuplicates] = useState('ignore');
  const [deleteSelectedDialogOpen, setDeleteSelectedDialogOpen] = useState(false);
  const [importLoading, setImportLoading] = useState(false);

  const [duplicatedQuestoesIds, setDuplicatedQuestoesIds] = useState([]);
  const [duplicateCount, setDuplicateCount] = useState(0);

  const [sortOrderDuplicated, setSortOrderDuplicated] = useState('asc');

  const location = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const prova_id = params.get('prova_id') || '';
  const searchParam = params.get('search') || '';
  const pageParam = parseInt(params.get('page') || '1', 10);
  const limitParam = parseInt(params.get('limit') || '10', 10);

  console.log('[QuestoesList] Parametros da URL:', { prova_id, searchParam, pageParam, limitParam });

  // Efeito para "sincronizar" search, currentPage, itemsPerPage com params da URL
  useEffect(() => {
    console.log('[useEffect -> sync params] Entrou. searchParam=', searchParam, 'pageParam=', pageParam, 'limitParam=', limitParam);
    setSearch(searchParam);
    setCurrentPage(pageParam);
    setItemsPerPage(limitParam);
  }, [searchParam, pageParam, limitParam]);

  // Efeito para buscar dados da prova, caso exista prova_id
  useEffect(() => {
    console.log('[useEffect -> fetchProva] prova_id=', prova_id);
    if (!prova_id) {
      console.log('[useEffect -> fetchProva] Não há prova_id, não buscará prova.');
      return;
    }

    const fetchProva = async () => {
      console.log('[fetchProva] Iniciando fetch para prova_id:', prova_id);
      try {
        const response = await fetch(`https://medchoices.com.br:3001/admin/provas/${prova_id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        console.log('[fetchProva] Resposta da API:', data);
        if (data.success) {
          setProva(data.prova);
        } else {
          console.error('[fetchProva] Erro (success=false):', data.message);
        }
      } catch (err) {
        console.error('[fetchProva] Erro geral ao buscar prova:', err);
      }
    };

    fetchProva();
  }, [prova_id]);

  // Debounced search para evitar chamar API a cada digitação
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        console.log('[debouncedSearch] Executando. value=', value);
        const newParams = new URLSearchParams(location.search);
        if (value) {
          newParams.set('search', value);
        } else {
          newParams.delete('search');
        }
        newParams.set('page', '1');
        newParams.set('limit', itemsPerPage.toString());
        navigate(`?${newParams.toString()}`);
      }, 500),
    [location.search, navigate, itemsPerPage]
  );

  // Função para buscar questões
  const fetchQuestoes = useCallback(async () => {
    console.log('[fetchQuestoes] Iniciando...');
    setLoading(true);
    setError('');

    try {
      let url = `https://medchoices.com.br:3001/admin/questoes?page=${pageParam}&limit=${itemsPerPage}`;
      if (prova_id) url += `&prova_id=${prova_id}`;
      if (searchParam) url += `&search=${encodeURIComponent(searchParam)}`;
      console.log('[fetchQuestoes] URL final:', url);

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      console.log('[fetchQuestoes] Resposta da API:', data);

      if (data.success) {
        console.log('[fetchQuestoes] setQuestoes com data.questoes.length=', data.questoes.length);
        setQuestoes(data.questoes);
        setTotalQuestoes(data.totalQuestoes);
        setTotalPages(data.totalPages);
        setCurrentPage(data.currentPage);
        setSelectedQuestoes([]);
      } else {
        console.error('[fetchQuestoes] Erro (success=false):', data.message);
        setError(data.message || 'Erro ao buscar Questões.');
      }
    } catch (error) {
      console.error('[fetchQuestoes] Erro no catch:', error);
      setError('Erro ao buscar Questões.');
    } finally {
      console.log('[fetchQuestoes] Finally. loading=false.');
      setLoading(false);
    }
  }, [prova_id, searchParam, pageParam, itemsPerPage]);

  // Função para buscar duplicadas
  const fetchDuplicadas = useCallback(async () => {
    console.log('[fetchDuplicadas] Iniciando...');
    try {
      let url = `https://medchoices.com.br:3001/questoes/duplicadas`;
      if (prova_id) url += `?prova_id=${prova_id}`;
      console.log('[fetchDuplicadas] URL final:', url);

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      console.log('[fetchDuplicadas] response.ok=', response.ok, 'status=', response.status);
      if (!response.ok) {
        console.warn('[fetchDuplicadas] Falha ao carregar duplicadas, status:', response.status);
        setDuplicatedQuestoesIds([]);
        setDuplicateCount(0);
        return;
      }

      const data = await response.json();
      console.log('[fetchDuplicadas] Resposta da API:', data);

      if (data.success && data.duplicadas && data.duplicadas.length > 0) {
        const idsDuplicados = data.duplicadas;
        setDuplicatedQuestoesIds(idsDuplicados);
        setDuplicateCount(idsDuplicados.length);
        setSnackbar({
          open: true,
          message: `Encontradas ${idsDuplicados.length} questões duplicadas!`,
          severity: 'warning',
        });
        console.log('[fetchDuplicadas] IDs duplicados encontrados:', idsDuplicados);
      } else {
        setDuplicatedQuestoesIds([]);
        setDuplicateCount(0);
        console.log('[fetchDuplicadas] Nenhuma duplicada encontrada.');
      }
    } catch (err) {
      console.error('[fetchDuplicadas] Erro no catch:', err);
      setDuplicatedQuestoesIds([]);
      setDuplicateCount(0);
    }
  }, [prova_id]);

  // Efeito para buscar questões (toda vez que [fetchQuestoes] muda)
  useEffect(() => {
    console.log('[useEffect -> fetchQuestoes]');
    fetchQuestoes();
  }, [fetchQuestoes]);

  // Efeito para buscar duplicadas após termos a lista de questões
  useEffect(() => {
    console.log('[useEffect -> fetchDuplicadas] loading=', loading, 'error=', error, 'questoes.length=', questoes.length);
    if (!loading && !error && questoes.length > 0) {
      console.log('[useEffect -> fetchDuplicadas] Chamando fetchDuplicadas()');
      fetchDuplicadas();
    } else if (!loading && !error && questoes.length === 0) {
      console.log('[useEffect -> fetchDuplicadas] Não há questões, zera duplicadas...');
      setDuplicatedQuestoesIds([]);
      setDuplicateCount(0);
    }
  }, [loading, error, questoes, fetchDuplicadas]);

  // Deletar uma questão individual
  const handleDelete = async () => {
    console.log('[handleDelete] Iniciando. deleteTarget=', deleteTarget);
    if (!deleteTarget) {
      console.warn('[handleDelete] Nenhum deleteTarget definido. Abortando.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes/${deleteTarget}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      console.log('[handleDelete] Resposta da API:', data);

      if (data.success) {
        console.log('[handleDelete] Questão deletada com sucesso. Atualizando lista.');
        setSnackbar({ open: true, message: 'Questão deletada com sucesso!', severity: 'success' });
        fetchQuestoes();
      } else {
        console.error('[handleDelete] Erro ao deletar (success=false):', data.message);
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar Questão.', severity: 'error' });
      }
    } catch (error) {
      console.error('[handleDelete] Erro no catch:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar Questão.', severity: 'error' });
    } finally {
      console.log('[handleDelete] Finally. loading=false.');
      setLoading(false);
      setDeleteTarget(null);
      setDeleteDialogOpen(false);
    }
  };

  // Deletar questões selecionadas
  const handleDeleteSelected = async () => {
    console.log('[handleDeleteSelected] Iniciando. selectedQuestoes=', selectedQuestoes);
    if (selectedQuestoes.length === 0) {
      console.warn('[handleDeleteSelected] Nenhuma questão selecionada. Abortando.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ ids: selectedQuestoes }),
      });

      const data = await response.json();
      console.log('[handleDeleteSelected] Resposta da API:', data);

      if (data.success) {
        console.log('[handleDeleteSelected] Questões deletadas com sucesso. Atualizando lista.');
        setSnackbar({ open: true, message: 'Questões deletadas com sucesso!', severity: 'success' });
        fetchQuestoes();
      } else {
        console.error('[handleDeleteSelected] Erro ao deletar (success=false):', data.message);
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar Questões.', severity: 'error' });
      }
    } catch (error) {
      console.error('[handleDeleteSelected] Erro no catch:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar Questões.', severity: 'error' });
    } finally {
      console.log('[handleDeleteSelected] Finally. loading=false.');
      setLoading(false);
      setSelectedQuestoes([]);
      setDeleteSelectedDialogOpen(false);
    }
  };

  // Mudança no campo de busca
  const handleSearchChange = (e) => {
    console.log('[handleSearchChange] Valor:', e.target.value);
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  // Paginação
  const handlePageChange = (event, value) => {
    console.log('[handlePageChange] Mudando para página:', value);
    const newParams = new URLSearchParams(location.search);
    newParams.set('page', value.toString());
    newParams.set('limit', itemsPerPage.toString());
    navigate(`?${newParams.toString()}`);
  };

  const handleItemsPerPageChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    console.log('[handleItemsPerPageChange] Novo limit:', newLimit);
    setItemsPerPage(newLimit);
    const newParams = new URLSearchParams(location.search);
    newParams.set('limit', newLimit.toString());
    newParams.set('page', '1');
    navigate(`?${newParams.toString()}`);
  };

  // Selecionar/desselecionar todas as questões
  const handleSelectAll = (e) => {
    console.log('[handleSelectAll] checked=', e.target.checked);
    if (e.target.checked) {
      const allIds = questoes.map((q) => q.id);
      console.log('[handleSelectAll] Selecionando todos. allIds=', allIds);
      setSelectedQuestoes(allIds);
    } else {
      console.log('[handleSelectAll] Deselecionando todos.');
      setSelectedQuestoes([]);
    }
  };

  // Selecionar/desselecionar uma questão individual
  const handleSelectOne = (e, id) => {
    console.log('[handleSelectOne] checked=', e.target.checked, 'id=', id);
    if (e.target.checked) {
      setSelectedQuestoes((prev) => [...prev, id]);
    } else {
      setSelectedQuestoes((prev) => prev.filter((qid) => qid !== id));
    }
  };

  // Abrir pré-visualização
  const handlePreview = (questao) => {
    console.log('[handlePreview] Questao:', questao);
    setPreviewQuestao(questao);
    setPreviewOpen(true);
  };

  // Verifica se todas as questões estão selecionadas
  const isAllSelected = questoes.length > 0 && selectedQuestoes.length === questoes.length;

  // Exportar questões
  const handleExportQuestoes = async () => {
    console.log('[handleExportQuestoes] Iniciando. prova_id=', prova_id);
    if (!prova_id) {
      console.error('[handleExportQuestoes] Erro: prova_id não definido!');
      setSnackbar({ open: true, message: 'Selecione uma prova para exportar as questões.', severity: 'warning' });
      return;
    }

    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes/export?prova_id=${prova_id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log('[handleExportQuestoes] response.ok=', response.ok, 'status=', response.status);

      const text = await response.text();
      console.log('[handleExportQuestoes] Resposta (raw text):', text);

      if (!response.ok) {
        const error = JSON.parse(text);
        console.error('[handleExportQuestoes] Erro ao exportar. msg=', error.message);
        throw new Error(error.message || 'Erro ao exportar questões.');
      }

      const blob = new Blob([text], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `questoes_prova_${prova_id}.txt`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSnackbar({ open: true, message: 'Questões exportadas com sucesso!', severity: 'success' });
      console.log('[handleExportQuestoes] Download concluído com sucesso.');
    } catch (error) {
      console.error('[handleExportQuestoes] Erro no catch:', error);
      setSnackbar({ open: true, message: error.message || 'Erro ao exportar questões.', severity: 'error' });
    }
  };

  // Abrir diálogo de importação
  const openImportDialog = () => {
    console.log('[openImportDialog] Iniciando. prova_id=', prova_id);
    if (!prova_id) {
      console.error('[openImportDialog] Erro: prova_id é necessário para importar questões.');
      setSnackbar({ open: true, message: 'prova_id é necessário para importar questões.', severity: 'error' });
      return;
    }
    setImportDialogOpen(true);
  };

  // Fechar diálogo de importação
  const closeImportDialog = () => {
    console.log('[closeImportDialog] Fechando diálogo de importação.');
    setImportDialogOpen(false);
    setSelectedFileName('');
    setHandleDuplicates('ignore');
  };

  // Importar questões (submit do form)
  const handleImportQuestoes = async (event) => {
    event.preventDefault();
    console.log('[handleImportQuestoes] Iniciando...');
    const fileInput = event.target.elements.file;
    const file = fileInput.files[0];
    console.log('[handleImportQuestoes] file selecionado:', file);

    if (!file) {
      console.warn('[handleImportQuestoes] Nenhum arquivo selecionado.');
      setSnackbar({ open: true, message: 'Nenhum arquivo selecionado.', severity: 'warning' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('handle_duplicates', handleDuplicates);

    setImportLoading(true);
    try {
      console.log('[handleImportQuestoes] Fazendo fetch POST para /import?prova_id=', prova_id);
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes/import?prova_id=${prova_id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log('[handleImportQuestoes] Resposta da API:', data);

      if (data.success) {
        let mensagem = `Importação concluída: ${data.totalImportadas} importadas, ${data.totalFalhas} falhas.`;
        if (data.falhas && data.falhas.length > 0) {
          const falhasDetalhadas = data.falhas.map(f => `Linha ${f.linha}: ${f.erro}`).join('\n');
          mensagem += `\nFalhas:\n${falhasDetalhadas}`;
        }
        setSnackbar({ open: true, message: mensagem, severity: 'success' });
        console.log('[handleImportQuestoes] Importação realizada com sucesso.');
        fetchQuestoes();
        closeImportDialog();
      } else {
        console.error('[handleImportQuestoes] Erro ao importar (success=false). data=', data);
        const mensagemErro = data.falhas && data.falhas.length > 0
          ? `Falhas:\n${data.falhas.map(f => `Linha ${f.linha}: ${f.erro}`).join('\n')}`
          : data.message || 'Erro ao importar questões.';
        setSnackbar({ open: true, message: mensagemErro, severity: 'error' });
      }
    } catch (error) {
      console.error('[handleImportQuestoes] Erro no catch:', error);
      setSnackbar({ open: true, message: 'Erro ao importar questões.', severity: 'error' });
    } finally {
      console.log('[handleImportQuestoes] Finally. importLoading=false.');
      setImportLoading(false);
    }
  };

  // Quando altera o file
  const handleFileChange = (e) => {
    console.log('[handleFileChange] e.target.files:', e.target.files);
    const file = e.target.files[0];
    if (file) {
      console.log('[handleFileChange] File selecionado:', file.name);
      setSelectedFileName(file.name);
    } else {
      console.log('[handleFileChange] Nenhum file selecionado.');
      setSelectedFileName('');
    }
  };

  // Fechar o snackbar
  const handleCloseSnackbar = () => {
    console.log('[handleCloseSnackbar] Fechando snackbar.');
    setSnackbar({ ...snackbar, open: false });
  };

  // Fechar preview
  const handleClosePreview = () => {
    console.log('[handleClosePreview] Fechando preview.');
    setPreviewOpen(false);
    setPreviewQuestao(null);
  };

  // Ordenar coluna duplicada
  const handleSortDuplicated = () => {
    console.log('[handleSortDuplicated] Invertendo sortOrderDuplicated. Atual=', sortOrderDuplicated);
    setSortOrderDuplicated((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  // Ordenar lista de questões baseadas em duplicadas
  const sortedQuestoes = useMemo(() => {
    console.log('[sortedQuestoes] Recalculando lista ordenada. sortOrderDuplicated=', sortOrderDuplicated);
    if (sortOrderDuplicated === 'none') return questoes;
    return [...questoes].sort((a, b) => {
      const aDuplicated = duplicatedQuestoesIds.includes(a.id);
      const bDuplicated = duplicatedQuestoesIds.includes(b.id);

      if (aDuplicated === bDuplicated) return 0;
      if (sortOrderDuplicated === 'asc') {
        return aDuplicated ? -1 : 1; // Duplicadas primeiro
      } else {
        return aDuplicated ? 1 : -1; // Duplicadas por último
      }
    });
  }, [questoes, duplicatedQuestoesIds, sortOrderDuplicated]);

  console.log('[QuestoesList] Antes do return. Variáveis:', {
    loading,
    error,
    totalQuestoes,
    currentPage,
    totalPages,
    itemsPerPage,
    duplicateCount,
    selectedQuestoes,
    prova,
    search,
  });

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/materias"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Materias
        </MuiLink>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/provas"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Provas
        </MuiLink>
        <Typography color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Questões
        </Typography>
      </Breadcrumbs>

      <Card
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main + '0A',
          mb: 3,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" color="primary">
                {prova ? `Lista de Questões - ${prova.titulo}` : 'Lista de Questões'}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Total de {totalQuestoes} questões cadastradas
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                component={Link}
                to={`/admin/questoes/create${prova_id ? `?prova_id=${prova_id}` : ''}`}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  px: 3,
                  py: 1.5,
                }}
              >
                Criar Nova Questão
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Buscar Questões"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: 2 }}
            >
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel id="items-per-page-label">Por Página</InputLabel>
                <Select
                  labelId="items-per-page-label"
                  id="items-per-page-select"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Por Página"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={handleExportQuestoes}
              >
                Exportar Questões
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<UploadIcon />}
                onClick={openImportDialog}
              >
                Importar Questões
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {duplicateCount > 0 && (
        <Paper sx={{ p: 2, mb: 2, borderRadius: 2 }} elevation={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="subtitle1">
                Foram encontradas {duplicateCount} questões duplicadas.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}

      {selectedQuestoes.length > 0 && (
        <Paper sx={{ p: 2, mb: 2, borderRadius: 2 }} elevation={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">{selectedQuestoes.length} selecionadas</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  console.log('[selectedQuestoes] Abrindo diálogo de deleção em massa...', selectedQuestoes);
                  setDeleteSelectedDialogOpen(true);
                }}
              >
                Deletar Selecionadas
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      <Card sx={{ borderRadius: 2 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, py: 4 }}>
            <CircularProgress size={40} />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2, borderRadius: 2, m: 2 }}>
            {error}
          </Alert>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main + '1A',
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                        indeterminate={
                          selectedQuestoes.length > 0 &&
                          selectedQuestoes.length < questoes.length
                        }
                        inputProps={{ 'aria-label': 'Selecionar todas as questões' }}
                      />
                    </TableCell>
                    <TableCell>#</TableCell>
                    <TableCell>Matéria</TableCell>
                    <TableCell>Prova</TableCell>
                    <TableCell>Enunciado</TableCell>
                    <TableCell>Opções</TableCell>
                    <TableCell>Resposta Correta</TableCell>
                    <TableCell>Imagem</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={true}
                        direction={sortOrderDuplicated}
                        onClick={handleSortDuplicated}
                      >
                        Duplicada
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Data de Criação</TableCell>
                    <TableCell>Data de Atualização</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedQuestoes.map((questao, index) => {
                    const questionNumber = (currentPage - 1) * itemsPerPage + index + 1;
                    const isDuplicated = duplicatedQuestoesIds.includes(questao.id);
                    return (
                      <TableRow
                        key={questao.id}
                        hover
                        sx={
                          isDuplicated
                            ? { backgroundColor: (theme) => theme.palette.error.light + '20' }
                            : {}
                        }
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedQuestoes.includes(questao.id)}
                            onChange={(e) => handleSelectOne(e, questao.id)}
                            inputProps={{ 'aria-labelledby': `questao-${questao.id}` }}
                          />
                        </TableCell>
                        <TableCell>{questionNumber}</TableCell>
                        <TableCell>{questao.materia_nome}</TableCell>
                        <TableCell>{questao.prova_titulo}</TableCell>
                        <TableCell>{questao.enunciado}</TableCell>
                        <TableCell>
                          <Stack spacing={0.5}>
                            <Typography variant="body2">
                              <strong>A:</strong> {questao.opcao_a}
                            </Typography>
                            <Typography variant="body2">
                              <strong>B:</strong> {questao.opcao_b}
                            </Typography>
                            <Typography variant="body2">
                              <strong>C:</strong> {questao.opcao_c}
                            </Typography>
                            <Typography variant="body2">
                              <strong>D:</strong> {questao.opcao_d}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip label={questao.resposta_correta.toUpperCase()} color="success" />
                        </TableCell>
                        <TableCell>
                          {questao.imagem ? (
                            <Tooltip title="Ver Imagem">
                              <IconButton
                                color="primary"
                                component="a"
                                href={
                                  questao.imagem.startsWith('http')
                                    ? questao.imagem
                                    : `https://medchoices.com.br:3001/${questao.imagem}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ImageIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell>
                          {isDuplicated ? (
                            <Tooltip title="Questão Duplicada">
                              <WarningIcon color="error" />
                            </Tooltip>
                          ) : (
                            'Não'
                          )}
                        </TableCell>
                        <TableCell>
                          {new Date(questao.created_at).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {new Date(questao.updated_at).toLocaleString()}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Editar">
                            <IconButton
                              color="primary"
                              sx={{
                                backgroundColor: (theme) => theme.palette.primary.main + '2A',
                                mb: 1,
                              }}
                              component={Link}
                              to={`/admin/questoes/edit/${questao.id}`}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Deletar">
                            <IconButton
                              color="error"
                              sx={{
                                backgroundColor: (theme) => theme.palette.error.main + '2A',
                                mb: 1,
                              }}
                              onClick={() => {
                                console.log('[TableBody -> Deletar questao]', questao.id);
                                setDeleteTarget(questao.id);
                                setDeleteDialogOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Pré-visualizar">
                            <IconButton
                              color="info"
                              sx={{
                                backgroundColor: (theme) => theme.palette.info.main + '2A',
                                mb: 1,
                              }}
                              onClick={() => handlePreview(questao)}
                            >
                              <PreviewIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {sortedQuestoes.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={13} align="center">
                        Nenhuma Questão encontrada.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
              <Typography variant="body2">
                Página {currentPage} de {totalPages}
              </Typography>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )}
      </Card>

      {previewQuestao && (
        <Dialog open={previewOpen} onClose={handleClosePreview} fullWidth maxWidth="xl">
          <DialogTitle>Pré-visualização da Questão</DialogTitle>
          <DialogContent dividers>
            <Typography variant="h6" gutterBottom>
              {previewQuestao.enunciado}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography>
                <strong>A:</strong> {previewQuestao.opcao_a}
              </Typography>
              <Typography>
                <strong>B:</strong> {previewQuestao.opcao_b}
              </Typography>
              <Typography>
                <strong>C:</strong> {previewQuestao.opcao_c}
              </Typography>
              <Typography>
                <strong>D:</strong> {previewQuestao.opcao_d}
              </Typography>
            </Box>
            <Typography variant="subtitle1">
              Resposta Correta: {previewQuestao.resposta_correta.toUpperCase()}
            </Typography>
            {previewQuestao.imagem && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Imagem:</Typography>
                <img
                  src={
                    previewQuestao.imagem.startsWith('http')
                      ? previewQuestao.imagem
                      : `https://medchoices.com.br:3001/${previewQuestao.imagem}`
                  }
                  alt="Questao"
                  style={{ maxWidth: '100%', height: '500px', objectFit: 'contain' }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePreview} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog open={importDialogOpen} onClose={closeImportDialog} fullWidth maxWidth="sm">
        <DialogTitle>Importar Questões</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecione o arquivo .txt contendo as questões que deseja importar.
          </DialogContentText>
          <form onSubmit={handleImportQuestoes}>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" component="label" color="primary" disabled={importLoading}>
                Selecionar Arquivo
                <input
                  type="file"
                  accept=".txt"
                  hidden
                  name="file"
                  onChange={handleFileChange}
                  disabled={importLoading}
                />
              </Button>
              {selectedFileName && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Arquivo selecionado: {selectedFileName}
                </Typography>
              )}
            </Box>
            <Box sx={{ mt: 3 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tratamento de Duplicatas</FormLabel>
                <RadioGroup
                  aria-label="handle_duplicates"
                  name="handle_duplicates"
                  value={handleDuplicates}
                  onChange={(e) => {
                    console.log('[RadioGroup -> handleDuplicates] Novo valor:', e.target.value);
                    setHandleDuplicates(e.target.value);
                  }}
                >
                  <FormControlLabel value="ignore" control={<Radio />} label="Ignorar Duplicatas" />
                  <FormControlLabel value="allow" control={<Radio />} label="Adicionar Duplicatas" />
                </RadioGroup>
              </FormControl>
            </Box>
            <DialogActions sx={{ mt: 3 }}>
              <Button onClick={closeImportDialog} color="secondary" disabled={importLoading}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={importLoading}>
                {importLoading ? <CircularProgress size={24} /> : 'Importar'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => {
          console.log('[DeleteConfirmationDialog -> onClose]');
          setDeleteDialogOpen(false);
          setDeleteTarget(null);
        }}
        onConfirm={handleDelete}
        title="Confirmar Deleção"
        description="Tem certeza que deseja deletar esta Questão? Esta ação não pode ser desfeita."
      />

      <DeleteSelectedConfirmationDialog
        open={deleteSelectedDialogOpen}
        onClose={() => {
          console.log('[DeleteSelectedConfirmationDialog -> onClose]');
          setDeleteSelectedDialogOpen(false);
        }}
        onConfirm={handleDeleteSelected}
        title="Confirmar Deleção das Questões Selecionadas"
        description="Tem certeza que deseja deletar todas as questões selecionadas? Esta ação não pode ser desfeita."
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <AlertSnackbar onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </Container>
  );
};

export default QuestoesList;
