import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { MessageCircle, Send, User, Mail, Paperclip } from 'lucide-react';

const theme = createTheme({
// Personalize o tema aqui, se desejar
palette: {
    primary: {
      main: '#3D9A8B'
    },
    secondary: {
      main: '#114C5F'
    },
    success: {
      main: '#2a9d8f'
    },
    warning: {
      main: '#f4a261'
    },
    info: {
      main: '#e9c46a'
    },
    error: {
      main: '#bc4749'
    },
    cinza: {
      main: '#eeee'
    },
  }
});

const API_URL = 'https://medchoices.com.br:3001';

function SupportArea() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedback(null);
  
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      setFeedback({
        type: 'error',
        message: 'Todos os campos são obrigatórios.',
      });
      setSnackbarOpen(true);
      setIsSubmitting(false);
      return;
    }
  
    try {
      const formDataObj = new FormData();
      formDataObj.append('name', formData.name);
      formDataObj.append('email', formData.email);
      formDataObj.append('subject', formData.subject);
      formDataObj.append('message', formData.message);
      if (file) formDataObj.append('file', file);
  
      console.log('Enviando os seguintes dados:', Object.fromEntries(formDataObj.entries()));
  
      const response = await fetch(`${API_URL}/suporte/enviar-mensagem`, {
        method: 'POST',
        body: formDataObj,
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Erro ao enviar mensagem.');
      }
  
      setFeedback({
        type: 'success',
        message: data.message || 'Mensagem enviada com sucesso!',
      });
      setFormData({ name: '', email: '', subject: '', message: '' });
      setFile(null);
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      setFeedback({
        type: 'error',
        message: error.message || 'Erro ao enviar mensagem. Tente novamente mais tarde.',
      });
    } finally {
      setIsSubmitting(false);
      setSnackbarOpen(true);
    }
  };
  

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <MessageCircle size={40} strokeWidth={1.5} className="text-blue-600" />
          <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 2 }}>
            Central de Suporte
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Estamos aqui para ajudar! Envie sua mensagem e retornaremos em breve.
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              fullWidth
              label="Nome"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              InputProps={{
                startAdornment: <User size={20} className="text-gray-400 mr-2" />,
              }}
            />

            <TextField
              fullWidth
              label="E-mail"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              InputProps={{
                startAdornment: <Mail size={20} className="text-gray-400 mr-2" />,
              }}
            />

            <TextField
              fullWidth
              label="Assunto"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />

            <TextField
              fullWidth
              label="Mensagem"
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleInputChange}
              required
            />

            <Button variant="contained" component="label" startIcon={<Paperclip />}>
              Anexar Arquivo
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            {file && <Typography>Arquivo selecionado: {file.name}</Typography>}

            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : <Send />}
              sx={{
                py: 1.5,
                mt: 2,
              }}
            >
              {isSubmitting ? 'Enviando...' : 'Enviar Mensagem'}
            </Button>
          </Box>
        </form>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={feedback?.type || 'info'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {feedback?.message}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default SupportArea;
