import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  CircularProgress,
  Alert,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Breadcrumbs,
  Link as MuiLink,
  Card,
  CardContent,
  Fade,
  alpha,
  TableSortLabel,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  Home as HomeIcon,
  List as ListIcon,
} from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { Link, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useTheme } from '@mui/material/styles';

// Componente de Alerta
const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Componente para confirmar exclusão
const DeleteConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 2 }}>
      <Button onClick={onClose} color="inherit" variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        autoFocus
        sx={{ ml: 2 }}
      >
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
);

// Componente para o Diálogo de Confirmação de Deleção (para múltiplas seleções)
const DeleteSelectedConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 2 }}>
      <Button onClick={onClose} color="inherit" variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        autoFocus
        sx={{ ml: 2 }}
      >
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
);

const MateriasList = () => {
  const theme = useTheme();
  const location = useLocation();

  // Estados
  const [materias, setMaterias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [totalMaterias, setTotalMaterias] = useState(0);
  const [selectedMaterias, setSelectedMaterias] = useState([]);
  const [search, setSearch] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [deleteSelectedDialogOpen, setDeleteSelectedDialogOpen] = useState(false);

  // Novo estado para "Infinite Scroll"
  const [page, setPage] = useState(1); // Qual "página" atual (ou offset)
  const limit = 100;                  // Tamanho do lote
  const [hasMore, setHasMore] = useState(true); // Se ainda há mais para carregar do servidor

  // Estado para ordenação
  const [sortConfig, setSortConfig] = useState({
    field: null,    // 'nome', 'created_at', 'updated_at'
    direction: 'asc'
  });

  // Ref para o "sentinel" (o elemento que o Intersection Observer observará)
  const sentinelRef = useRef(null);

  // Debounce para otimizar buscas
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        // Quando o usuário muda a busca, resetamos a lista
        setMaterias([]);
        setPage(1);
        setHasMore(true);
        setSearch(value);
      }, 500),
    []
  );

  // Quando digitar no campo de busca:
  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  // Observador para o "infinite scroll"
  useEffect(() => {
    // Função que será chamada quando o sentinel aparecer na tela
    const onIntersect = (entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting && !loading && hasMore) {
        // Carregar mais
        setPage((prev) => prev + 1);
      }
    };

    const observer = new IntersectionObserver(onIntersect, {
      root: null,      // viewport
      rootMargin: '0px',
      threshold: 0.1,  // 10% visível
    });

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [loading, hasMore]);

  // Função para buscar matérias do servidor
  const fetchMaterias = useCallback(async (pageToLoad, searchValue) => {
    setLoading(true);
    setError('');

    try {
      let url = `https://medchoices.com.br:3001/admin/materias?page=${pageToLoad}&limit=${limit}`;
      if (searchValue) {
        url += `&search=${encodeURIComponent(searchValue)}`;
      }

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();

      if (data.success) {
        // Se pageToLoad === 1, estamos recomeçando a lista (provavelmente por troca de busca)
        // senão, append
        setMaterias((prev) =>
          pageToLoad === 1 ? data.materias : [...prev, ...data.materias]
        );

        setTotalMaterias(data.totalMaterias || data.total || 0);

        // Se o número de matérias retornadas for menor que 'limit', não há mais dados
        if (data.materias.length < limit) {
          setHasMore(false);
        }
      } else {
        setError(data.message || 'Erro ao buscar matérias.');
        setHasMore(false);
      }
    } catch (err) {
      console.error('Erro ao buscar matérias:', err);
      setError('Erro ao buscar matérias.');
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, []);

  // Toda vez que "page" ou "search" mudar, buscamos mais dados
  useEffect(() => {
    fetchMaterias(page, search);
  }, [page, search, fetchMaterias]);

  // Função para deletar uma matéria individual
  const handleDelete = async () => {
    if (!deleteTarget) return;

    setLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/materias/${deleteTarget}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({ open: true, message: 'Matéria deletada com sucesso!', severity: 'success' });
        // Recarregar tudo do zero (page=1) para refletir mudança
        setMaterias([]);
        setPage(1);
        setHasMore(true);
      } else {
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar matéria.', severity: 'error' });
      }
    } catch (error) {
      console.error('Erro ao deletar matéria:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar matéria.', severity: 'error' });
    } finally {
      setLoading(false);
      setDeleteTarget(null);
      setDeleteDialogOpen(false);
    }
  };

  // Função para deletar múltiplas matérias selecionadas
  const handleDeleteSelected = async () => {
    if (selectedMaterias.length === 0) return;

    setLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/materias`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ ids: selectedMaterias }),
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({ open: true, message: 'Matérias deletadas com sucesso!', severity: 'success' });
        // Recarregar do zero
        setMaterias([]);
        setPage(1);
        setHasMore(true);
      } else {
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar matérias.', severity: 'error' });
      }
    } catch (error) {
      console.error('Erro ao deletar matérias:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar matérias.', severity: 'error' });
    } finally {
      setLoading(false);
      setSelectedMaterias([]);
      setDeleteSelectedDialogOpen(false);
    }
  };

  // Selecionar / desselecionar todas
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = materias.map((m) => m.id);
      setSelectedMaterias(allIds);
    } else {
      setSelectedMaterias([]);
    }
  };

  // Selecionar / desselecionar uma
  const handleSelectOne = (e, id) => {
    if (e.target.checked) {
      setSelectedMaterias((prev) => [...prev, id]);
    } else {
      setSelectedMaterias((prev) => prev.filter((mid) => mid !== id));
    }
  };

  const isAllSelected = materias.length > 0 && selectedMaterias.length === materias.length;

  // Força a recarga (refresh) resetando o estado
  const handleRefresh = () => {
    setMaterias([]);
    setPage(1);
    setHasMore(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Função para lidar com a ordenação (client-side)
  const handleSort = (field) => {
    let direction = 'asc';
    if (sortConfig.field === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ field, direction });
  };

  // Ordenação client-side (opcional)
  const sortedMaterias = useMemo(() => {
    let sortable = [...materias];

    if (sortConfig.field) {
      sortable.sort((a, b) => {
        let aField = a[sortConfig.field];
        let bField = b[sortConfig.field];

        if (sortConfig.field === 'created_at' || sortConfig.field === 'updated_at') {
          aField = new Date(aField);
          bField = new Date(bField);
        } else {
          aField = aField ? aField.toString().toLowerCase() : '';
          bField = bField ? bField.toString().toLowerCase() : '';
        }

        if (aField < bField) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aField > bField) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sortable;
  }, [materias, sortConfig]);

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/materias"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Materias
        </MuiLink>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/provas"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Provas
        </MuiLink>
      </Breadcrumbs>

      {/* Cabeçalho */}
      <Card
        elevation={0}
        sx={{
          backgroundColor: theme.palette.primary.main + '0A',
          mb: 3,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" color="primary">
                Lista de Matérias
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Total de {totalMaterias} matérias cadastradas
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                component={Link}
                to="/admin/materias/create"
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  px: 3,
                  py: 1.5,
                }}
              >
                Criar Nova Matéria
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Campo de Busca e Botões de Refresh */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                variant="outlined"
                label="Buscar Matérias"
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}
            >
              {/* Botão de Refresh */}
              <Tooltip title="Atualizar lista" arrow>
                <IconButton
                  onClick={handleRefresh}
                  sx={{ mr: 1 }}
                  color="primary"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Botões de Ações para Múltiplas Seleções */}
      {selectedMaterias.length > 0 && (
        <Paper sx={{ p: 2, mb: 2, borderRadius: 2 }} elevation={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                {selectedMaterias.length} selecionadas
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteSelectedDialogOpen(true)}
              >
                Deletar Selecionadas
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Tabela */}
      <Card sx={{ borderRadius: 2 }}>
        {error ? (
          <Alert
            severity="error"
            sx={{ mb: 2, borderRadius: 2, m: 2 }}
            action={
              <Button color="inherit" size="small" onClick={handleRefresh}>
                Tentar Novamente
              </Button>
            }
          >
            {error}
          </Alert>
        ) : (
          <Fade in>
            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.primary.main + '1A',
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                        indeterminate={
                          selectedMaterias.length > 0 &&
                          selectedMaterias.length < materias.length
                        }
                        inputProps={{
                          'aria-label': 'Selecionar todas as matérias',
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'nome'}
                        direction={
                          sortConfig.field === 'nome'
                            ? sortConfig.direction
                            : 'asc'
                        }
                        onClick={() => handleSort('nome')}
                      >
                        Nome
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Descrição</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'created_at'}
                        direction={
                          sortConfig.field === 'created_at'
                            ? sortConfig.direction
                            : 'asc'
                        }
                        onClick={() => handleSort('created_at')}
                      >
                        Criado em
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'updated_at'}
                        direction={
                          sortConfig.field === 'updated_at'
                            ? sortConfig.direction
                            : 'asc'
                        }
                        onClick={() => handleSort('updated_at')}
                      >
                        Atualizado em
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedMaterias.map((materia) => (
                    <TableRow
                      key={materia.id}
                      hover
                      sx={{
                        '&:hover': {
                          backgroundColor: theme.palette.action.hover,
                        },
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedMaterias.includes(materia.id)}
                          onChange={(e) => handleSelectOne(e, materia.id)}
                          inputProps={{
                            'aria-labelledby': `materia-${materia.id}`,
                          }}
                        />
                      </TableCell>
                      <TableCell>{materia.nome}</TableCell>
                      <TableCell>{materia.descricao}</TableCell>
                      <TableCell>
                        {new Date(materia.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {new Date(materia.updated_at).toLocaleString()}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Editar" arrow>
                          <IconButton
                            color="primary"
                            sx={{
                              backgroundColor: alpha(
                                theme.palette.primary.main,
                                0.1
                              ),
                              '&:hover': {
                                backgroundColor: alpha(
                                  theme.palette.primary.main,
                                  0.2
                                ),
                              },
                              mr: 1,
                            }}
                            component={Link}
                            to={`/admin/materias/edit/${materia.id}`}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Excluir" arrow>
                          <IconButton
                            color="error"
                            sx={{
                              backgroundColor: alpha(
                                theme.palette.error.main,
                                0.1
                              ),
                              '&:hover': {
                                backgroundColor: alpha(
                                  theme.palette.error.main,
                                  0.2
                                ),
                              },
                            }}
                            onClick={() => {
                              setDeleteTarget(materia.id);
                              setDeleteDialogOpen(true);
                            }}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  {materias.length === 0 && !loading && (
                    <TableRow>
                      <TableCell colSpan={6} align="center" sx={{ py: 4 }}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Nenhuma Matéria encontrada.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* Loading spinner ao final da tabela */}
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={32} />
                </Box>
              )}
              {/* Sentinel - fica abaixo da tabela para disparar o "infinite scroll" */}
              <div ref={sentinelRef} />
            </TableContainer>
          </Fade>
        )}
      </Card>

      {/* Diálogo de Confirmação de Deleção Individual */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setDeleteTarget(null);
        }}
        onConfirm={handleDelete}
        title="Confirmar Deleção"
        description="Tem certeza que deseja excluir esta matéria? Esta ação não pode ser desfeita."
      />

      {/* Diálogo de Confirmação de Deleção Múltipla */}
      <DeleteSelectedConfirmationDialog
        open={deleteSelectedDialogOpen}
        onClose={() => setDeleteSelectedDialogOpen(false)}
        onConfirm={handleDeleteSelected}
        title="Confirmar Deleção das Matérias Selecionadas"
        description="Tem certeza que deseja deletar todas as matérias selecionadas? Esta ação não pode ser desfeita."
      />

      {/* Snackbar para Mensagens */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <AlertSnackbar
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </Container>
  );
};

export default MateriasList;
