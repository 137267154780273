// frontend/src/components/SubscriptionForm.js

import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, FormControl, InputLabel, Select,
  MenuItem, Grid, CircularProgress, Snackbar, Alert
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns'; // Importar format

function SubscriptionForm({ open, handleClose, user, refreshUsers }) {
  const [status, setStatus] = useState(user.status || '');
  const [dataInicio, setDataInicio] = useState(user.data_inicio ? new Date(user.data_inicio) : null);
  const [dataFim, setDataFim] = useState(user.data_fim ? new Date(user.data_fim) : null);
  const [dataCancelamento, setDataCancelamento] = useState(user.cancelado_em ? new Date(user.cancelado_em) : null); // Corrigir nome da coluna

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    setStatus(user.status || '');
    setDataInicio(user.data_inicio ? new Date(user.data_inicio) : null);
    setDataFim(user.data_fim ? new Date(user.data_fim) : null);
    setDataCancelamento(user.cancelado_em ? new Date(user.cancelado_em) : null); // Corrigir nome da coluna
    setError('');
  }, [user]);

  // Função para formatar a data no formato MySQL (UTC)
  const formatMySQLDateTime = (date) => {
    if (!date) return null;
    const pad = (n) => n.toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1);
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSubmit = async () => {
    // Validação simples
    if (!status) {
      setError('Status é obrigatório.');
      return;
    }
    if (!dataInicio) {
      setError('Data de Início é obrigatória.');
      return;
    }
    if (!dataFim) {
      setError('Data de Fim é obrigatória.');
      return;
    }
    if (dataCancelamento && dataCancelamento < dataInicio) {
      setError('Data de Cancelamento não pode ser antes da Data de Início.');
      return;
    }
    if (dataFim < dataInicio) {
      setError('Data de Fim não pode ser antes da Data de Início.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/subscriptions/${user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          status,
          data_inicio: formatMySQLDateTime(dataInicio),
          data_fim: formatMySQLDateTime(dataFim),
          cancelado_em: dataCancelamento ? formatMySQLDateTime(dataCancelamento) : null,
        }),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setSnackbar({
          open: true,
          message: 'Assinatura atualizada com sucesso.',
          severity: 'success',
        });
        refreshUsers(); // Atualizar a lista de usuários
        handleClose();
      } else {
        throw new Error(result.message || 'Erro ao atualizar assinatura.');
      }
    } catch (err) {
      console.error('Erro ao atualizar assinatura:', err);
      setError(err.message);
      setSnackbar({
        open: true,
        message: err.message || 'Erro ao atualizar assinatura.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Editar Assinatura do Usuário</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status da Assinatura</InputLabel>
                <Select
                  value={status}
                  label="Status da Assinatura"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="premium">Premium</MenuItem>
                  <MenuItem value="premium_cancelado">Premium Cancelado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DateTimePicker
                  label="Data de Início"
                  value={dataInicio}
                  onChange={(newValue) => setDataInicio(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  ampm={false} // Formato 24 horas
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DateTimePicker
                  label="Data de Fim"
                  value={dataFim}
                  onChange={(newValue) => setDataFim(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  ampm={false} // Formato 24 horas
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DateTimePicker
                  label="Data de Cancelamento"
                  value={dataCancelamento}
                  onChange={(newValue) => setDataCancelamento(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  ampm={false} // Formato 24 horas
                  clearable
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" disabled={loading}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Salvar'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para Feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SubscriptionForm;
