// frontend/src/pages/Admin/Provas/ProvasList.js

import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  IconButton, 
  Button,
  Typography,
  CircularProgress,
  Alert,
  Tooltip,
  TextField,
  Box,
  Checkbox,
  Snackbar,
  Grid,
  Card,
  CardContent,
  useTheme,
  alpha,
  InputAdornment,
  Chip,
  Breadcrumbs,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TableSortLabel,
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Quiz as QuizIcon, 
  Search as SearchIcon, 
  Add as AddIcon,
  AccessTime as AccessTimeIcon,
  School as SchoolIcon,
  CalendarToday as CalendarTodayIcon,
  UploadFile as UploadFileIcon,
  Download as DownloadIcon,
  Home as HomeIcon,
  List as ListIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

// Custom Alert component for Snackbar
const AlertSnackbar = React.forwardRef((props, ref) => (
  <Alert elevation={6} variant="filled" ref={ref} {...props} />
));

// Componente para confirmar exclusão
const DeleteConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
    <DialogContent>
      <Typography>{description}</Typography>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 2 }}>
      <Button onClick={onClose} color="secondary" variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        autoFocus
        sx={{ ml: 2 }}
      >
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
);

const ProvasList = () => {
  const theme = useTheme();
  const [provas, setProvas] = useState([]);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [error, setError] = useState('');
  const [totalProvas, setTotalProvas] = useState(0);
  
  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  // Utilizando useMemo para evitar recriação de URLSearchParams em cada renderização
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  // Alterado para 'access_level' e 'materia_id'
  const accessLevelParam = params.get('access_level') || 'All'; // Novo parâmetro para Nível de Acesso
  const materiaParam = params.get('materia_id') || 'All'; // Novo parâmetro para Matéria

  const searchParam = params.get('search') || '';
  const pageParam = parseInt(params.get('page') || '1', 10);
  const limitParam = parseInt(params.get('limit') || '10', 10); // Limite de itens por página

  const [search, setSearch] = useState(searchParam);
  const [selectedProvas, setSelectedProvas] = useState([]);
  const [snackbar, setSnackbar] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' 
  });

  // Estados para ordenação
  const [sortField, setSortField] = useState(params.get('sortField') || 'id'); // Campo padrão
  const [sortOrder, setSortOrder] = useState(params.get('sortOrder') || 'asc'); // Ordem padrão: crescente

  // Estados para filtros
  const [accessLevelFilter, setAccessLevelFilter] = useState(accessLevelParam);
  const [materiasFilter, setMateriasFilter] = useState(materiaParam);
  const [materiasList, setMateriasList] = useState([]); // Lista de matérias para o filtro

  // Estados para modal de exclusão
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null); // Pode ser objeto (única) ou array de IDs (múltiplas)

  // Estados para handling duplicate Prova names during import
  const [duplicateProvas, setDuplicateProvas] = useState([]);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [importedProvas, setImportedProvas] = useState([]);

  // Função para renderizar o nível de acesso
  const renderAccessLevel = (access_level) => {
    switch (access_level) {
      case 'free':
        return (
          <Chip
            icon={<LockOpenIcon sx={{ fontSize: 16 }} />}
            label="Livre"
            color="secondary"
            size="small"
            sx={{ borderRadius: 1 }}
          />
        );
      case 'premium':
        return (
          <Chip
            icon={<LockIcon sx={{ fontSize: 16 }} />}
            label="Premium"
            color="primary"
            size="small"
            sx={{ borderRadius: 1 }}
          />
        );
      case 'premium_cancelado':
        return (
          <Chip
            icon={<CancelIcon sx={{ fontSize: 16, color: 'error.main' }} />}
            label="Premium Cancelado"
            color="error"
            size="small"
            sx={{ borderRadius: 1 }}
          />
        );
      default:
        return <Typography variant="body2" color="text.secondary">N/A</Typography>;
    }
  };

  // Fetch provas with timeout
  const fetchWithTimeout = async (url, options = {}, timeout = 10000) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal
      });
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  };

  // Fetch lista de matérias para o filtro
  const fetchMateriasList = useCallback(async () => {
    try {
      const response = await fetchWithTimeout('https://medchoices.com.br:3001/admin/materias', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar matérias. Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setMateriasList(data.materias);
      } else {
        throw new Error(data.message || 'Erro ao buscar matérias.');
      }
    } catch (error) {
      console.error('Erro ao buscar matérias:', error);
      setSnackbar({ 
        open: true, 
        message: error.message || 'Erro ao buscar matérias.', 
        severity: 'error' 
      });
    }
  }, []);

  // Fetch provas
  const fetchProvas = useCallback(async () => {
    if (loadingFetch) return;
    
    setLoadingFetch(true);
    setError('');
    
    try {
      // Construir a URL usando URL e URLSearchParams
      const baseUrl = 'https://medchoices.com.br:3001/admin/provas';
      const url = new URL(baseUrl);
      const queryParams = new URLSearchParams();

      if (search) {
        queryParams.append('search', search);
      }

      if (sortField) {
        queryParams.append('sortField', sortField);
      }

      if (sortOrder) {
        queryParams.append('sortOrder', sortOrder);
      }

      // Alterado para 'access_level' e 'materia_id'
      if (accessLevelFilter && accessLevelFilter !== 'All') {
        queryParams.append('access_level', accessLevelFilter);
      }

      if (materiasFilter && materiasFilter !== 'All') {
        queryParams.append('materia_id', materiasFilter);
      }

      queryParams.append('page', pageParam.toString());
      queryParams.append('limit', limitParam.toString());

      url.search = queryParams.toString();

      const response = await fetchWithTimeout(url.toString(), {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar provas. Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setProvas(data.provas);
        setTotalProvas(data.provas.length);
        setSelectedProvas([]);
      } else {
        throw new Error(data.message || 'Erro ao buscar Provas.');
      }
    } catch (error) {
      console.error('Erro ao buscar Provas:', error);
      setError(error.message || 'Erro ao buscar Provas.');
    } finally {
      setLoadingFetch(false);
    }
  }, [search, sortField, sortOrder]); // Removido loadingFetch

  // Fetch provas e lista de matérias ao montar o componente
  useEffect(() => {
    fetchProvas();
    fetchMateriasList();
  }, [fetchProvas, fetchMateriasList]);

  // Delete single prova - Atualizada para abrir o modal
  const handleDelete = (id) => {
    const prova = provas.find(p => p.id === id);
    if (prova) {
      handleOpenDeleteModal(prova);
    }
  };

  // Delete selected provas - Atualizada para abrir o modal
  const handleDeleteSelected = () => {
    if (selectedProvas.length === 0) return;
    handleOpenDeleteModal(selectedProvas); // Passa o array de IDs
  };

  // Abre o modal de exclusão
  const handleOpenDeleteModal = (target) => {
    setDeleteTarget(target); // target pode ser uma única prova ou um array de IDs
    setIsDeleteModalOpen(true);
  };

  // Fecha o modal de exclusão
  const handleCloseDeleteModal = () => {
    setDeleteTarget(null);
    setIsDeleteModalOpen(false);
  };

  // Confirma a exclusão
  const confirmDelete = async () => {
    if (!deleteTarget) return;

    try {
      if (Array.isArray(deleteTarget)) {
        // Exclusão múltipla
        const response = await fetchWithTimeout(`https://medchoices.com.br:3001/admin/provas`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ ids: deleteTarget }),
        });

        if (!response.ok) {
          throw new Error(`Erro ao deletar provas. Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          setSnackbar({ 
            open: true, 
            message: 'Provas deletadas com sucesso!', 
            severity: 'success' 
          });
          await fetchProvas();
        } else {
          throw new Error(data.message || 'Erro ao deletar Provas.');
        }
      } else {
        // Exclusão única
        const response = await fetchWithTimeout(`https://medchoices.com.br:3001/admin/provas/${deleteTarget.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Erro ao deletar prova. Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          setSnackbar({
            open: true,
            message: 'Prova deletada com sucesso!',
            severity: 'success',
          });
          await fetchProvas();
        } else {
          throw new Error(data.message || 'Erro ao deletar prova.');
        }
      }
    } catch (error) {
      setSnackbar({ 
        open: true, 
        message: error.message || 'Erro ao deletar provas.', 
        severity: 'error' 
      });
    } finally {
      handleCloseDeleteModal();
    }
  };

  // Handle search with debounce
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        const newParams = new URLSearchParams(location.search);
        if (value) {
          newParams.set('search', value);
        } else {
          newParams.delete('search');
        }
        newParams.set('page', '1');
        newParams.set('limit', limitParam.toString());
        navigate(`?${newParams.toString()}`);
      }, 500),
    [location.search, navigate, limitParam]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    debouncedSearch(value);
  };

  // Handle selection
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedProvas(provas.map((p) => p.id));
    } else {
      setSelectedProvas([]);
    }
  };

  const handleSelectOne = (event, id) => {
    if (event.target.checked) {
      setSelectedProvas((prev) => [...prev, id]);
    } else {
      setSelectedProvas((prev) => prev.filter((pid) => pid !== id));
    }
  };

  const isAllSelected = provas.length > 0 && selectedProvas.length === provas.length;

  // Export single prova as TXT
  const handleExportSingleProva = async (provaId) => {
    try {
      setLoadingExport(true);
      console.log(`Iniciando exportação da prova ID: ${provaId}`);
      const response = await fetchWithTimeout(`https://medchoices.com.br:3001/admin/provas/${provaId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar prova. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Resposta do servidor ao buscar prova:', data);
      if (data.success) {
        const prova = data.prova;
        // Remover campos desnecessários
        const { id, materia_nome, created_at, updated_at, ...provaSemId } = prova;
        // Envolver a prova em um array para compatibilidade com o backend
        const provasArray = [provaSemId];
        const blob = new Blob([JSON.stringify(provasArray, null, 2)], { 
          type: 'text/plain' 
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        // Sanitize file name to remove invalid characters
        const sanitizedTitle = prova.titulo.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        link.href = url;
        link.download = `prova_${sanitizedTitle}.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        console.log(`Prova exportada como: prova_${sanitizedTitle}.txt`);
        setSnackbar({ 
          open: true, 
          message: 'Prova exportada com sucesso!', 
          severity: 'success' 
        });
      } else {
        throw new Error(data.message || 'Erro ao exportar prova.');
      }
    } catch (error) {
      console.error('Erro durante a exportação da prova:', error);
      setSnackbar({ 
        open: true, 
        message: error.message || 'Erro ao exportar prova.', 
        severity: 'error' 
      });
    } finally {
      setLoadingExport(false);
    }
  };

  // Import TXT
  const handleImportJSONClick = () => {
    fileInputRef.current?.click();
  };

  const handleImportJSON = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoadingImport(true);
      console.log(`Iniciando importação do arquivo: ${file.name}`);
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          const textData = e.target?.result;
          console.log('Conteúdo do arquivo lido:', textData);
          const jsonData = JSON.parse(textData);
          console.log('JSON parseado:', jsonData);
          if (!Array.isArray(jsonData)) {
            throw new Error('Formato JSON inválido. Deve ser um array de provas.');
          }

          // Remover campos desnecessários das provas importadas
          const provasImportadas = jsonData.map(prova => {
            const { id, materia_nome, created_at, updated_at, ...provaSemId } = prova;
            return provaSemId;
          });

          // Check for duplicate names
          const existingProvaNames = new Set(provas.map(p => p.titulo.toLowerCase())); // Certifique-se de comparar em minúsculas
          const duplicates = provasImportadas.filter(p => existingProvaNames.has(p.titulo.toLowerCase()));

          console.log('Provas duplicadas encontradas:', duplicates);

          if (duplicates.length > 0) {
            setDuplicateProvas(duplicates);
            setImportedProvas(provasImportadas);
            setIsDuplicateModalOpen(true);
          } else {
            // No duplicates, proceed with import
            console.log('Nenhuma duplicata encontrada. Procedendo com a importação.');
            await importProvas(provasImportadas);
          }
        } catch (err) {
          console.error('Erro ao processar o arquivo importado:', err);
          setSnackbar({ 
            open: true, 
            message: err.message || 'Arquivo JSON inválido ou estrutura incorreta.', 
            severity: 'error' 
          });
          setLoadingImport(false);
        }
      };
      
      reader.onerror = (e) => {
        console.error('Erro ao ler o arquivo:', e);
        setSnackbar({ 
          open: true, 
          message: 'Erro ao ler o arquivo.', 
          severity: 'error' 
        });
        setLoadingImport(false);
      };
      
      reader.readAsText(file);
    } catch (error) {
      console.error('Erro durante a importação das provas:', error);
      setSnackbar({ 
        open: true, 
        message: 'Erro ao importar provas.', 
        severity: 'error' 
      });
      setLoadingImport(false);
    }
  };

  // Function to import provas after handling duplicates
  const importProvas = async (provasToImport) => {
    try {
      console.log('Enviando provas para o servidor:', provasToImport);
      const response = await fetchWithTimeout('https://medchoices.com.br:3001/admin/provas/import', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ provas: provasToImport }),
      });

      console.log('Resposta do servidor após importação:', response);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Erro ao importar provas. Status: ${response.status}`, errorText);
        throw new Error(`Erro ao importar provas. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Resposta do servidor após importação:', data);
      if (data.success) {
        setSnackbar({ 
          open: true, 
          message: 'Provas importadas com sucesso!', 
          severity: 'success' 
        });
        await fetchProvas();
      } else {
        throw new Error(data.message || 'Erro ao importar provas.');
      }
    } catch (error) {
      console.error('Erro ao importar provas:', error);
      setSnackbar({ 
        open: true, 
        message: error.message || 'Erro ao importar provas.', 
        severity: 'error' 
      });
    } finally {
      setLoadingImport(false);
    }
  };

  // Handle renaming duplicates and proceed with import
  const handleResolveDuplicates = async () => {
    // Atualizar os títulos das provas importadas com os novos títulos fornecidos pelo usuário
    const updatedProvas = importedProvas.map(prova => {
      const duplicate = duplicateProvas.find(dp => dp.titulo.toLowerCase() === prova.titulo.toLowerCase());
      if (duplicate && duplicate.newTitle) {
        return { ...prova, titulo: duplicate.newTitle };
      }
      return prova;
    });

    console.log('Provas atualizadas após renomear duplicatas:', updatedProvas);

    setIsDuplicateModalOpen(false);
    setDuplicateProvas([]);
    setImportedProvas([]);

    // Proceder com a importação
    await importProvas(updatedProvas);
  };

  // Atualizar o título duplicado
  const handleDuplicateTitleChange = (titulo, newTitle) => {
    setDuplicateProvas(prev => prev.map(prova => 
      prova.titulo.toLowerCase() === titulo.toLowerCase() ? { ...prova, newTitle } : prova
    ));
  };

  // Função para lidar com a ordenação nos cabeçalhos usando TableSortLabel
  const handleSort = (field) => {
    if (sortField === field) {
      // Alterna a direção da ordenação
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // Define o novo campo de ordenação e reseta a direção para crescente
      setSortField(field);
      setSortOrder('asc');
    }

    // Atualizar os parâmetros da URL para refletir a ordenação
    const newParams = new URLSearchParams(location.search);
    newParams.set('sortField', field);
    newParams.set('sortOrder', sortField === field && sortOrder === 'asc' ? 'desc' : 'asc');
    navigate(`?${newParams.toString()}`);
  };

  // Ordenar as provas com base nos parâmetros
  const sortedProvas = useMemo(() => {
    if (!sortField) return provas;

    return [...provas].sort((a, b) => {
      let aField = a[sortField];
      let bField = b[sortField];

      // Se o campo for data, converter para Date
      if (sortField === 'created_at' || sortField === 'updated_at') {
        aField = new Date(aField);
        bField = new Date(bField);
      } else if (sortField === 'titulo' || sortField === 'materia_nome') {
        // Transformar para string e converter para minúsculas para comparação consistente
        aField = aField ? aField.toString().toLowerCase() : '';
        bField = bField ? bField.toString().toLowerCase() : '';
      }

      if (aField < bField) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (aField > bField) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [provas, sortField, sortOrder]);

  // Handle access level filter
  const handleAccessLevelFilterChange = (e) => {
    const value = e.target.value;
    setAccessLevelFilter(value);
    const newParams = new URLSearchParams(location.search);
    if (value && value !== 'All') {
      newParams.set('access_level', value); // Alterado para 'access_level'
    } else {
      newParams.delete('access_level');
    }
    newParams.set('page', '1'); // Resetar para a primeira página
    newParams.set('limit', limitParam.toString());
    navigate(`?${newParams.toString()}`);
  };

  // Handle materias filter
  const handleMateriasFilterChange = (e) => {
    const value = e.target.value;
    setMateriasFilter(value);
    const newParams = new URLSearchParams(location.search);
    if (value && value !== 'All') {
      newParams.set('materia_id', value); // Alterado para 'materia_id'
    } else {
      newParams.delete('materia_id');
    }
    newParams.set('page', '1'); // Resetar para a primeira página
    newParams.set('limit', limitParam.toString());
    navigate(`?${newParams.toString()}`);
  };

  // Handle Refresh
  const handleRefresh = () => {
    fetchProvas();
  };

  // Handle Close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box maxWidth="xl" sx={{margin: '0 auto', padding: 3 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/materias"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Matérias
        </MuiLink>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/provas"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Provas
        </MuiLink>
      </Breadcrumbs>

      {/* Header Card */}
      <Card 
        elevation={0}
        sx={{ 
          backgroundColor: alpha(theme.palette.primary.main, 0.05),
          mb: 3,
          borderRadius: 2
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" color="primary">
                Lista de Provas
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Total de {totalProvas} provas cadastradas
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  component={Link}
                  to="/admin/provas/create"
                  sx={{ 
                    borderRadius: 2,
                    textTransform: 'none',
                    px: 3,
                    py: 1.5
                  }}
                >
                  Nova Prova
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<UploadFileIcon />}
                  onClick={handleImportJSONClick}
                  disabled={loadingImport}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                  }}
                >
                  {loadingImport ? <CircularProgress size={24} /> : 'Importar TXT'}
                </Button>
                <input
                  type="file"
                  accept=".txt"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImportJSON}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Search and Filters Card */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Buscar provas..."
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="access-level-filter-label">Nível de Acesso</InputLabel>
                <Select
                  labelId="access-level-filter-label"
                  id="access-level-filter"
                  value={accessLevelFilter}
                  onChange={handleAccessLevelFilterChange}
                  label="Nível de Acesso"
                >
                  <MenuItem value="All">Todos</MenuItem>
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="premium">Premium</MenuItem>

                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>

      {/* Delete Selected Button */}
      {selectedProvas.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button 
            variant="contained"
            color="error"
            onClick={handleDeleteSelected}
            startIcon={<DeleteIcon />}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none'
            }}
          >
            Deletar {selectedProvas.length} {selectedProvas.length === 1 ? 'Prova' : 'Provas'}
          </Button>
        </Box>
      )}

      {/* Main Content */}
      {loadingFetch ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2, borderRadius: 2 }}>{error}</Alert>
      ) : (
        <Card sx={{ borderRadius: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.05) }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                      indeterminate={selectedProvas.length > 0 && selectedProvas.length < provas.length}
                      inputProps={{ 'aria-label': 'Selecionar todas as provas' }}
                    />
                  </TableCell>
                  {/* Título */}
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'titulo'}
                      direction={sortField === 'titulo' ? sortOrder : 'asc'}
                      onClick={() => handleSort('titulo')}
                    >
                      Título
                    </TableSortLabel>
                  </TableCell>
                  {/* Matéria */}
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'materia_nome'}
                      direction={sortField === 'materia_nome' ? sortOrder : 'asc'}
                      onClick={() => handleSort('materia_nome')}
                    >
                      Matéria
                    </TableSortLabel>
                  </TableCell>
                  {/* Tempo */}
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'tempo'}
                      direction={sortField === 'tempo' ? sortOrder : 'asc'}
                      onClick={() => handleSort('tempo')}
                    >
                      Tempo
                    </TableSortLabel>
                  </TableCell>
                  {/* Questões */}
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'total_questoes'}
                      direction={sortField === 'total_questoes' ? sortOrder : 'asc'}
                      onClick={() => handleSort('total_questoes')}
                    >
                      Questões
                    </TableSortLabel>
                  </TableCell>
                  {/* Nível de Acesso */}
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'access_level'}
                      direction={sortField === 'access_level' ? sortOrder : 'asc'}
                      onClick={() => handleSort('access_level')}
                    >
                      Nível de Acesso
                    </TableSortLabel>
                  </TableCell>
                  {/* Data de Criação */}
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'created_at'}
                      direction={sortField === 'created_at' ? sortOrder : 'asc'}
                      onClick={() => handleSort('created_at')}
                    >
                      Data de Criação
                    </TableSortLabel>
                  </TableCell>
                  {/* Ações */}
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProvas.map((prova) => {
                  console.log('Prova:', prova); // Log para verificar os dados
                  return (
                    <TableRow 
                      key={prova.id} 
                      hover
                      sx={{ '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.02) } }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedProvas.includes(prova.id)}
                          onChange={(e) => handleSelectOne(e, prova.id)}
                          inputProps={{ 'aria-labelledby': `prova-${prova.id}` }}
                        />
                      </TableCell>
                      {/* Título */}
                      <TableCell>
                        <Typography variant="subtitle2">{prova.titulo}</Typography>
                      </TableCell>
                      {/* Matéria */}
                      <TableCell>
                        <Chip
                          icon={<SchoolIcon sx={{ fontSize: 16 }} />}
                          label={prova.materia_nome}
                          size="small"
                          sx={{ borderRadius: 1 }}
                        />
                      </TableCell>
                      {/* Tempo */}
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <AccessTimeIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                          <Typography variant="body2">{prova.tempo} min</Typography>
                        </Box>
                      </TableCell>
                      {/* Questões */}
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<QuizIcon />}
                          component={Link}
                          to={`/admin/questoes?prova_id=${prova.id}${prova.materia_id ? `&materia_id=${prova.materia_id}` : ''}`}
                          sx={{ 
                            borderRadius: 1,
                            textTransform: 'none'
                          }}
                        >
                          {prova.total_questoes || 0} Questões
                        </Button>
                      </TableCell>
                      {/* Nível de Acesso */}
                      <TableCell>
                        {renderAccessLevel(prova.access_level)}
                      </TableCell>
                      {/* Data de Criação */}
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <CalendarTodayIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                          <Typography variant="body2">
                            {new Date(prova.created_at).toLocaleDateString()}
                          </Typography>
                        </Box>
                      </TableCell>
                      {/* Ações */}
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Tooltip title="Editar">
                            <IconButton 
                              size="small"
                              color="primary"
                              component={Link} 
                              to={`/admin/provas/edit/${prova.id}`}
                              sx={{ 
                                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.primary.main, 0.2)
                                }
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Exportar">
                            <IconButton 
                              size="small"
                              color="info"
                              onClick={() => handleExportSingleProva(prova.id)}
                              sx={{ 
                                backgroundColor: alpha(theme.palette.info.main, 0.1),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.info.main, 0.2)
                                }
                              }}
                            >
                              <DownloadIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Deletar">
                            <IconButton 
                              size="small"
                              color="error"
                              onClick={() => { setDeleteTarget(prova); setIsDeleteModalOpen(true); }}
                              sx={{ 
                                backgroundColor: alpha(theme.palette.error.main, 0.1),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.error.main, 0.2)
                                }
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {provas.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} align="center" sx={{ py: 8 }}>
                      <Typography variant="h6" color="text.secondary">
                        Nenhuma Prova encontrada
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Tente ajustar os filtros ou criar uma nova prova
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}

      {/* Feedback Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertSnackbar 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>

      {/* Duplicate Provas Modal */}
      <Dialog open={isDuplicateModalOpen} onClose={() => {}} maxWidth="sm" fullWidth>
        <DialogTitle>Provas com Nomes Duplicados</DialogTitle>
        <DialogContent>
          <Typography>
            Algumas provas que você está tentando importar possuem nomes que já existem. Por favor, altere os nomes para evitar duplicatas.
          </Typography>
          <Box component="form" sx={{ mt: 2 }}>
            {duplicateProvas.map((prova, index) => {
              const isDuplicate = provas.some(p => p.titulo.toLowerCase() === (prova.newTitle || prova.titulo).toLowerCase());
              return (
                <TextField
                  key={prova.titulo}
                  label={`Novo Título para "${prova.titulo}"`}
                  fullWidth
                  margin="normal"
                  value={prova.newTitle || prova.titulo}
                  onChange={(e) => handleDuplicateTitleChange(prova.titulo, e.target.value)}
                  error={isDuplicate}
                  helperText={isDuplicate ? 'Este título já está em uso. Por favor, escolha outro.' : ''}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              // Permitir que o usuário cancele a importação
              setIsDuplicateModalOpen(false);
              setDuplicateProvas([]);
              setImportedProvas([]);
              setLoadingImport(false);
              setSnackbar({ 
                open: true, 
                message: 'Importação cancelada.', 
                severity: 'info' 
              });
            }} 
            color="secondary"
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleResolveDuplicates} 
            variant="contained" 
            color="primary"
            disabled={
              duplicateProvas.some(prova => 
                !prova.newTitle || 
                prova.newTitle.trim() === '' ||
                provas.some(p => p.titulo.toLowerCase() === prova.newTitle.toLowerCase())
              )
            }
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Confirmação de Exclusão */}
      <Dialog
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">
          {Array.isArray(deleteTarget) ? 'Confirmar Exclusão' : 'Confirmar Exclusão'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {Array.isArray(deleteTarget)
              ? `Tem certeza que deseja deletar ${deleteTarget.length} prova(s) selecionada(s)? Esta ação não pode ser desfeita.`
              : `Tem certeza que deseja deletar a prova "${deleteTarget?.titulo}"? Esta ação não pode ser desfeita.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProvasList;
