import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  useTheme,
  useMediaQuery,
  Fade,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { XCircle, ArrowLeft, RefreshCw } from 'lucide-react';
import { motion } from 'framer-motion';

const CancelPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleGoBack = () => {
    navigate('/upgrade');
  };

  const handleTryAgain = () => {
    navigate('/upgrade');
  };

  const MotionPaper = motion(Paper);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 4,
          px: { xs: 2, sm: 4 },
        }}
      >
        <Fade in timeout={1000}>
          <MotionPaper
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            elevation={3}
            sx={{
              p: { xs: 3, sm: 6 },
              borderRadius: 2,
              textAlign: 'center',
              width: '100%',
              backgroundColor: 'background.paper',
            }}
          >
            <XCircle
              size={isMobile ? 48 : 64}
              color="#f44336"
              style={{ margin: '0 auto' }}
            />
            
            <Typography
              variant={isMobile ? "h5" : "h4"}
              component="h1"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            >
              Assinatura Cancelada
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 4,
                color: 'text.secondary',
                maxWidth: '400px',
                mx: 'auto',
                lineHeight: 1.6,
              }}
            >
              A sua assinatura não foi concluída. Não se preocupe, você pode tentar novamente quando desejar.
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                justifyContent: 'center',
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleGoBack}
                startIcon={<ArrowLeft size={20} />}
                sx={{
                  py: 1.5,
                  px: 3,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                }}
              >
                Voltar
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleTryAgain}
                startIcon={<RefreshCw size={20} />}
                sx={{
                  py: 1.5,
                  px: 3,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                  boxShadow: theme.shadows[2],
                  '&:hover': {
                    boxShadow: theme.shadows[4],
                  },
                }}
              >
                Tentar Novamente
              </Button>
            </Box>
          </MotionPaper>
        </Fade>
      </Box>
    </Container>
  );
};

export default CancelPage;