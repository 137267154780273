import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  CircularProgress, 
  Alert, 
  Box,
  Paper,
  Container,
  Fade,
  Breadcrumbs,
  Link,
  Stack
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Book as BookIcon, ArrowLeft as ArrowLeftIcon } from '@mui/icons-material';

const MateriasForm = () => {
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchMateria = async () => {
        setLoading(true);
        setError('');
        try {
          const response = await fetch(`https://medchoices.com.br:3001/admin/materias`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });

          const data = await response.json();

          if (data.success) {
            const materia = data.materias.find((m) => m.id === parseInt(id));
            if (materia) {
              setNome(materia.nome);
              setDescricao(materia.descricao);
            } else {
              setError('Matéria não encontrada.');
            }
          } else {
            setError(data.message || 'Erro ao buscar Matéria.');
          }
        } catch (error) {
          console.error('Erro ao buscar Matéria:', error);
          setError('Erro ao buscar Matéria.');
        } finally {
          setLoading(false);
        }
      };

      fetchMateria();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const data = { nome, descricao };

    try {
      const response = await fetch(
        id 
          ? `https://medchoices.com.br:3001/admin/materias/${id}` 
          : `https://medchoices.com.br:3001/admin/materias`,
        {
          method: id ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
        }
      );

      const resData = await response.json();

      if (resData.success) {
        navigate('/admin/materias');
      } else {
        setError(resData.message || 'Erro ao salvar Matéria.');
      }
    } catch (error) {
      console.error('Erro ao salvar Matéria:', error);
      setError('Erro ao salvar Matéria.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link
            component="button"
            variant="body1"
            onClick={() => navigate('/admin')}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Admin
          </Link>
          <Link
            component="button"
            variant="body1"
            onClick={() => navigate('/admin/materias')}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Matérias
          </Link>
          <Typography color="text.primary">
            {id ? 'Editar' : 'Nova Matéria'}
          </Typography>
        </Breadcrumbs>

        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <BookIcon sx={{ fontSize: 32, mr: 2, color: 'primary.main' }} />
            <Typography variant="h4" component="h1" color="primary">
              {id ? 'Editar Matéria' : 'Nova Matéria'}
            </Typography>
          </Box>

          <Fade in={Boolean(error)}>
            <Box sx={{ mb: 3 }}>
              {error && <Alert severity="error" variant="filled">{error}</Alert>}
            </Box>
          </Fade>

          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                label="Nome da Matéria"
                variant="outlined"
                fullWidth
                required
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                disabled={isSubmitting}
                InputProps={{
                  sx: { borderRadius: 1.5 }
                }}
              />

              <TextField
                label="Descrição"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                disabled={isSubmitting}
                InputProps={{
                  sx: { borderRadius: 1.5 }
                }}
                helperText="Descreva o conteúdo e objetivos desta matéria"
              />

              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/admin/materias')}
                  startIcon={<ArrowLeftIcon />}
                  disabled={isSubmitting}
                  sx={{ borderRadius: 1.5 }}
                >
                  Voltar
                </Button>
                
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{ 
                    borderRadius: 1.5,
                    px: 4,
                    ml: 'auto'
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : id ? (
                    'Atualizar Matéria'
                  ) : (
                    'Criar Matéria'
                  )}
                </Button>
              </Box>
            </Stack>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default MateriasForm;