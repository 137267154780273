// src/pages/PremiumFeature.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PremiumFeature = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/inicio'); // Redireciona o usuário para a página inicial
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Bem-vindo à Funcionalidade Premium!
      </Typography>
      <Typography variant="body1" sx={{ maxWidth: '600px' }}>
        Esta é uma funcionalidade exclusiva para usuários premium. 
        Aqui você pode adicionar funcionalidades avançadas, conteúdos especiais ou ferramentas exclusivas.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleBackToHome}>
        Voltar para Início
      </Button>
    </Box>
  );
};

export default PremiumFeature;
