import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  IconButton,
  Alert,
  Drawer,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Button,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
  Fade,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  Search as SearchIcon,
  TuneRounded as TuneIcon,
  Refresh as RefreshIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
  TrendingUp as TrendingUpIcon,
  AccessTime as AccessTimeIcon,
  GridView as GridViewIcon,
  ViewList as ViewListIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { alpha } from '@mui/material/styles';

const HistoricoUsuario = () => {
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(9); // Valor fixo conforme o código original
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('data_realizacao');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filtroPontuacao, setFiltroPontuacao] = useState([0, 100]);
  const [viewMode, setViewMode] = useState('grid');

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fetchHistorico = async (search, sortBy, sortOrder, currentPage, limit, pontuacaoRange) => {
    setLoading(true);
    setError(null);
    try {
      const url = `https://medchoices.com.br:3001/admin/me/historico?page=${currentPage}&limit=${limit}&search=${encodeURIComponent(
        search
      )}&sortBy=${sortBy}&order=${sortOrder}&pontuacao_min=${pontuacaoRange[0]}&pontuacao_max=${pontuacaoRange[1]}`;

      console.log('Fetching URL:', url); // Log da URL

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const data = await response.json();

      console.log('API Response:', data); // Log detalhado da resposta

      if (data.success) {
        // Converter 'pontuacao' e 'tempo_utilizado' para números
        const historicoParsed = data.historico.map(item => ({
          ...item,
          pontuacao: parseFloat(item.pontuacao),
          tempo_utilizado: Number(item.tempo_utilizado),
        }));
        setHistorico(historicoParsed);
        setTotal(data.total);
      } else {
        setError(data.message || 'Erro ao carregar o histórico. Tente novamente.');
      }
    } catch (error) {
      setError('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  };

  // Debounce apenas para a busca
  const debouncedSearch = useMemo(
    () =>
      debounce((search) => {
        setPage(1);
        fetchHistorico(search, orderBy, order, 1, rowsPerPage, filtroPontuacao);
      }, 300),
    [orderBy, order, rowsPerPage, filtroPontuacao]
  );

  // Efeito para busca com debounce
  useEffect(() => {
    console.log('Aplicando filtros:');
    console.log('Search Term:', searchTerm);
    console.log('Order By:', orderBy);
    console.log('Order:', order);
    console.log('Page:', page);
    console.log('Rows Per Page:', rowsPerPage);
    console.log('Pontuacao Range:', filtroPontuacao);

    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      fetchHistorico(searchTerm, orderBy, order, page, rowsPerPage, filtroPontuacao);
    }
    return () => debouncedSearch.cancel();
  }, [searchTerm, orderBy, order, rowsPerPage, filtroPontuacao, debouncedSearch]);

  // Efeito separado para paginação sem debounce
  useEffect(() => {
    console.log('Mudança de página:');
    console.log('Page:', page);
    fetchHistorico(searchTerm, orderBy, order, page, rowsPerPage, filtroPontuacao);
  }, [page, rowsPerPage, searchTerm, orderBy, order, filtroPontuacao]);

  // Ordenar no frontend, caso a API não esteja fazendo corretamente
  useEffect(() => {
    if (historico.length > 0) {
      const sortedHistorico = [...historico].sort((a, b) => {
        if (orderBy === 'pontuacao') {
          return order === 'asc' ? a.pontuacao - b.pontuacao : b.pontuacao - a.pontuacao;
        } else if (orderBy === 'tempo_utilizado') {
          return order === 'asc' ? a.tempo_utilizado - b.tempo_utilizado : b.tempo_utilizado - a.tempo_utilizado;
        } else if (orderBy === 'data_realizacao') {
          return order === 'asc'
            ? new Date(a.data_realizacao) - new Date(b.data_realizacao)
            : new Date(b.data_realizacao) - new Date(a.data_realizacao);
        }
        return 0;
      });
      // console.log('Sorted Historico:', sortedHistorico);
      setHistorico(sortedHistorico);
    }
  }, [historico, orderBy, order]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOrderChange = (newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(newOrderBy);
    setPage(1); // Reseta para a primeira página ao mudar a ordenação
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handlePontuacaoChange = (event, newValue) => {
    setFiltroPontuacao(newValue);
    setPage(1);
  };

  const handleRefresh = () => {
    setPage(1); // Reseta para a primeira página
    toggleDrawer(); // Fecha a gaveta
  };

  const getPontuacaoColor = (pontuacao) => {
    if (pontuacao >= 80) return theme.palette.success.main;
    if (pontuacao >= 60) return theme.palette.info.main;
    if (pontuacao >= 40) return theme.palette.warning.main;
    return theme.palette.error.main;
  };

  const toggleViewMode = () => {
    setViewMode(prev => prev === 'grid' ? 'list' : 'grid');
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: { xs: 2, md: 4 } }}>
      <Container maxWidth="xl">
        {/* Removido o Card que envolvia o Header */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            // p: 3,
            borderRadius: 4,
            mb: 3,
            // boxShadow: theme.shadows[3],
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, md: 2 } }}>
                {/* 
                  Adicionando display responsivo para ocultar o ícone no mobile.
                  O ícone ficará visível apenas em telas médias (md) ou maiores.
                */}
                <Box
                  sx={{
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    p: 2,
                    borderRadius: 2,
                    display: { xs: 'none', md: 'flex' }, // Oculta no mobile
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AssignmentIcon
                    sx={{
                      fontSize: 32,
                      color: theme.palette.primary.main,
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: '1.5rem', sm: '2rem', md: '2.25rem' },
                      fontWeight: 600,
                      color: 'primary.main',
                    }}
                  >
                    Histórico de Provas
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    Total de Provas: {total}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Buscar..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                    },
                  }}
                />
                <Tooltip title="Filtros Avançados">
                  <IconButton
                    onClick={toggleDrawer}
                    sx={{
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.2),
                      },
                    }}
                  >
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={viewMode === 'grid' ? 'Visualizar em Lista' : 'Visualizar em Grid'}>
                  <IconButton
                    onClick={toggleViewMode}
                    sx={{
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.2),
                      },
                    }}
                  >
                    {viewMode === 'grid' ? <ViewListIcon /> : <GridViewIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Drawer de Filtros */}
        <Drawer
          anchor={isMobile ? 'bottom' : 'right'}
          open={drawerOpen}
          onClose={toggleDrawer}
          PaperProps={{
            sx: {
              width: isMobile ? '100%' : 400,
              height: isMobile ? '90vh' : '100%',
              borderTopLeftRadius: isMobile ? 16 : 0,
              borderTopRightRadius: isMobile ? 16 : 0,
              p: 3,
            },
          }}
        >
          <Box sx={{ position: 'relative', height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6">Filtros Avançados</Typography>
              <IconButton onClick={toggleDrawer} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ mb: 3 }} />

            <Box sx={{ height: 'calc(100% - 180px)', overflowY: 'auto' }}>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Ordenar Por</InputLabel>
                <Select
                  value={orderBy}
                  label="Ordenar Por"
                  onChange={(e) => handleOrderChange(e.target.value)}
                >
                  <MenuItem value="data_realizacao">Data de Realização</MenuItem>
                  <MenuItem value="pontuacao">Pontuação</MenuItem>
                  <MenuItem value="tempo_utilizado">Tempo Utilizado</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Ordem</InputLabel>
                <Select
                  value={order}
                  label="Ordem"
                  onChange={(e) => { setOrder(e.target.value); setPage(1); }}
                >
                  <MenuItem value="asc">Crescente</MenuItem>
                  <MenuItem value="desc">Decrescente</MenuItem>
                </Select>
              </FormControl>

              {/* <Typography gutterBottom>Faixa de Pontuação (%)</Typography>
              <Slider
                value={filtroPontuacao}
                onChange={handlePontuacaoChange}
                valueLabelDisplay="auto"
                min={0}
                max={100}
                sx={{ mb: 3 }}
              /> */}
            </Box>

            <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, p: 2, bgcolor: 'background.paper' }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleRefresh}
                startIcon={<RefreshIcon />}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: theme.shadows[2],
                  },
                }}
                disabled={
                  orderBy === 'data_realizacao' &&
                  order === 'desc' &&
                  filtroPontuacao[0] === 0 &&
                  filtroPontuacao[1] === 100
                }
              >
                Aplicar Filtros
              </Button>
            </Box>
          </Box>
        </Drawer>

        {/* Feedback de Erro */}
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {/* Loading State */}
        {loading && (
          <Box sx={{ width: '100%', mb: 3 }}>
            <LinearProgress />
          </Box>
        )}

        {/* Lista de Provas */}
        <Fade in={!loading}>
          <Box>
            {historico.length > 0 ? (
              viewMode === 'grid' ? (
                <Grid container spacing={3}>
                  {historico.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.resultado_id}>
                      {/* Substituído o Card por Box */}
                      <Box
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          borderRadius: 3,
                          border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: theme.shadows[8],
                            border: `1px solid ${theme.palette.primary.main}`,
                          },
                          p: 2,
                          bgcolor: 'background.paper',
                        }}
                      >
                        {/* Cabeçalho com Título e Botão */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
                            {item.prova_titulo}
                          </Typography>
                          <IconButton
                            onClick={() => navigate(`/historico/${item.resultado_id}`)}
                            sx={{
                              color: 'primary.main',
                              '&:hover': {
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                              },
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Box>

                        {/* Adicionando a Data da Prova */}
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {new Date(item.data_realizacao).toLocaleDateString('pt-BR', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                          })}
                        </Typography>

                        {/* Conteúdo */}
                        <Box sx={{ flexGrow: 1, pt: 0, mt: 2 }}>
                          <Box
                            sx={{
                              mb: 3,
                              p: 2,
                              borderRadius: 2,
                              bgcolor: alpha(getPontuacaoColor(item.pontuacao), 0.1),
                            }}
                          >
                            <Typography variant="subtitle2" gutterBottom>
                              Pontuação
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <TrendingUpIcon sx={{ color: getPontuacaoColor(item.pontuacao), mr: 1 }} />
                              <Typography
                                variant="h4"
                                sx={{
                                  color: getPontuacaoColor(item.pontuacao),
                                  fontWeight: 600,
                                }}
                              >
                                {item.pontuacao}%
                              </Typography>
                            </Box>
                            <LinearProgress
                              variant="determinate"
                              value={item.pontuacao}
                              sx={{
                                height: 8,
                                borderRadius: 4,
                                bgcolor: alpha(getPontuacaoColor(item.pontuacao), 0.2),
                                '& .MuiLinearProgress-bar': {
                                  bgcolor: getPontuacaoColor(item.pontuacao),
                                },
                              }}
                            />
                          </Box>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  p: 1.5,
                                  borderRadius: 2,
                                  bgcolor: alpha(theme.palette.success.main, 0.1),
                                }}
                              >
                                <CheckCircleIcon color="success" sx={{ mr: 1, fontSize: 20 }} />
                                <Typography variant="body2">
                                  {item.acertos} acertos
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  p: 1.5,
                                  borderRadius: 2,
                                  bgcolor: alpha(theme.palette.error.main, 0.1),
                                }}
                              >
                                <CancelIcon color="error" sx={{ mr: 1, fontSize: 20 }} />
                                <Typography variant="body2">
                                  {item.erros} erros
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: 2,
                              p: 1.5,
                              borderRadius: 2,
                              bgcolor: alpha(theme.palette.grey[500], 0.1),
                            }}
                          >
                            <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 20 }} />
                            <Typography variant="body2" color="text.secondary">
                              {Math.floor(item.tempo_utilizado / 60)}m {item.tempo_utilizado % 60}s
                            </Typography>
                          </Box>
                        </Box>

                        {/* Botão */}
                        <Box sx={{ p: 2 }}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => navigate(`/historico/${item.resultado_id}`)}
                            startIcon={<VisibilityIcon />}
                            sx={{
                              borderRadius: 2,
                              textTransform: 'none',
                              boxShadow: 'none',
                              '&:hover': {
                                boxShadow: theme.shadows[2],
                              },
                            }}
                          >
                            Ver Detalhes
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                // Modo Lista: Removido o Paper e Card
                <Box
                  sx={{
                    border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
                    borderRadius: 3,
                    boxShadow: theme.shadows[3],
                    overflow: 'hidden',
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Prova</TableCell>
                        <TableCell align="center">Data</TableCell>
                        <TableCell align="center">Pontuação</TableCell>
                        <TableCell align="center">Acertos</TableCell>
                        <TableCell align="center">Erros</TableCell>
                        <TableCell align="center">Tempo</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historico.map((item) => (
                        <TableRow
                          key={item.resultado_id}
                          sx={{
                            '&:hover': {
                              bgcolor: alpha(theme.palette.primary.main, 0.04),
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                              {item.prova_titulo}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {new Date(item.data_realizacao).toLocaleDateString('pt-BR')}
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                bgcolor: alpha(getPontuacaoColor(item.pontuacao), 0.1),
                                color: getPontuacaoColor(item.pontuacao),
                                px: 2,
                                py: 0.5,
                                borderRadius: 2,
                              }}
                            >
                              {item.pontuacao}%
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box sx={{ color: 'success.main', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <CheckCircleIcon sx={{ fontSize: 16, mr: 0.5 }} />
                              {item.acertos}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box sx={{ color: 'error.main', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <CancelIcon sx={{ fontSize: 16, mr: 0.5 }} />
                              {item.erros}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            {Math.floor(item.tempo_utilizado / 60)}m {item.tempo_utilizado % 60}s
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={() => navigate(`/historico/${item.resultado_id}`)}
                              size="small"
                              sx={{ color: 'primary.main' }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '50vh',
                  textAlign: 'center',
                }}
              >
                <AssignmentIcon
                  sx={{
                    fontSize: 80,
                    color: 'text.secondary',
                    mb: 2,
                  }}
                />
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Nenhum histórico encontrado
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
                  Realize algumas provas para começar a construir seu histórico.
                  Use os filtros para refinar sua busca quando tiver registros.
                </Typography>
              </Box>
            )}
          </Box>
        </Fade>

        {/* Paginação */}
        {!loading && historico.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              mt: 4,
            }}
          >
            <Button
              variant="outlined"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              Anterior
            </Button>
            <Typography variant="body1">
              Página {page} de {Math.ceil(total / rowsPerPage)}
            </Typography>
            <Button
              variant="outlined"
              disabled={page >= Math.ceil(total / rowsPerPage)}
              onClick={() => setPage(page + 1)}
            >
              Próxima
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default HistoricoUsuario;
