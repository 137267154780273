import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Alert,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Breadcrumbs,
  Link as MuiLink,
  Snackbar,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Chip,
  Stack,
  Paper,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  Home as HomeIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AccessTime as AccessTimeIcon,
  Star as StarIcon,
  CreditCard as CreditCardIcon,
  CalendarMonth as CalendarIcon,
  Timer as TimerIcon,
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';

const SubscriptionPage = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [error, setError] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const fetchSubscription = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://medchoices.com.br:3001/my-subscription', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error || 'Falha ao carregar assinatura.');
      }
      const data = await response.json();
      if (data.success) {
        setSubscription(data.subscription);
      } else {
        throw new Error(data.error || 'Não foi possível carregar assinatura.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      fetchSubscription();
    }
  }, [auth?.token]);

  const handleCancelSubscription = async () => {
    setCanceling(true);
    setError('');
    try {
      const response = await fetch('https://medchoices.com.br:3001/cancel-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Erro ao cancelar assinatura.');
      }

      setSnackbar({
        open: true,
        message: data.message || 'Cancelamento solicitado com sucesso! Você manterá acesso premium até o fim do período atual.',
        severity: 'success',
      });

      setSubscription((prev) =>
        prev ? { ...prev, status: 'premium_cancelado' } : null
      );
    } catch (err) {
      setError(err.message);
      setSnackbar({
        open: true,
        message: err.message || 'Erro ao cancelar assinatura.',
        severity: 'error',
      });
    } finally {
      setCanceling(false);
      setConfirmOpen(false);
    }
  };

  const getStatusChip = (status) => {
    const statusConfig = {
      premium: { color: 'success', icon: <StarIcon />, label: 'Premium' },
      premium_cancelado: { color: 'warning', icon: <AccessTimeIcon />, label: 'Premium (Cancelado)' },
      free: { color: 'default', icon: <CheckCircleIcon />, label: 'Gratuito' },
    };

    const config = statusConfig[status] || statusConfig.free;

    return (
      <Chip
        icon={config.icon}
        label={config.label}
        color={config.color}
        variant="filled"
        sx={{ fontSize: '1rem', height: 32 }}
      />
    );
  };

  const isPremiumStatus = (status) => ['premium', 'premium_cancelado'].includes(status);

  return (
    <Box
      sx={{
        minHeight: '100vh',
       
      }}
    >
      <Container maxWidth="xl" sx={{ py: { xs: 3, sm: 5 } }}>
        <Box sx={{ maxWidth: 'lg', mx: 'auto' }}>
          {/* Breadcrumbs */}
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              mb: 4,
              display: isMobile ? 'none' : 'flex',
            }}
          >
            <MuiLink
              underline="hover"
              color="inherit"
              component={Link}
              to="/admin/materias"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
              Início
            </MuiLink>
            <Typography color="text.primary">Minha Assinatura</Typography>
          </Breadcrumbs>

          {/* Header */}
          <Box sx={{ mb: 6 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              spacing={2}
            >
              <Typography 
                variant="h4" 
                component="h1" 
                sx={{ 
                  fontWeight: 700,
                  background: 'linear-gradient(45deg, #1976d2, #42a5f5)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'primary',
                }}
              >
                Minha Assinatura
              </Typography>
              {subscription?.status && getStatusChip(subscription.status)}
            </Stack>
          </Box>

          {/* Main Content */}
          {loading ? (
            <Paper 
              elevation={0} 
              sx={{ 
                p: 4, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                gap: 2,
                bgcolor: 'background.paper',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
              }}
            >
              <CircularProgress size={24} />
              <Typography>Carregando dados da assinatura...</Typography>
            </Paper>
          ) : error ? (
            <Alert 
              severity="error" 
              sx={{ 
                borderRadius: 2,
                mb: 4,
                '& .MuiAlert-message': { width: '100%' }
              }}
            >
              {error}
            </Alert>
          ) : subscription ? (
            <Grid container spacing={4}>
              {/* Subscription Details */}
              {isPremiumStatus(subscription.status) && (
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 4,
                      height: '100%',
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'divider',
                      background: 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
                    }}
                  >
                    <Stack spacing={4}>
                      <Box>
                        <Typography 
                          variant="h6" 
                          gutterBottom 
                          sx={{ 
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <CreditCardIcon />
                          Detalhes da Assinatura
                        </Typography>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                        <Box sx={{ textAlign: 'left', mt: 6 }}>
        <Typography variant="body2" color="text.secondary">
        O plano será renovado automaticamente. Caso não queira a renovação, lembre-se de cancelar a assinatura antecipadamente.
        </Typography>
      </Box>
                      </Box>

                      {subscription.data_inicio && (
                        <Box>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <CalendarIcon color="action" />
                            <Box>
                              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Data de Início
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {new Date(subscription.data_inicio).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      )}

                      {subscription.data_fim && (
                        <Box>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <TimerIcon color="action" />
                            <Box>
                              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Data de Término
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {new Date(subscription.data_fim).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      )}
                    </Stack>
                  </Paper>
                </Grid>
              )}

              {/* Subscription Actions */}
              <Grid item xs={12} md={isPremiumStatus(subscription.status) ? 6 : 12}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    height: '100%',
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    background: subscription.status === 'free' 
                      ? 'linear-gradient(145deg, #3D9A8B 0%, #3D9A8B 100%)'
                      : 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
                  }}
                >
                  {subscription.status === 'premium' && (
                    <Stack spacing={3}>
                      <Typography variant="h6" gutterBottom color="error">
                        Cancelar Assinatura
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ao cancelar sua assinatura, você manterá acesso aos recursos premium até o fim do período atual.
                      </Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setConfirmOpen(true)}
                        disabled={canceling}
                        startIcon={<CancelIcon />}
                        size="large"
                        sx={{ alignSelf: 'flex-start', px: 4 }}
                      >
                        {canceling ? 'Cancelando...' : 'Cancelar Assinatura'}
                      </Button>
                    </Stack>
                  )}

                  {subscription.status === 'premium_cancelado' && (
                    <Alert
                      severity="info"
                      sx={{
                        borderRadius: 2,
                        bgcolor: 'rgba(2, 136, 209, 0.08)',
                        '& .MuiAlert-icon': {
                          color: 'primary.main'
                        }
                      }}
                      icon={<AccessTimeIcon />}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
                        Assinatura Cancelada
                      </Typography>
                      <Typography variant="body2">
                        Você manterá acesso premium até o fim do ciclo atual.
                      </Typography>
                    </Alert>
                  )}

                  {subscription.status === 'free' && (
                    <Stack spacing={3} alignItems="center" sx={{ color: 'white', bgcolor: 'primary.main', }}>
                      <StarIcon sx={{ fontSize: 48 }} />
                      <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
                        Aproveite ainda mais benefícios com o plano Premium!
                      </Typography>
                      <Typography variant="body1" align="center" sx={{ opacity: 0.9 }}>
                        Desbloqueie recursos exclusivos e tenha uma experiência completa.
                      </Typography>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate('/upgrade')}
                        startIcon={<StarIcon />}
                        sx={{
                          px: 4,
                          py: 1.5,
                          bgcolor: 'white',
                          color: 'primary.main',
                          '&:hover': {
                            bgcolor: 'rgba(255, 255, 255, 0.9)',
                          }
                        }}
                      >
                        Tornar-se Premium
                      </Button>
                    </Stack>
                  )}
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1" color="text.secondary" align="center">
              Não foi possível carregar as informações de assinatura.
            </Typography>
          )}
        </Box>

        {/* Cancel Subscription Dialog */}
        <Dialog
          open={confirmOpen}
          onClose={() => !canceling && setConfirmOpen(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: { borderRadius: 2 }
          }}
        >
          <DialogTitle>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <CancelIcon color="error" />
              <Typography variant="h6">Confirmar Cancelamento</Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Tem certeza que deseja cancelar sua assinatura?
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Você permanecerá premium até o final do período atual, mas não será renovado.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button
              onClick={() => setConfirmOpen(false)}
              disabled={canceling}
              variant="outlined"
            >
              Voltar
            </Button>
            <Button
              onClick={handleCancelSubscription}
              color="error"
              variant="contained"
              disabled={canceling}
              startIcon={canceling ? <CircularProgress size={20} /> : <CancelIcon />}
            >
              {canceling ? 'Cancelando...' : 'Confirmar Cancelamento'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default SubscriptionPage;