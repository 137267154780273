// frontend/src/pages/MateriasDisponiveis.js

import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Alert,
    Grid,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Container,
    Paper,
    useTheme,
    useMediaQuery,
    Skeleton,
    Pagination,
    Chip,
    Tooltip,
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    Search as SearchIcon,
    Assignment as AssignmentIcon,
    QuestionAnswer as QuestionAnswerIcon,
    AccessTime as AccessTimeIcon,
    Lock as LockIcon,
    LockOpen as LockOpenIcon,
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Importe o AuthContext

const MateriasDisponiveis = ({
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    itemsPerPage,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [materias, setMaterias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [expandedAccordion, setExpandedAccordion] = useState(null);

    const navigate = useNavigate();
    const { auth } = useContext(AuthContext); // Acesse o contexto de autenticação

    useEffect(() => {
        const fetchMateriasDisponiveis = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('token');

                if (!token) {
                    throw new Error('Token de autenticação não encontrado.');
                }

                const [materiasResponse, provasResponse] = await Promise.all([
                    fetch('https://medchoices.com.br:3001/admin/materias', {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    fetch('https://medchoices.com.br:3001/admin/provas', {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);

                if (!materiasResponse.ok || !provasResponse.ok) {
                    throw new Error('Erro ao buscar dados de matérias ou provas.');
                }

                const materiasData = await materiasResponse.json();
                const provasData = await provasResponse.json();

                if (materiasData.success && provasData.success) {
                    const materiasComProvas = materiasData.materias.map((materia) => ({
                        ...materia,
                        provas: provasData.provas.filter(
                            (prova) => prova.materia_id === materia.id && prova.total_questoes > 0
                        ),
                    }));

                    setMaterias(materiasComProvas.filter((materia) => materia.provas.length > 0));
                } else {
                    setMaterias([]);
                }
            } catch (error) {
                console.error('Erro ao carregar matérias disponíveis:', error.message);
                setError('Erro ao carregar matérias. Por favor, tente novamente mais tarde.');
            } finally {
                setLoading(false);
            }
        };

        fetchMateriasDisponiveis();
    }, []);

    const filteredMaterias = useMemo(() => materias.filter((materia) => {
        const materiaNome = materia?.nome || '';
        const searchQuery = searchTerm || '';
        return materiaNome.toLowerCase().includes(searchQuery.toLowerCase());
    }), [searchTerm, materias]);

    const totalPages = Math.ceil(filteredMaterias.length / itemsPerPage);
    const paginatedMaterias = useMemo(() => filteredMaterias.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ), [filteredMaterias, currentPage, itemsPerPage]);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Função para verificar o status do usuário
    const getUserStatus = () => {
        if (!auth?.isAuthenticated || !auth?.user) return 'free';
        const status = auth.user.status;
        if (status === 'premium' || status === 'premium_cancelado') {
            return 'premium';
        }
        return 'free';
    };

    const userStatus = getUserStatus();

    // Função para lidar com o clique na prova
    const handleProvaClick = (prova) => {
        if (prova.access_level === 'premium') {
            if (userStatus === 'free') {
                navigate('/upgrade');
            } else {
                navigate('/provas', { state: { provaId: prova.id } });
            }
        } else {
            // Prova gratuita, sempre redireciona para /provas
            navigate('/provas', { state: { provaId: prova.id } });
        }
    };

    return (
        <Box sx={{ bgcolor: 'grey.50', minHeight: '100vh', py: { xs: 2, md: 4 } }}>
            <Container maxWidth="xl">
                <Grid item xs={12} md={6} mb={4}>
                    <Box sx={{
                        display: 'flex', alignItems: 'center', gap: { xs: 1, md: 2 }, backgroundColor: (theme) => theme.palette.primary.main + '0A',
                        mb: 3,
                        p: 3,
                        borderRadius: 2,
                    }}>
                        <Box
                            sx={{
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                p: 2,
                                borderRadius: 2,
                                display: { xs: 'none', md: 'flex' },
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <AssignmentIcon
                                sx={{
                                    fontSize: 32,
                                    color: theme.palette.primary.main,
                                }}
                            />
                        </Box>
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.25rem' },
                                    fontWeight: 600,
                                    color: 'primary.main',
                                }}
                            >
                                Matérias Disponíveis
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                                {materias.length} {materias.length === 1 ? 'matéria' : 'matérias'} disponíveis
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Box
                    sx={{
                        textAlign: 'center',
                        marginBottom: '20px',
                    }}
                >
                    <Typography>
                        Acesse o menu{' '}
                        <Typography
                            component="span"
                            sx={{ color: 'primary.main', cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => navigate('/provas')}
                        >
                            'Provas'
                        </Typography>{' '}
                        e siga as instruções para realizar sua prova.
                    </Typography>
                </Box>

                <Paper
                    elevation={0}
                    sx={{
                        p: { xs: 2, md: 3 },
                        borderRadius: 3,
                        bgcolor: 'background.paper',
                        boxShadow: 'none',
                    }}
                >
                    <TextField
                        fullWidth
                        placeholder="Buscar Matéria"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
                        }}
                        sx={{
                            mb: 3,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                            },
                        }}
                    />

                    {loading ? (
                        Array.from(new Array(3)).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant="rectangular"
                                height={100}
                                sx={{ mb: 2, borderRadius: 2 }}
                            />
                        ))
                    ) : error ? (
                        <Alert
                            severity="error"
                            sx={{
                                borderRadius: 2,
                                '& .MuiAlert-icon': { fontSize: '2rem' },
                            }}
                        >
                            {error}
                        </Alert>
                    ) : paginatedMaterias.length > 0 ? (
                        paginatedMaterias.map((materia) => (
                            <Accordion
                                key={materia.id}
                                expanded={expandedAccordion === materia.id}
                                onChange={handleAccordionChange(materia.id)}
                                sx={{
                                    mb: 2,
                                    borderRadius: '16px !important',
                                    '&:before': { display: 'none' },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        borderRadius: '16px !important',
                                        '&.Mui-expanded': {
                                            borderBottomLeftRadius: '0 !important',
                                            borderBottomRightRadius: '0 !important',
                                        },
                                        bgcolor: 'grey.50',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                        <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600 }}>
                                            {materia.nome}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'text.secondary',
                                                mt: 0.5,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            {materia.provas.length}{' '}
                                            {materia.provas.length === 1 ? 'prova disponível' : 'provas disponíveis'}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper} elevation={0}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                                                        Título da Prova
                                                    </TableCell>
                                                    <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                                                        Questões
                                                    </TableCell>
                                                    <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                                                        Tempo
                                                    </TableCell>
                                                    <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                                                        Acesso
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {materia.provas.map((prova) => (
                                                    <TableRow
                                                        key={prova.id}
                                                        hover
                                                        sx={{
                                                            cursor: 'pointer',
                                                            '&:hover': { bgcolor: 'action.hover' },
                                                        }}
                                                        onClick={() => handleProvaClick(prova)}
                                                    >
                                                        <TableCell>{prova.titulo}</TableCell>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                <QuestionAnswerIcon fontSize="small" color="action" />
                                                                {prova.total_questoes}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                <AccessTimeIcon fontSize="small" color="action" />
                                                                {prova.tempo
                                                                    ? `${Math.floor(prova.tempo)} min`
                                                                    : 'Sem tempo definido'}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {prova.access_level === 'premium' ? (
                                                                <Tooltip title="A prova é exclusiva para assinantes premium.">
                                                                    <Chip
                                                                        label="Premium"
                                                                        color="warning"
                                                                        size="small"
                                                                        icon={<LockIcon />}
                                                                        sx={{
                                                                            borderRadius: 1.5,
                                                                            fontWeight: 600,
                                                                            textTransform: 'capitalize',
                                                                            cursor: 'pointer',
                                                                            '& .MuiChip-label': {
                                                                                color: '#FFFFFF',
                                                                            },
                                                                            '& .MuiChip-icon': {
                                                                                color: '#FFFFFF',
                                                                            },
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Prova gratuita.">
                                                                    <Chip
                                                                        label="Free"
                                                                        color="success"
                                                                        size="small"
                                                                        icon={<LockOpenIcon />}
                                                                        sx={{
                                                                            borderRadius: 1.5,
                                                                            fontWeight: 600,
                                                                            textTransform: 'capitalize',
                                                                            cursor: 'pointer',
                                                                            '& .MuiChip-label': {
                                                                                color: 'inherit',
                                                                            },
                                                                            '& .MuiChip-icon': {
                                                                                color: 'inherit',
                                                                            },
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Alert
                            severity="info"
                            sx={{
                                borderRadius: 2,
                                '& .MuiAlert-icon': { fontSize: '2rem' },
                            }}
                        >
                            Nenhuma matéria disponível no momento.
                        </Alert>
                    )}

                    {filteredMaterias.length > itemsPerPage && (
                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                                size={isMobile ? 'small' : 'medium'}
                                showFirstButton
                                showLastButton
                            />
                        </Box>
                    )}
                </Paper>
            </Container>
        </Box>
    );
};

MateriasDisponiveis.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
};

export default MateriasDisponiveis;
