import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
  Alert,
  CircularProgress,
  Typography,
  Box,
  Container,
  Paper,
  useTheme,
  useMediaQuery,
  Fade,
  LinearProgress
} from '@mui/material';
import { CheckCircle, AlertCircle } from 'lucide-react';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { auth, loading, updateAuthToken, refreshUser } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [progress, setProgress] = useState(0);
  const [redirected, setRedirected] = useState(false); // Adicionado para controlar o redirecionamento

  // Simula o progresso do carregamento
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    console.log('🔑 [SuccessPage]: Iniciando processamento da página de sucesso.');

    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');

    const confirmSession = async () => {
      console.log('🔄 [SuccessPage]: Iniciando confirmação da sessão.');
      setConfirming(true);
      try {
        if (!auth.token) {
          throw new Error('Token de autenticação ausente.');
        }

        if (!sessionId) {
          throw new Error('sessionId ausente na URL.');
        }

        const response = await fetch('https://medchoices.com.br:3001/confirm-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`,
          },
          body: JSON.stringify({ sessionId }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Erro ao confirmar a sessão.');
        }

        const data = await response.json();

        if (data.success) {
          setMessage('Pagamento realizado com sucesso! Seu status foi atualizado para Premium.');
          
          if (data.token) {
            updateAuthToken(data.token);
          }
          
          await refreshUser(data.token);
        } else {
          setError(data.message || 'Erro ao confirmar pagamento.');
        }
      } catch (err) {
        setError(`Erro ao confirmar sessão: ${err.message}`);
      } finally {
        setConfirming(false);

        // Garante o redirecionamento apenas uma vez
        if (!redirected) {
          setRedirected(true); // Marca como redirecionado
          setTimeout(() => {
            if (message) {
              console.log('🔄 [SuccessPage]: Redirecionando para /inicio com reload.');
              navigate('/inicio');
            } else if (error) {
              console.log('🔄 [SuccessPage]: Redirecionando para /upgrade.');
              navigate('/upgrade');
            }
          }, 3000);
        }
      }
    };

    if (!loading && !redirected) { // Certifica-se de que a função é chamada apenas uma vez
      if (sessionId) {
        confirmSession();
      } else {
        setError('ID da sessão não encontrado na URL.');
        setTimeout(() => {
          if (!redirected) {
            setRedirected(true);
            navigate('/upgrade');
          }
        }, 3000);
      }
    }
  }, [location.search, auth.token, loading, navigate, message, error, updateAuthToken, refreshUser, redirected]);

  if (loading || confirming || progress < 100) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            gap: 3,
          }}
        >
          <CircularProgress size={isMobile ? 40 : 60} />
          <Typography variant={isMobile ? "h6" : "h5"} textAlign="center">
            Processando seu pagamento...
          </Typography>
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {Math.round(progress)}%
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Fade in timeout={800}>
        <Paper
          elevation={3}
          sx={{
            mt: isMobile ? 4 : 8,
            p: isMobile ? 3 : 4,
            borderRadius: 2,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {message ? (
            <CheckCircle
              size={isMobile ? 48 : 64}
              color={theme.palette.success.main}
              style={{ margin: '0 auto' }}
            />
          ) : error ? (
            <AlertCircle
              size={isMobile ? 48 : 64}
              color={theme.palette.error.main}
              style={{ margin: '0 auto' }}
            />
          ) : null}

          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            component="h1"
            sx={{ fontWeight: 'bold', mb: 2 }}
          >
            {message ? 'Pagamento Bem-Sucedido!' : 'Processando Pagamento'}
          </Typography>

          {message && (
            <Alert 
              severity="success" 
              variant="filled"
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          )}

          {error && (
            <Alert 
              severity="error"
              variant="filled"
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          )}

          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ mt: 1 }}
          >
            {message
              ? 'Você será redirecionado para a página inicial em instantes...'
              : error
              ? 'Você será redirecionado para a página de upgrade em instantes...'
              : 'Aguarde enquanto processamos sua solicitação...'}
          </Typography>
        </Paper>
      </Fade>
    </Container>
  );
};

export default SuccessPage;
