// frontend/src/pages/Admin/Provas/ProvasForm.js

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Snackbar,
  Paper,
  Container,
  Breadcrumbs,
  Link as MuiLink,
  Fade,
  IconButton,
} from '@mui/material';
import { 
  Timer, 
  Book, 
  Save, 
  ArrowLeft, 
  School, 
  Add as AddIcon,
  Delete as DeleteIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom'; // Importações corretas

const ProvasForm = () => {
  const [materias, setMaterias] = useState([]);
  const [materia_id, setMateriaId] = useState('');
  const [provas, setProvas] = useState([
    { titulo: '', tempo: '', access_level: 'free' } // Inicializa com uma prova vazia
  ]);
  const [provasExistentes, setProvasExistentes] = useState([]); // Provas já existentes na matéria selecionada
  const [originalTitulo, setOriginalTitulo] = useState(''); // Armazena o título original da prova em edição
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [authorizationError, setAuthorizationError] = useState(''); // Novo estado para erros de autorização
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const navigate = useNavigate();
  const { id } = useParams();

  // Função para buscar provas existentes da matéria selecionada
  const fetchProvasExistentes = async (materiaId) => {
    if (!materiaId) {
      setProvasExistentes([]);
      return;
    }

    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/provas?materia_id=${materiaId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Você não tem permissão para acessar provas dessa matéria.');
        }
        throw new Error(`Erro ao buscar Provas existentes. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        setProvasExistentes(data.provas);
      } else {
        setProvasExistentes([]);
        setError(data.message || 'Erro ao buscar Provas existentes.');
      }
    } catch (error) {
      console.error('Erro ao buscar Provas existentes:', error);
      if (error.message.includes('permissão')) {
        setAuthorizationError(error.message);
      } else {
        setProvasExistentes([]);
        setError(error.message || 'Erro ao buscar Provas existentes.');
      }
    }
  };

  // Função para buscar matérias
  const fetchMaterias = async () => {
    try {
      const response = await fetch('https://medchoices.com.br:3001/admin/materias', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Você não tem permissão para acessar matérias.');
        }
        throw new Error(`Erro ao buscar Matérias. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        setMaterias(data.materias);
      } else {
        setError(data.message || 'Erro ao buscar Matérias.');
      }
    } catch (error) {
      console.error('Erro ao buscar Matérias:', error);
      if (error.message.includes('permissão')) {
        setAuthorizationError(error.message);
      } else {
        setError(error.message || 'Erro ao buscar Matérias.');
      }
    }
  };

  // Função para buscar uma prova específica
  const fetchProva = async () => {
    if (id) {
      try {
        const response = await fetch(`https://medchoices.com.br:3001/admin/provas/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          if (response.status === 403) {
            throw new Error('Você não tem permissão para acessar esta prova.');
          }
          throw new Error(`Erro ao buscar Prova. Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.success && data.prova) {
          setOriginalTitulo(data.prova.titulo.trim().toLowerCase()); // Armazena o título original
          setMateriaId(data.prova.materia_id.toString());
          setProvas([{ titulo: data.prova.titulo, tempo: data.prova.tempo, access_level: data.prova.access_level }]);
          fetchProvasExistentes(data.prova.materia_id);
        } else {
          throw new Error('Prova não encontrada.');
        }
      } catch (error) {
        console.error('Erro ao buscar Prova:', error);
        if (error.message.includes('permissão')) {
          setAuthorizationError(error.message);
        } else {
          setError(error.message || 'Erro ao buscar Prova.');
        }
      }
    }
  };

  // Função de inicialização
  const initialize = async () => {
    await fetchMaterias();
    await fetchProva();
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Função de submissão do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');
    setAuthorizationError('');

    // Verificar se todos os títulos são únicos no formulário
    const titulos = provas.map((p) => p.titulo.trim().toLowerCase());
    const titulosUnicos = new Set(titulos);
    if (titulos.length !== titulosUnicos.size) {
      setError('Os títulos das provas devem ser únicos no formulário.');
      setSubmitting(false);
      return;
    }

    // Verificar se algum título já existe na matéria selecionada
    let titulosExistentes = provasExistentes.map((p) => p.titulo.toLowerCase());

    if (id) {
      // Excluir o título original da lista de títulos existentes
      titulosExistentes = titulosExistentes.filter(t => t !== originalTitulo);
    }

    const titulosDuplicados = provas.filter((p) => titulosExistentes.includes(p.titulo.trim().toLowerCase()));

    if (titulosDuplicados.length > 0) {
      setError(`Os seguintes títulos já existem na matéria selecionada: ${titulosDuplicados.map(p => p.titulo).join(', ')}`);
      setSubmitting(false);
      return;
    }

    if (provas.some((p) => isNaN(Number(p.tempo)) || Number(p.tempo) <= 0)) {
      setError('Todos os tempos devem ser números positivos.');
      setSubmitting(false);
      return;
    }
    
    // Preparar os dados para submissão
    const data = provas.map((prova) => ({
      materia_id,
      titulo: prova.titulo.trim(),
      tempo: String(prova.tempo).trim(),
      access_level: prova.access_level, // Incluir access_level
    }));

    try {
      const response = await fetch(
        id ? `https://medchoices.com.br:3001/admin/provas/${id}` : 'https://medchoices.com.br:3001/admin/provas/multiple', // Endpoint para criação múltipla ou edição
        {
          method: id ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(id ? data[0] : { provas: data }),
        }
      );

      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Você não tem permissão para realizar esta ação.');
        }
        throw new Error(`Erro ao salvar Provas. Status: ${response.status}`);
      }

      const resData = await response.json();

      if (resData.success) {
        setSnackbar({
          open: true,
          message: id ? 'Prova atualizada com sucesso!' : 'Provas criadas com sucesso!',
          severity: 'success',
        });
        navigate('/admin/provas');
      } else {
        throw new Error(resData.message || 'Erro ao salvar Provas.');
      }
    } catch (error) {
      console.error('Erro ao salvar Provas:', error);
      if (error.message.includes('permissão')) {
        setAuthorizationError(error.message);
      } else {
        setError(error.message || 'Erro ao salvar Provas.');
      }
      setSnackbar({
        open: true,
        message: error.message || 'Erro ao salvar Provas.',
        severity: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Função para lidar com a mudança de matéria
  const handleMateriaChange = (event) => {
    const newMateriaId = event.target.value;
    setMateriaId(newMateriaId);
    fetchProvasExistentes(newMateriaId);
  };

  // Função para adicionar uma nova prova
  const handleAddProva = () => {
    setProvas([...provas, { titulo: '', tempo: '', access_level: 'free' }]);
  };

  // Função para remover uma prova existente
  const handleRemoveProva = (index) => {
    const newProvas = provas.filter((_, i) => i !== index);
    setProvas(newProvas);
  };

  // Função para lidar com mudanças nos campos das provas
  const handleProvaChange = (index, field, value) => {
    const newProvas = provas.map((prova, i) => 
      i === index ? { ...prova, [field]: value } : prova
    );
    setProvas(newProvas);
  };

  // Função para fechar o Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Renderizar o componente de erro de autorização, se existir
  if (authorizationError) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 3 }}>
          {authorizationError}
        </Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/admin/provas')}
          startIcon={<ArrowLeft />}
        >
          Voltar para Lista de Provas
        </Button>
      </Container>
    );
  }

  // Renderizar o loading state
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Fade in timeout={500}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          {/* Breadcrumbs Navigation */}
          <Breadcrumbs sx={{ mb: 3 }}>
            <MuiLink
              component="button"
              color="inherit"
              onClick={() => navigate('/admin')}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <School sx={{ mr: 0.5 }} />
              Admin
            </MuiLink>
            <MuiLink
              component="button"
              color="inherit"
              onClick={() => navigate('/admin/provas')}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Book sx={{ mr: 0.5 }} />
              Provas
            </MuiLink>
            <Typography color="text.primary">
              {id ? 'Editar Prova' : 'Criar Provas'}
            </Typography>
          </Breadcrumbs>

          <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
            {id ? 'Editar Prova' : 'Criar Provas'}
          </Typography>

          {/* Exibir erro geral, se existir */}
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {/* Formulário de Provas */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Seleção da Matéria */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Matéria</InputLabel>
                  <Select
                    value={materia_id}
                    onChange={handleMateriaChange}
                    label="Matéria"
                    disabled={submitting}
                    startAdornment={<Book sx={{ mr: 1 }} />}
                  >
                    {materias.map((materia) => (
                      <MenuItem key={materia.id} value={materia.id.toString()}>
                        {materia.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Provas Dinâmicas */}
              {provas.map((prova, index) => (
                <React.Fragment key={index}>
                  {/* Título da Prova */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={`Título da Prova ${index + 1}`}
                      fullWidth
                      required
                      value={prova.titulo}
                      onChange={(e) => handleProvaChange(index, 'titulo', e.target.value)}
                      disabled={submitting}
                      InputProps={{
                        startAdornment: <Book sx={{ mr: 1 }} />,
                      }}
                      error={
                        // Verifica duplicação no formulário
                        provas.filter((p, i) => 
                          p.titulo.trim().toLowerCase() === prova.titulo.trim().toLowerCase() && i !== index
                        ).length > 0 ||
                        // Verifica duplicação no banco de dados, excluindo o título original se estiver em edição
                        provasExistentes.some((pe) => 
                          pe.titulo.toLowerCase() === prova.titulo.trim().toLowerCase() && 
                          prova.titulo.trim().toLowerCase() !== originalTitulo
                        )
                      }
                      helperText={
                        provas.filter((p, i) => 
                          p.titulo.trim().toLowerCase() === prova.titulo.trim().toLowerCase() && i !== index
                        ).length > 0
                          ? 'Este título já está em uso no formulário. Por favor, escolha outro.'
                          : provasExistentes.some((pe) => 
                              pe.titulo.toLowerCase() === prova.titulo.trim().toLowerCase() && 
                              prova.titulo.trim().toLowerCase() !== originalTitulo
                            )
                              ? 'Este título já existe na matéria selecionada. Por favor, escolha outro.'
                              : ''
                      }
                    />
                  </Grid>

                  {/* Tempo da Prova */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={`Tempo da Prova ${index + 1} (minutos)`}
                      fullWidth
                      required
                      type="number"
                      value={prova.tempo}
                      onChange={(e) => handleProvaChange(index, 'tempo', e.target.value)}
                      disabled={submitting}
                      InputProps={{
                        startAdornment: <Timer sx={{ mr: 1 }} />,
                      }}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>

                  {/* Nível de Acesso da Prova */}
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Nível de Acesso</InputLabel>
                      <Select
                        value={prova.access_level || 'free'}
                        onChange={(e) => handleProvaChange(index, 'access_level', e.target.value)}
                        label="Nível de Acesso"
                        disabled={submitting}
                      >
                        <MenuItem value="free">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LockOpenIcon sx={{ mr: 1 }} />
                            Livre (Free)
                          </Box>
                        </MenuItem>
                        <MenuItem value="premium">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LockIcon sx={{ mr: 1 }} />
                            Premium
                          </Box>
                        </MenuItem>
                        {/* Adicione outros níveis de acesso se necessário */}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Botão para Remover Prova (Somente em Modo de Criação) */}
                  {!id && provas.length > 1 && (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveProva(index)}
                        disabled={submitting}
                        startIcon={<DeleteIcon />}
                        sx={{ mt: 1 }}
                      >
                        Remover Prova
                      </Button>
                    </Grid>
                  )}
                </React.Fragment>
              ))}

              {/* Botão para Adicionar Nova Prova (Somente em Modo de Criação) */}
              {!id && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    onClick={handleAddProva}
                    disabled={submitting}
                    startIcon={<AddIcon />}
                    sx={{ mt: 2 }}
                  >
                    Adicionar Nova Prova
                  </Button>
                </Grid>
              )}
            </Grid>

            {/* Botões de Ação */}
            <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate('/admin/provas')}
                disabled={submitting}
                startIcon={<ArrowLeft />}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || provas.length === 0}
                startIcon={submitting ? <CircularProgress size={20} /> : <Save />}
                sx={{ minWidth: 150 }}
              >
                {submitting ? 'Salvando...' : id ? 'Atualizar' : 'Criar Provas'}
              </Button>
            </Box>
          </form>

          {/* Feedback Snackbar */}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Paper>
      </Fade>
    </Container>
  );
};

export default ProvasForm;
