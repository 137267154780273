// frontend/src/components/PublicRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../context/AuthContext';
import { CircularProgress, Typography, Box } from '@mui/material';

const PublicRoute = ({ children }) => {
  const { auth, loading } = useAuth();

  console.log('🔑 [PublicRoute]: Iniciando verificação de acesso público.');

  if (loading) {
    console.log('🔄 [PublicRoute]: Estado de carregamento ativo.');
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
        }}
      >
        {/* <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Carregando...
        </Typography> */}
      </Box>
    );
  }

  if (auth.isAuthenticated) {
    console.warn('⚠️ [PublicRoute]: Usuário já autenticado. Redirecionando para /inicio.');
    return <Navigate to="/inicio" replace />;
  }

  console.log('✅ [PublicRoute]: Acesso autorizado a rota pública.');
  return children;
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
