// frontend/src/pages/Choices.js

import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useAuth } from '../context/AuthContext';

import {
  Box,
  Button,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Container,
  useTheme,
  Fade,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  Modal,
  Alert,
  IconButton,
  Stack,
  Snackbar,
  Divider,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import {
  Timer as TimerIcon,
  Equalizer as EqualizerIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  ArrowBack as ArrowBackIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Crown as CrownIcon, Unlock as UnlockIcon } from 'lucide-react';
import { alpha } from '@mui/material/styles';
import { debounce } from 'lodash';
import ReportarErro from './ReportarErro';

ChartJS.register(ArcElement, ChartTooltip, Legend);

// Definição das URLs base da API
const API_BASE_URL = 'https://medchoices.com.br:3001';
const ADMIN_BASE_URL = `${API_BASE_URL}/admin`;

// Função para embaralhar arrays
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const Choices = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { auth } = useContext(AuthContext);
  const [status, setStatus] = useState('free');
  const [searchParams] = useSearchParams();
  const initialProvaId = searchParams.get('prova_id');
  const [materias, setMaterias] = useState([]);
  const [selectedMateria, setSelectedMateria] = useState(null);
  const [provas, setProvas] = useState([]);
  const [selectedProva, setSelectedProva] = useState(null);
  const [questoes, setQuestoes] = useState([]);
  const [loadingMaterias, setLoadingMaterias] = useState(false);
  const [loadingProvas, setLoadingProvas] = useState(false);
  const [answers, setAnswers] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [progress, setProgress] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [userHistorico, setUserHistorico] = useState([]);
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const [provaIniciadaId, setProvaIniciadaId] = useState(null);
  const [loading, setLoading] = useState(true);

  // Estados para Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Função para receber feedback do ReportarErro
  const handleReportarErroFeedback = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Obtém o status do usuário no AuthContext
  useEffect(() => {
    if (auth?.user?.status) {
      setStatus(auth.user.status.toLowerCase());
      setLoading(false);
    } else {
      console.log('Dados do AuthContext ainda não carregados.');
    }
  }, [auth]);

  useEffect(() => {
    console.log('Status do usuário (AuthContext):', status);
  }, [status]);

  const steps = ['Selecionar Matéria', 'Selecionar Prova', 'Responder Questões'];

  // Função para buscar matérias com debounce para otimizar as buscas
  const fetchMateriasDebounced = useMemo(
    () =>
      debounce(async (inputValue) => {
        setLoadingMaterias(true);
        try {
          const response = await fetch(
            `${ADMIN_BASE_URL}/materias?search=${encodeURIComponent(inputValue)}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );

          // Verificar se a resposta é bem-sucedida
          if (!response.ok) {
            const errorText = await response.text();
            console.error('Erro ao buscar matérias:', errorText);
            setMaterias([]);
            setSnackbarMessage('Erro ao buscar matérias.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
          }

          // Verificar o tipo de conteúdo
          const contentType = response.headers.get('content-type');
          if (!contentType || !contentType.includes('application/json')) {
            console.error('Resposta inválida do servidor:', await response.text());
            setMaterias([]);
            setSnackbarMessage('Resposta inválida do servidor.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
          }

          const data = await response.json();
          if (data.success) {
            const materiasComStatus = await Promise.all(
              data.materias.map(async (materia) => {
                try {
                  const provasResponse = await fetch(
                    `${ADMIN_BASE_URL}/provas/com-questoes?materia_id=${materia.id}`,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                      },
                    }
                  );

                  if (!provasResponse.ok) {
                    console.error(`Erro ao buscar provas para a matéria ${materia.nome}:`, await provasResponse.text());
                    return { ...materia, disabled: true };
                  }

                  const provasData = await provasResponse.json();

                  if (provasData.success && provasData.provas && provasData.provas.length > 0) {
                    return { ...materia, disabled: false };
                  } else {
                    return { ...materia, disabled: true };
                  }
                } catch (error) {
                  console.error(`Erro ao buscar provas para a matéria ${materia.nome}:`, error);
                  return { ...materia, disabled: true };
                }
              })
            );

            setMaterias(materiasComStatus);
          } else {
            console.error(data.message || 'Erro ao buscar matérias.');
            setMaterias([]);
            setSnackbarMessage(data.message || 'Erro ao buscar matérias.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        } catch (error) {
          console.error('Erro ao buscar matérias:', error);
          setMaterias([]);
          setSnackbarMessage('Erro ao buscar matérias.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } finally {
          setLoadingMaterias(false);
        }
      }, 300),
    []
  );

  const handleMateriasInputChange = (event, value) => {
    fetchMateriasDebounced(value);
  };

  // Função para buscar provas existentes da matéria selecionada
  const fetchProvasExistentes = async (materiaId) => {
    if (!materiaId) {
      setProvas([]);
      return;
    }

    setLoadingProvas(true);
    try {
      const response = await fetch(
        `${ADMIN_BASE_URL}/provas/com-questoes?materia_id=${materiaId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      // Verificar se a resposta é bem-sucedida
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erro ao buscar provas existentes:', errorText);
        setProvas([]);
        setSnackbarMessage('Erro ao buscar provas existentes.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      // Verificar o tipo de conteúdo
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        console.error('Resposta inválida do servidor:', await response.text());
        setProvas([]);
        setSnackbarMessage('Resposta inválida do servidor.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const data = await response.json();

      if (data.success) {
        const pontuacaoMap = getMaxPontuacaoPerProva();
        const provasProcessadas = data.provas.map((prova) => {
          console.log('Prova:', prova); // Log para depuração
          return {
            ...prova,
            jaRealizada: pontuacaoMap[prova.id] ? true : false,
            maiorPontuacao: pontuacaoMap[prova.id] || null,
          };
        });

        setProvas(provasProcessadas);
      } else {
        console.error(data.message || 'Erro ao buscar provas existentes.');
        setProvas([]);
        setSnackbarMessage(data.message || 'Erro ao buscar provas existentes.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Erro ao buscar provas existentes:', error);
      setProvas([]);
      setSnackbarMessage('Erro ao buscar provas existentes.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoadingProvas(false);
    }
  };

  // Função para buscar histórico do usuário
  const fetchUserHistorico = async () => {
    try {
      const response = await fetch(`${ADMIN_BASE_URL}/me/historico`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erro ao buscar histórico do usuário:', errorText);
        setSnackbarMessage('Erro ao buscar histórico do usuário.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      // Verificar o tipo de conteúdo
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        console.error('Resposta inválida do servidor:', await response.text());
        setSnackbarMessage('Resposta inválida do servidor.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const data = await response.json();
      if (data.success) {
        setUserHistorico(data.historico);
      } else {
        console.error(data.message || 'Erro ao buscar histórico do usuário.');
        setSnackbarMessage(data.message || 'Erro ao buscar histórico do usuário.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Erro ao buscar histórico do usuário:', error);
      setSnackbarMessage('Erro ao buscar histórico do usuário.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchUserHistorico();
  }, []);

  // Função para obter a maior pontuação por prova
  const getMaxPontuacaoPerProva = () => {
    const pontuacaoMap = {};
    userHistorico.forEach((item) => {
      const provaId = item.prova_id;
      const pontuacao = parseFloat(item.pontuacao);
      if (!pontuacaoMap[provaId] || pontuacao > pontuacaoMap[provaId]) {
        pontuacaoMap[provaId] = pontuacao;
      }
    });
    return pontuacaoMap;
  };

  // Função para buscar prova inicial se houver 'prova_id' na URL
  useEffect(() => {
    const fetchProva = async () => {
      if (initialProvaId) {
        try {
          const response = await fetch(
            `${ADMIN_BASE_URL}/provas/${initialProvaId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );

          // Verificar se a resposta é bem-sucedida
          if (!response.ok) {
            const errorText = await response.text();
            console.error('Erro ao buscar prova:', errorText);
            setSnackbarMessage('Erro ao buscar prova.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
          }

          // Verificar o tipo de conteúdo
          const contentType = response.headers.get('content-type');
          if (!contentType || !contentType.includes('application/json')) {
            console.error('Resposta inválida do servidor:', await response.text());
            setSnackbarMessage('Resposta inválida do servidor.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
          }

          const data = await response.json();

          if (data.success && data.prova) {
            setSelectedMateria({ id: data.prova.materia_id, nome: data.prova.materia_nome });
            setSelectedProva(data.prova.id);
            setActiveStep(2);
            fetchProvasExistentes(data.prova.materia_id);
          } else {
            console.error('Prova não encontrada.');
            setSnackbarMessage('Prova não encontrada.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        } catch (error) {
          console.error('Erro ao buscar Prova:', error);
          setSnackbarMessage('Erro ao buscar prova.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }
    };

    fetchProva();
  }, [initialProvaId]);

  // Função para iniciar a prova
  const iniciarProva = async (provaId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/iniciar-prova`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ prova_id: provaId }),
      });

      // Verificar se a resposta é bem-sucedida
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erro ao iniciar prova:', errorText);
        setSnackbarMessage('Erro ao iniciar prova.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const data = await response.json();
      if (data.success) {
        setProvaIniciadaId(data.prova_iniciada_id);
        console.log('Prova iniciada com ID:', data.prova_iniciada_id);
      } else {
        console.error('Erro ao iniciar prova:', data.message);
        setSnackbarMessage(data.message || 'Erro ao iniciar prova.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Erro ao iniciar prova:', error);
      setSnackbarMessage('Erro ao iniciar prova.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Função para finalizar a prova
  const finalizarProva = async () => {
    if (!provaIniciadaId) {
      console.warn('Nenhuma prova iniciada para finalizar.');
      setSnackbarMessage('Nenhuma prova iniciada para finalizar.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    console.log(`Finalizando prova iniciada com ID: ${provaIniciadaId}`);

    try {
      const response = await fetch(`${ADMIN_BASE_URL}/finalizar-prova`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ prova_iniciada_id: provaIniciadaId }),
      });

      // Verificar se a resposta é bem-sucedida
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erro ao finalizar prova:', errorText);
        setSnackbarMessage('Erro ao finalizar prova.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const data = await response.json();
      console.log('Resposta da finalização da prova:', data);

      if (data.success) {
        console.log('Prova finalizada com sucesso:', data.message);
      } else {
        console.error('Erro ao finalizar prova:', data.message);
        setSnackbarMessage(data.message || 'Erro ao finalizar prova.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Erro ao finalizar prova:', error);
      setSnackbarMessage('Erro ao finalizar prova.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Função para processar questões e embaralhar alternativas
  const processarQuestoes = (questoesOriginais) => {
    const questoesComAlternativas = questoesOriginais.map((questao) => {
      const alternativas = ['a', 'b', 'c', 'd'].map((letra) => ({
        letra,
        texto: questao[`opcao_${letra}`],
        correta: questao.resposta_correta === letra.toLowerCase(),
      }));

      const alternativasEmbaralhadas = shuffleArray(alternativas);

      return {
        ...questao,
        alternativas: alternativasEmbaralhadas,
      };
    });

    return shuffleArray(questoesComAlternativas);
  };

  // Função para buscar questões da prova selecionada
  useEffect(() => {
    const fetchQuestoes = async () => {
      if (!selectedProva) return;
      setLoadingProvas(true);
      try {
        const responseProva = await fetch(
          `${ADMIN_BASE_URL}/provas/${selectedProva}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        // Verificar se a resposta é bem-sucedida
        if (!responseProva.ok) {
          const errorText = await responseProva.text();
          console.error('Erro ao buscar prova:', errorText);
          setSnackbarMessage('Erro ao buscar prova.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }

        const dataProva = await responseProva.json();

        if (dataProva.success) {
          await iniciarProva(selectedProva);

          setStartTime(Date.now());
          if (dataProva.prova.tempo) {
            const tempoEmSegundos = dataProva.prova.tempo * 60;
            setTotalTime(tempoEmSegundos);
            setTimeRemaining(tempoEmSegundos);
          }

          const questoesProcessadas = processarQuestoes(dataProva.prova.questoes || []);
          setQuestoes(questoesProcessadas);
        } else {
          console.error('Erro ao buscar prova:', dataProva.message);
          setSnackbarMessage('Erro ao buscar prova.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error('Erro ao buscar questões:', error);
        setSnackbarMessage('Erro ao buscar questões.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setQuestoes([]);
      } finally {
        setLoadingProvas(false);
      }
    };
    fetchQuestoes();
  }, [selectedProva]);

  // Função para iniciar o temporizador da prova
  useEffect(() => {
    if (!startTime || totalTime <= 0) return;

    timerRef.current = setInterval(() => {
      const elapsed = Math.floor((Date.now() - startTime) / 1000);
      const remaining = Math.max(0, totalTime - elapsed);
      setTimeRemaining(remaining);

      if (remaining === 0) {
        clearInterval(timerRef.current);
        handleSubmit();
      }
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [startTime, totalTime]);

  // Atualizar a barra de progresso conforme as respostas são respondidas
  useEffect(() => {
    if (questoes.length > 0) {
      const answeredCount = Object.keys(answers).length;
      setProgress((answeredCount / questoes.length) * 100);
    }
  }, [answers, questoes.length]);

  // Função para lidar com a mudança de resposta do usuário
  const handleAnswerChange = (questaoId, resposta) => {
    setAnswers({
      ...answers,
      [questaoId]: resposta,
    });
  };

  // Função para formatar o tempo restante
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Função para lidar com o envio das respostas
  const handleSubmit = async () => {
    if (showResults) return;

    setSubmitting(true);
    setTimeRemaining(0);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const correctAnswers = questoes.filter((questao) => {
      const respostaUsuario = answers[questao.id];
      const alternativaCorreta = questao.alternativas.find((alt) => alt.correta);
      return respostaUsuario === alternativaCorreta.letra;
    });

    const resultData = {
      total: questoes.length,
      correct: correctAnswers.length,
      incorrect: questoes.length - correctAnswers.length,
      percentage: ((correctAnswers.length / questoes.length) * 100).toFixed(2),
      timeTaken: Math.floor((Date.now() - startTime) / 1000),
    };

    setResults(resultData);
    setShowResults(true);
    setSubmitting(false);

    await finalizarProva();

    const historicoData = {
      prova_id: selectedProva,
      acertos: correctAnswers.length,
      erros: questoes.length - correctAnswers.length,
      tempo_utilizado: Math.floor((Date.now() - startTime) / 1000),
      pontuacao: ((correctAnswers.length / questoes.length) * 100).toFixed(2),
      total_questoes: questoes.length,
      respostas: questoes.map((questao) => {
        const respostaUsuario = answers[questao.id];
        return {
          questao_id: questao.id,
          alternativa_escolhida: respostaUsuario || null,
          correta: questao.alternativas.find((alt) => alt.letra.toLowerCase() === (respostaUsuario || '').toLowerCase())?.correta || false,
        };
      }),
    };

    try {
      const response = await fetch(`${ADMIN_BASE_URL}/me/historico`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(historicoData),
      });

      // Verificar se a resposta é bem-sucedida
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erro ao salvar histórico:', errorText);
        setSnackbarMessage('Erro ao salvar histórico.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const data = await response.json();
      if (!data.success) {
        console.error('Erro ao salvar histórico:', data.message);
        setSnackbarMessage(data.message || 'Erro ao salvar histórico.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        console.log('Histórico salvo com sucesso:', data);
        setSnackbarMessage('Histórico salvo com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Erro ao salvar histórico:', error);
      setSnackbarMessage('Erro ao salvar histórico.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Definição dos dados para o gráfico de resultados
  const chartData = {
    labels: ['Acertos', 'Erros'],
    datasets: [
      {
        data: [results?.correct || 0, results?.incorrect || 0],
        backgroundColor: [theme.palette.success.main, theme.palette.error.main],
        borderColor: [theme.palette.success.light, theme.palette.error.light],
        borderWidth: 1,
      },
    ],
  };

  // Função para redirecionar o usuário para a página inicial
  const handleExit = () => {
    navigate('/inicio');
  };

  // Função para verificar o acesso à prova com base no nível do usuário
  const verificarAcessoProva = (prova) => {
    const accessLevel = prova.access_level ? prova.access_level.toLowerCase() : 'unknown';
    const allowedStatusesForPremium = ['premium', 'premium_cancelado'];

    if (accessLevel === 'premium' && !allowedStatusesForPremium.includes(status)) {
      navigate('/upgrade');
      return false;
    }

    return true; // Permite o acesso para todos os casos, inclusive free.
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Substituído o Paper por Box */}
      <Box
        sx={{
          p: 1,
          borderRadius: 3,
          bgcolor: alpha(theme.palette.background.paper, 0.98),
          backdropFilter: 'blur(8px)',
          boxShadow: 'none', // Removendo a sombra típica do Paper
        }}
      >
        {!selectedProva ? (
          <>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                mb: 4,
                textAlign: 'center',
                fontWeight: 'bold',
                color: theme.palette.primary.main,
              }}
            >
              Seleção de Prova
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                mb: 6,
                '& .MuiStepLabel-root .Mui-completed': {
                  color: theme.palette.success.main,
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ maxWidth: 1200, mx: 'auto' }}>
              {/* Autocomplete para Seleção da Matéria */}
              <Autocomplete
                options={materias}
                getOptionLabel={(option) => option.nome}
                value={selectedMateria}
                onChange={(event, newValue) => {
                  setSelectedMateria(newValue);
                  setSelectedProva(null);
                  setActiveStep(newValue ? 1 : 0);
                  if (newValue) {
                    fetchProvasExistentes(newValue.id);
                  }
                }}
                onInputChange={handleMateriasInputChange}
                loading={loadingMaterias}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      opacity: option.disabled ? 0.5 : 1,
                      pointerEvents: option.disabled ? 'none' : 'auto',
                      display: 'flex',
                      flexDirection: 'column', // Alinhamento vertical
                      alignItems: 'flex-start', // Alinhamento à esquerda
                      padding: '8px 16px',
                    }}
                  >
                    <Typography variant="body1">{option.nome}</Typography>
                    {option.disabled && (
                      <Chip label="Sem Provas" size="small" color="warning" sx={{ mt: 1 }} />
                    )}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Matéria"
                    variant="outlined"
                    fullWidth
                    required
                    helperText="Digite para buscar e selecionar a matéria desejada."
                    placeholder="Ex: Anatomia, Fisiologia..."
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingMaterias ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                noOptionsText="Digite para buscar a matéria desejada."
                sx={{ mb: 4 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />

              {/* Autocomplete para Seleção da Prova */}
              <Autocomplete
                options={provas}
                getOptionLabel={(option) => option.titulo}
                value={selectedProva ? provas.find((prova) => prova.id === selectedProva) : null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    // Verificar o nível de acesso antes de selecionar a prova
                    const podeAcessar = verificarAcessoProva(newValue);
                    if (podeAcessar) {
                      setSelectedProva(newValue.id);
                      setActiveStep(2);
                    } else {
                      setSelectedProva(null);
                      setActiveStep(1);
                    }
                  } else {
                    setSelectedProva(null);
                    setActiveStep(1);
                  }
                }}
                loading={loadingProvas}
                disabled={!selectedMateria}
                renderOption={(props, option) => {
                  const { key, ...otherProps } = props;
                  const accessLevel = option.access_level ? option.access_level.toLowerCase() : 'unknown';
                  const isLowAproveitamento = option.maiorPontuacao < 40;

                  return (
                    <li
                      key={key}
                      {...otherProps}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          width: '100%',
                        }}
                      >
                        {/* Linha contendo o Título da Prova e o Chip de Acesso */}
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1, // Espaçamento entre o título e o Chip
                            width: '100%',
                          }}
                        >
                          <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            {option.titulo}
                          </Typography>
                          <Chip
                            icon={
                              accessLevel === 'premium' ? (
                                <CrownIcon size={14} />
                              ) : accessLevel === 'free' ? (
                                <UnlockIcon size={14} />
                              ) : null
                            }
                            label={
                              accessLevel === 'free'
                                ? 'Free'
                                : accessLevel === 'premium'
                                  ? 'Premium'
                                  : 'Unknown'
                            }
                            size="small"
                            sx={{
                              height: '20px',
                              borderRadius: '10px',
                              backgroundColor:
                                accessLevel === 'premium'
                                  ? alpha(theme.palette.warning.main, 0.1)
                                  : accessLevel === 'free'
                                    ? alpha(theme.palette.info.main, 0.1)
                                    : alpha(theme.palette.grey[500], 0.1),
                              color:
                                accessLevel === 'premium'
                                  ? theme.palette.warning.main
                                  : accessLevel === 'free'
                                    ? theme.palette.primary.main
                                    : theme.palette.text.primary,
                              '& .MuiChip-icon': {
                                color: 'inherit',
                              },
                              fontSize: '0.7rem',
                              fontWeight: 600,
                            }}
                          />
                        </Box>

                        {/* Condicional: Chip de Último Rendimento e Texto "Prova já realizada" */}
                        {option.jaRealizada && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 0.5,
                              mt: 1,
                            }}
                          >
                            <Chip
                              label={`Rendimento: ${option.maiorPontuacao}%`}
                              color={isLowAproveitamento ? 'error' : 'success'}
                              size="small"
                              sx={{
                                borderRadius: 1.5,
                                backgroundColor: isLowAproveitamento
                                  ? alpha(theme.palette.error.main, 0.1)
                                  : alpha(theme.palette.success.main, 0.1),
                                fontWeight: 600,
                                fontSize: '0.85rem',
                                textTransform: 'capitalize',
                                '& .MuiChip-label': {
                                  color: isLowAproveitamento
                                    ? theme.palette.error.main
                                    : theme.palette.success.main,
                                },
                                '& .MuiChip-icon': {
                                  color: 'inherit',
                                },
                              }}
                            />
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              sx={{
                                mt: 0.3,
                              }}
                            >
                              Prova já realizada
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Prova"
                    variant="outlined"
                    fullWidth
                    required
                    helperText={
                      selectedMateria
                        ? provas.length > 0
                          ? 'Selecione a prova referente à matéria escolhida.'
                          : 'Nenhuma prova disponível para a matéria selecionada.'
                        : 'Selecione uma matéria primeiro.'
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProvas ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                noOptionsText={
                  selectedMateria
                    ? provas.length > 0
                      ? 'Nenhuma prova corresponde à busca.'
                      : 'Nenhuma prova disponível para a matéria selecionada.'
                    : 'Selecione uma matéria primeiro.'
                }
                sx={{ mb: 4 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Box>
          </>
        ) : loadingProvas ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress size={30} />
          </Box>
        ) : (
          <Box>
            {questoes.map((questao, index) => (
              <Box
                key={questao.id}
                sx={{
                  mb: 4,
                  p: 0,
                  borderBottom: '1px solid',
                  borderColor: 'lightgrey',
                  bgcolor: showResults
                    ? questao.alternativas.find((alt) => alt.letra.toLowerCase() === (answers[questao.id] || '').toLowerCase())?.correta
                      ? alpha(theme.palette.success.main, 0.05)
                      : alpha(theme.palette.error.main, 0.05)
                    : theme.palette.background.paper,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={((index + 1) / questoes.length) * 100}
                    sx={{
                      width: '65%',
                      height: 8,
                      borderRadius: 4,
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 4,
                      },
                    }}
                  />
                  <Chip
                    icon={<TimerIcon />}
                    label={formatTime(timeRemaining)}
                    color={timeRemaining < 60 ? 'error' : 'primary'}
                    variant="outlined"
                    sx={{
                      fontWeight: 600,
                      fontSize: '0.9rem',
                    }}
                  />
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.text.primary,
                    mb: 2,
                    fontSize: '1rem',
                  }}
                >
                  Questão {index + 1} de {questoes.length}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    color: theme.palette.text.secondary,
                  }}
                >
                  {questao.enunciado}
                </Typography>

                {questao.imagem && (
                  <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <img
                      src={
                        questao.imagem.startsWith('http')
                          ? questao.imagem
                          : questao.imagem.startsWith('uploads/')
                            ? `${ADMIN_BASE_URL.replace('/admin', '')}/${questao.imagem}`
                            : `${ADMIN_BASE_URL.replace('/admin', '')}/uploads/${questao.imagem.replace(/^\/+/, '')}`
                      }
                      alt={`Imagem da questão ${index + 1}`}
                      style={{
                        maxWidth: '100%',
                        height: questao.image_height ? `${questao.image_height}px` : '500px',
                        borderRadius: 12,
                        boxShadow: theme.shadows[2],
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                )}

                <RadioGroup
                  value={answers[questao.id] || ''}
                  onChange={(e) => handleAnswerChange(questao.id, e.target.value)}
                  disabled={submitting || showResults}
                >
                  {questao.alternativas.map((option) => (
                    <Box
                      key={option.letra}
                      onClick={() => handleAnswerChange(questao.id, option.letra)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 1,
                        mb: 1,
                        borderRadius: 2,
                        cursor: 'pointer',
                        bgcolor: showResults
                          ? option.correta
                            ? alpha(theme.palette.success.main, 0.1)
                            : answers[questao.id]?.toLowerCase() === option.letra.toLowerCase()
                              ? alpha(theme.palette.error.main, 0.1)
                              : alpha(theme.palette.grey[100], 0.5)
                          : alpha(theme.palette.grey[100], 0.5),
                        border: `1px solid ${showResults
                          ? option.correta
                            ? alpha(theme.palette.success.main, 0.3)
                            : answers[questao.id]?.toLowerCase() === option.letra.toLowerCase()
                              ? alpha(theme.palette.error.main, 0.3)
                              : 'transparent'
                          : 'transparent'
                          }`,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          boxShadow: theme.shadows[3],
                          bgcolor: alpha(theme.palette.primary.light, 0.2),
                        },
                      }}
                    >
                      <FormControlLabel
                        value={option.letra}
                        control={<Radio color="primary" />}
                        label={
                          <Typography
                            sx={{
                              color: showResults
                                ? option.correta
                                  ? theme.palette.success.main
                                  : answers[questao.id]?.toLowerCase() === option.letra.toLowerCase()
                                    ? theme.palette.error.main
                                    : theme.palette.text.primary
                                : theme.palette.text.primary,
                              fontWeight:
                                showResults && option.correta
                                  ? 600
                                  : 400,
                            }}
                          >
                            {option.texto}
                          </Typography>
                        }
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  ))}
                </RadioGroup>

                {/* Componente de reportar erro, passando a função de feedback */}
                <ReportarErro questaoId={questao.id} onFeedback={handleReportarErroFeedback} />
              </Box>
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={handleExit}
                sx={{
                  borderRadius: 2,
                  px: 4,
                }}
              >
                Sair
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={showResults || timeRemaining <= 0 || submitting}
                endIcon={<CheckCircleIcon />}
                sx={{
                  borderRadius: 2,
                  px: 4,
                }}
              >
                Finalizar
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {/* Diálogo para Resultados */}
      <Dialog
        open={showResults}
        onClose={() => { }}
        fullWidth
        maxWidth="xl"
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            p: 3,
            boxShadow: 24,
            position: 'relative',
            background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 100%)`,
          },
        }}
      >
        <Fade in={showResults}>
          <Box>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              sx={{ fontWeight: 700, mb: 3 }}
            >
              Resultados da Prova
            </Typography>

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: alpha(theme.palette.info.light, 0.1),
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                >
                  <TimerIcon color="info" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Tempo
                    </Typography>
                    <Typography variant="h6">
                      {Math.floor(results?.timeTaken / 60)}min {results?.timeTaken % 60}s
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: alpha(theme.palette.success.light, 0.1),
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                >
                  <EqualizerIcon color="success" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Aproveitamento
                    </Typography>
                    <Typography variant="h6">{results?.percentage}%</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Typography align="center" sx={{ mb: 3 }}>
              Para visualizar mais detalhes sobre sua prova, acesse o menu{' '}
              <Typography
                component="span"
                sx={{ color: 'primary.main', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => navigate('/historico')} // Alterado para usar navigate em vez de window.location.href
              >
                Histórico
              </Typography>.
            </Typography>

            {/* Gráfico de Resultados */}
            <Box
              sx={{
                height: { xs: 200, sm: 250 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <Pie
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'bottom',
                      labels: {
                        font: {
                          size: 14,
                          weight: '500',
                        },
                        padding: 20,
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const label = context.label || '';
                          const value = context.parsed;
                          const total = context.chart._metasets[context.datasetIndex].total;
                          const percentage = ((value / total) * 100).toFixed(2);
                          return `${label}: ${percentage}%`;
                        },
                      },
                    },
                  },
                }}
              />
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center">
              <Button
                variant="outlined"
                onClick={() => navigate('/home')}
                startIcon={<ArrowBackIcon />}
                sx={{
                  borderRadius: 2,
                  px: 3,
                  width: { xs: '100%', sm: 'auto' },
                  textTransform: 'none',
                }}
              >
                Sair
              </Button>
              <Button
                variant="contained"
                onClick={() => setShowReview(true)}
                endIcon={<VisibilityIcon />}
                sx={{
                  borderRadius: 2,
                  px: 3,
                  width: { xs: '100%', sm: 'auto' },
                  textTransform: 'none',
                }}
              >
                Revisar Questões
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Dialog>

      {/* Modal para Revisão das Questões */}
      <Modal
        open={showReview}
        onClose={() => setShowReview(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={showReview}>
          <Box
            sx={{
              width: { xs: '95%', sm: '80%', md: '70%' },
              maxHeight: '90vh',
              bgcolor: 'background.paper',
              borderRadius: 3,
              boxShadow: 24,
              p: 4,
              position: 'relative',
              overflow: 'auto',
            }}
          >
            <IconButton
              onClick={() => setShowReview(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <Stack spacing={4}>
              <Typography
                variant="h5"
                align="center"
                color="primary"
                sx={{ fontWeight: 600, mb: 2 }}
              >
                Revisão das Questões
              </Typography>

              {questoes.map((questao, index) => {
                const alternativaCorreta = questao.alternativas.find((alt) => alt.correta);
                const respostaUsuario = answers[questao.id];

                return (
                  <Box
                    key={questao.id}
                    sx={{
                      mb: 4,
                      p: 2,
                      borderRadius: 2,
                      bgcolor: respostaUsuario === alternativaCorreta?.letra
                        ? alpha(theme.palette.success.main, 0.05)
                        : respostaUsuario
                          ? alpha(theme.palette.error.main, 0.05)
                          : alpha(theme.palette.warning.main, 0.05),
                      border: `1px solid ${respostaUsuario === alternativaCorreta?.letra
                        ? alpha(theme.palette.success.main, 0.2)
                        : respostaUsuario
                          ? alpha(theme.palette.error.main, 0.2)
                          : 'transparent'
                        }`,
                    }}
                  >
                    <Stack spacing={3}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" fontWeight={'700'}>
                          Questão {index + 1}
                        </Typography>

                        {/* Adicionar o Chip para destacar questões não respondidas */}
                        {respostaUsuario === undefined && (
                          <Chip
                            label="Não Respondida"
                            color="warning"
                            icon={<ErrorIcon />}
                            sx={{ fontWeight: 600 }}
                          />
                        )}

                        {respostaUsuario !== undefined && (
                          <Chip
                            icon={
                              respostaUsuario.toLowerCase() === alternativaCorreta?.letra.toLowerCase() ? (
                                <CheckCircleIcon />
                              ) : (
                                <ErrorIcon />
                              )
                            }
                            label={
                              respostaUsuario.toLowerCase() === alternativaCorreta?.letra.toLowerCase() ? 'Correta' : 'Incorreta'
                            }
                            color={respostaUsuario.toLowerCase() === alternativaCorreta?.letra.toLowerCase() ? 'success' : 'error'}
                            sx={{ fontWeight: 600 }}
                          />
                        )}
                      </Box>

                      <Typography sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
                        {questao.enunciado}
                      </Typography>

                      {questao.imagem && (
                        <Box sx={{ textAlign: 'center' }}>
                          <img
                            src={
                              questao.imagem.startsWith('http')
                                ? questao.imagem
                                : questao.imagem.startsWith('uploads/')
                                  ? `${ADMIN_BASE_URL.replace('/admin', '')}/${questao.imagem}`
                                  : `${ADMIN_BASE_URL.replace('/admin', '')}/uploads/${questao.imagem.replace(/^\/+/, '')}`
                            }
                            alt={`Imagem da questão ${index + 1}`}
                            style={{
                              maxWidth: '100%',
                              height: questao.image_height ? `${questao.image_height}px` : '500px', // Usando image_height do backend
                              objectFit: 'contain',
                              borderRadius: 12,
                              boxShadow: theme.shadows[2],
                            }}
                          />
                        </Box>
                      )}

                      <Stack spacing={2}>
                        {questao.alternativas.map((option) => {
                          const isCorrect = option.correta;
                          const isUserAnswer = respostaUsuario?.toLowerCase() === option.letra.toLowerCase();

                          return (
                            <Box
                              key={option.letra}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: 2,
                                borderRadius: 2,
                                bgcolor: isCorrect
                                  ? alpha(theme.palette.success.main, 0.1)
                                  : isUserAnswer
                                    ? alpha(theme.palette.error.main, 0.1)
                                    : alpha(theme.palette.grey[100], 0.5),
                                border: `1px solid ${isCorrect
                                  ? alpha(theme.palette.success.main, 0.3)
                                  : isUserAnswer
                                    ? alpha(theme.palette.error.main, 0.3)
                                    : 'transparent'
                                  }`,
                              }}
                            >
                              <Stack direction="row" spacing={2} alignItems="center">
                                {isCorrect && <CheckCircleIcon color="success" />}
                                {isUserAnswer && !isCorrect && <ErrorIcon color="error" />}
                                <Typography
                                  sx={{
                                    color: isCorrect
                                      ? theme.palette.success.main
                                      : isUserAnswer
                                        ? theme.palette.error.main
                                        : theme.palette.text.primary,
                                    fontWeight: isCorrect || isUserAnswer ? 600 : 400,
                                  }}
                                >
                                  {option.texto}
                                </Typography>

                                {isUserAnswer && !isCorrect && (
                                  <Typography
                                    sx={{
                                      color: theme.palette.error.main,
                                      fontStyle: 'italic',
                                      fontWeight: 400,
                                      ml: 2,
                                    }}
                                  >
                                    (Sua escolha)
                                  </Typography>
                                )}
                              </Stack>
                            </Box>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Box>
                );
              })}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => setShowReview(false)}
                  endIcon={<ArrowBackIcon />}
                  sx={{ borderRadius: 2, px: 3 }}
                >
                  Voltar
                </Button>
              </Box>
            </Stack>
          </Box>
        </Fade>
      </Modal>

      {/* Snackbar para Feedback ao Usuário */}
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
      >
        <Alert
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Choices;
