// frontend/src/components/NetworkStatus.js

import React, { useState, useEffect, useRef } from 'react';
import { Snackbar, Alert } from '@mui/material';

const NetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showOffline, setShowOffline] = useState(false);
    const [showUnstable, setShowUnstable] = useState(false);
    const [unstableCount, setUnstableCount] = useState(0);
    const pingIntervalRef = useRef(null); // Usar useRef em vez de useState para o intervalo

    // Função para verificar a conexão com o servidor
    const pingServer = async () => {
        const startTime = Date.now();
        try {
            // Use uma URL confiável e leve para o ping
            const response = await fetch('https://medchoices.com.br:3001/ping', { cache: 'no-store' });
            const latency = Date.now() - startTime;

            if (!response.ok || latency > 2000) { // Considera instável se o tempo de resposta for maior que 2 segundos
                setUnstableCount((prev) => {
                    const newCount = prev + 1;
                    if (newCount >= 3) { // Se 3 pings consecutivos forem instáveis
                        setShowUnstable(true);
                    }
                    return newCount;
                });
            } else {
                setUnstableCount(0); // Reseta o contador se o ping for bem-sucedido
                setShowUnstable(false);
            }
        } catch (error) {
            setUnstableCount((prev) => {
                const newCount = prev + 1;
                if (newCount >= 3) {
                    setShowUnstable(true);
                }
                return newCount;
            });
        }
    };

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setShowOffline(false);
            setShowUnstable(false);
            setUnstableCount(0);
        };

        const handleOffline = () => {
            setIsOnline(false);
            setShowOffline(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Inicia o intervalo de ping quando o componente é montado
        if (navigator.onLine) {
            pingServer(); // Verifica imediatamente
            pingIntervalRef.current = setInterval(pingServer, 5000); // Pinga a cada 5 segundos
        }

        // Limpa os event listeners e o intervalo quando o componente é desmontado
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            if (pingIntervalRef.current) {
                clearInterval(pingIntervalRef.current);
            }
        };
    }, []); // Array de dependências vazio para executar apenas uma vez

    // Atualiza o intervalo de ping quando o status online muda
    useEffect(() => {
        if (isOnline) {
            if (!pingIntervalRef.current) {
                pingServer(); // Verifica imediatamente
                pingIntervalRef.current = setInterval(pingServer, 5000);
            }
        } else {
            if (pingIntervalRef.current) {
                clearInterval(pingIntervalRef.current);
                pingIntervalRef.current = null;
            }
        }
    }, [isOnline]); // Dependência apenas de isOnline

    return (
        <>
            {/* Notificação para conexão offline */}
            <Snackbar
                open={showOffline}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setShowOffline(false)}
                autoHideDuration={6000}
            >
                <Alert onClose={() => setShowOffline(false)} severity="error" sx={{ width: '100%' }}>
                    Você está offline. A conexão com a internet foi perdida.
                </Alert>
            </Snackbar>

            {/* Notificação para conexão instável */}
            <Snackbar
                open={showUnstable}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setShowUnstable(false)}
                autoHideDuration={6000}
            >
                <Alert onClose={() => setShowUnstable(false)} severity="warning" sx={{ width: '100%' }}>
                    A conexão com a internet está instável e isso pode afetar o funcionamento do sistema.
                </Alert>
            </Snackbar>
        </>
    );
};

export default NetworkStatus;
