import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  TextField,
  Container,
  Avatar,
  InputAdornment,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../context/AuthContext';
import { CheckCircle, ErrorOutline, Search } from '@mui/icons-material';

// Configuração do Stripe
const stripePromise = loadStripe('pk_test_51HFoeRLmrBt62Vbvs2lyS0JtYffjRQJPVqdibKydBqkuMsCKu0xuln3RUGCZQDrbvR0qVQzY3p0nCdjhGsN8hL0W00J6ZLoLYR');

const PricingCard = ({ title, price, period, priceId, handlePurchase, loading }) => (
  <Card
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        boxShadow: 10,
        transform: 'scale(1.02)',
        transition: 'all 0.3s ease-in-out',
      },
      borderRadius: 3,
      overflow: 'hidden',
    }}
  >
    <CardContent sx={{ flexGrow: 1, p: 3, bgcolor: 'background.paper' }}>
      <Typography variant="h5" component="div" fontWeight="bold" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="h4" component="div" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        R${price}
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 2 }}>
        {period}
      </Typography>
    </CardContent>
    <CardActions sx={{ p: 3, pt: 0, bgcolor: 'background.default' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handlePurchase(priceId)}
        fullWidth
        size="large"
        disabled={loading}
        sx={{
          py: 1.5,
          textTransform: 'none',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : <CheckCircle sx={{ mr: 1 }} />}
        {loading ? 'Processando...' : 'Comprar'}
      </Button>
    </CardActions>
  </Card>
);

const BloggerProducts = ({ blogger, photo, description, products, handlePurchase, loading }) => (
  <Card sx={{ mb: 6, borderRadius: 3, boxShadow: 3 }}>
    <CardContent>
      <Grid container spacing={4} alignItems="center">
        {/* Foto e informações do blogueiro */}
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'center' }}>
            <Avatar
              src={photo}
              alt={blogger}
              sx={{
                width: 120,
                height: 120,
                margin: '0 auto',
                mb: 2,
                boxShadow: 3,
              }}
            />
            <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
              {blogger}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {description}
            </Typography>
          </Box>
        </Grid>

        {/* Opções de compra */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={4}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.title}>
                <PricingCard {...product} handlePurchase={handlePurchase} loading={loading} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const BloggerReferencesPage = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);
  const { auth } = useAuth();

  const bloggers = [
    {
      name: 'João Silva',
      photo: 'https://img.freepik.com/fotos-gratis/aluno-feliz-com-penteado-afro-mostra-dentes-brancos-fica-de-bom-humor-depois-das-aulas_273609-16608.jpg?t=st=1736983394~exp=1736986994~hmac=1e6f2721983eae9877d13c26a0102929d8a245ebdafe67f50114721d0b78b420&w=1380',
      description: 'Criador de conteúdo focado em tecnologia e inovação.',
      products: [
        { title: 'Mensal', price: '15', period: 'por mês', priceId: 'price_1QhBELLmrBt62VbvnTjcwj69' },
        { title: 'Semestral', price: '85', period: 'por 6 meses', priceId: 'price_1QX8RNLmrBt62VbvggoyJ9tW' },
        { title: 'Anual', price: '160', period: 'por ano', priceId: 'price_1QXkG6LmrBt62Vbvj0kn4WW7' },
      ],
    },
    {
      name: 'Maria Oliveira',
      photo: 'https://img.freepik.com/fotos-gratis/voce-tem-isso-junte-se-a-nos-mulher-sorridente-e-assertiva-apontando-para-frente-convidando-a-trabalhar-para-uma-empresa-ou-evento-elogiando-o-bom-trabalho-gesto-bem-executado-em-pe-sobre-uma-parede-branca_176420-37967.jpg?t=st=1736983361~exp=1736986961~hmac=dd2a998c2b84ed2b63cd46465939377fa05ae64a5ecc7baebd3a01c52cfa4336&w=1380',
      description: 'Especialista em estilo de vida saudável e fitness.',
      products: [
        { title: 'Mensal', price: '20', period: 'por mês', priceId: 'price_1QhBELLmrBt62VbvnTjcwj70' },
        { title: 'Semestral', price: '110', period: 'por 6 meses', priceId: 'price_1QX8RNLmrBt62VbvggoyJ9tX' },
        { title: 'Anual', price: '200', period: 'por ano', priceId: 'price_1QXkG6LmrBt62Vbvj0kn4WW8' },
      ],
    },
  ];

  const filteredBloggers = bloggers.filter((blogger) =>
    blogger.name.toLowerCase().includes(search.toLowerCase())
  );

  const handlePurchase = async (priceId) => {
    if (loading) return;
    setLoading(true);
    setError(null);

    const stripe = await stripePromise;

    try {
      const userId = auth.user?.userId;
      if (!userId) throw new Error('Usuário não autenticado.');

      const response = await fetch('https://medchoices.com.br:3001/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
        },
        body: JSON.stringify({ priceId, userId }),
      });

      if (!response.ok) throw new Error('Erro ao criar sessão de checkout.');

      const session = await response.json();
      await stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
      setError(`Erro: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 8 }}>
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography variant="h3" component="h1" fontWeight="bold" sx={{ mb: 4 }}>
          Referências
        </Typography>
        <TextField
          fullWidth
          placeholder="Buscar..."
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
          }}
          sx={{ maxWidth: 600, mx: 'auto' }}
        />
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ErrorOutline sx={{ mr: 1 }} />
          {error}
        </Typography>
      )}

      {filteredBloggers.map((blogger) => (
        <BloggerProducts
          key={blogger.name}
          blogger={blogger.name}
          photo={blogger.photo}
          description={blogger.description}
          products={blogger.products}
          handlePurchase={handlePurchase}
          loading={loading}
        />
      ))}
    </Container>
  );
};

export default BloggerReferencesPage;
