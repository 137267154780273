import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Container,
  Chip,
  Fade,
  Divider,
  IconButton,
  Tooltip,
  Paper,
  Grid,
  InputAdornment,
  Stack,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import QuizIcon from '@mui/icons-material/Quiz';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useParams, useNavigate } from 'react-router-dom';

// Criação de um tema personalizado com a nova paleta de cores
const theme = createTheme({
  palette: {
    primary: {
      main: '#3D9A8B',
    },
    secondary: {
      main: '#114C5F',
    },
    success: {
      main: '#2a9d8f',
      dark: '#207567', // Cor mais escura para garantir contraste
    },
    warning: {
      main: '#f4a261',
    },
    info: {
      main: '#e9c46a',
    },
    error: {
      main: '#bc4749',
      dark: '#a33a3a', // Cor mais escura para garantir contraste
    },
    cinza: {
      main: '#eeee',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '16px', // Adiciona padding interno padrão
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
});

const DetalhesProva = ({ resultadoId: propResultadoId }) => {
  const { resultadoId: paramResultadoId } = useParams();
  const navigate = useNavigate();
  const resultadoId = propResultadoId || paramResultadoId;

  const [detalhes, setDetalhes] = useState([]);
  const [filteredDetalhes, setFilteredDetalhes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [estatisticas, setEstatisticas] = useState({
    total: 0,
    corretas: 0,
    incorretas: 0,
  });

  useEffect(() => {
    if (!resultadoId) {
      console.error('Erro: resultadoId não fornecido ao DetalhesProva.');
      return;
    }

    const fetchDetalhes = async () => {
      setLoading(true);

      try {
        const response = await fetch(`https://medchoices.com.br:3001/admin/historico/${resultadoId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Erro na resposta da API: ${response.statusText}`);
        }

        const data = await response.json();

        if (data.success) {
          setDetalhes(data.detalhes);
          setFilteredDetalhes(data.detalhes);

          const corretas = data.detalhes.filter(q => q.correta).length;
          setEstatisticas({
            total: data.detalhes.length,
            corretas,
            incorretas: data.detalhes.length - corretas,
          });
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes da prova:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetalhes();
  }, [resultadoId]);

  useEffect(() => {
    const filtered = detalhes.filter((questao) => {
      const term = searchTerm.toLowerCase();
      return (
        questao.enunciado.toLowerCase().includes(term) ||
        ['a', 'b', 'c', 'd'].some((opcao) =>
          questao[`opcao_${opcao}`]?.toLowerCase().includes(term)
        )
      );
    });
    setFilteredDetalhes(filtered);
  }, [searchTerm, detalhes]);

  const renderContent = () => {
    if (!resultadoId) {
      return (
        <Alert
          severity="error"
          variant="filled"
          sx={{ mt: 4 }}
          action={
            <Button color="inherit" size="small" onClick={() => navigate(-1)}>
              Voltar
            </Button>
          }
        >
          ID do resultado não fornecido.
        </Alert>
      );
    }

    if (loading) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
        >
          <CircularProgress size={30} thickness={4} />
          <Typography variant="h7" sx={{ mt: 2, color: 'text.secondary' }}>
            Carregando os detalhes da prova...
          </Typography>
        </Box>
      );
    }

    if (detalhes.length === 0) {
      return (
        <Alert
          severity="warning"
          variant="filled"
          sx={{ mt: 4 }}
          action={
            <Button color="inherit" size="small" onClick={() => navigate(-1)}>
              Voltar
            </Button>
          }
        >
          Nenhum detalhe encontrado para o resultado.
        </Alert>
      );
    }

    return (
      <Fade in={true} timeout={800}>
        <Box>
          {filteredDetalhes.map((questao, index) => (
            <Card
              key={index}
              elevation={2}
              sx={{
                mb: 3,
                position: 'relative',
                overflow: 'visible',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: '4px',
                  backgroundColor: questao.correta ? 'success.main' : 'error.main',
                  borderRadius: '4px 0 0 4px',
                },
              }}
            >
              <CardContent>
                <Stack spacing={2}>
                  {/* Cabeçalho da Questão */}
                  <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                    {/* Cabeçalho da Questão no Mobile */}
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{
                        fontSize: { xs: '1.25rem', sm: '1.5rem' },
                        display: { xs: 'block', sm: 'none' }, // Apenas visível no mobile
                        fontWeight: 'bold',
                        mb: 1, // Margem inferior
                      }}
                    >
                      Questão {index + 1}
                    </Typography>

                    {/* Linha de Tags e Informações */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      {/* Tags e Número no Desktop */}
                      <Typography
                        variant="h6"
                        color="primary"
                        sx={{
                          fontSize: { xs: '1rem', sm: '1.25rem' },
                          display: { xs: 'flex', sm: 'flex' }, // Visível em todos os tamanhos
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        {/* Número da Questão no Desktop */}
                        <Box display={{ xs: 'none', sm: 'block' }}>
                          Questão {index + 1}
                        </Box>
                        {/* Tags */}
                        {questao.correta ? (
                          <Chip
                            icon={<CheckCircleIcon />}
                            label="Correta"
                            color="success"
                            size="small"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            icon={<CancelIcon />}
                            label="Incorreta"
                            color="error"
                            size="small"
                            variant="outlined"
                          />
                        )}
                        {!questao.alternativa_escolhida && (
                          <Chip
                            label="Não Respondida"
                            color="warning"
                            size="small"
                            variant="outlined"
                            sx={{ ml: 1 }}
                            aria-label="Questão não respondida pelo usuário"
                          />
                        )}
                      </Typography>
                    </Box>
                  </Box>


                  {/* Enunciado da Questão */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: 'text.primary',
                      lineHeight: 1.6,
                    }}
                  >
                    {questao.enunciado}
                  </Typography>

                  <Divider />

                  {/* Alternativas */}
                  <Grid container spacing={2}>
                    {['a', 'b', 'c', 'd'].map((opcao) => (
                      <Grid item xs={12} sm={6} md={3} key={opcao}>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            border: '1px solid',
                            borderColor:
                              questao.resposta_correta === opcao ?
                                'success.main' :
                                questao.alternativa_escolhida === opcao ?
                                  'error.main' :
                                  'grey.300',
                            backgroundColor:
                              questao.resposta_correta === opcao ?
                                'success.main' :
                                questao.alternativa_escolhida === opcao ?
                                  'error.main' :
                                  'cinza.main',
                            opacity:
                              questao.resposta_correta === opcao ||
                                questao.alternativa_escolhida === opcao ?
                                1 : 0.8,
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                              opacity: 1,
                              transform: 'translateX(4px)',
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight:
                                questao.resposta_correta === opcao ||
                                  questao.alternativa_escolhida === opcao ?
                                  500 : 400,
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              color: (questao.resposta_correta === opcao || questao.alternativa_escolhida === opcao) ?
                                'white' :
                                'text.primary',
                            }}
                          >
                            {questao[`opcao_${opcao}`]}
                            {/* Chips Indicando Resposta Correta e Resposta do Usuário */}
                            <Box component="span" ml={1}>
                              {questao.resposta_correta === opcao && (
                                <Chip
                                  label="Correta"
                                  size="small"
                                  color="success"
                                  sx={{ ml: 1, fontSize: '0.8rem' }}
                                  aria-label="Resposta correta"
                                />
                              )}
                              {questao.alternativa_escolhida === opcao &&
                                questao.alternativa_escolhida !== questao.resposta_correta && (
                                  <Chip
                                    label="Sua resposta"
                                    size="small"
                                    color="error"
                                    sx={{ ml: 1, fontSize: '0.8em' }}
                                    aria-label="Sua resposta incorreta"
                                  />
                                )}
                            </Box>
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>

                  {/* Tempo Utilizado */}
                  {questao.tempo_utilizado !== undefined && questao.tempo_utilizado !== null && (
                    <Box display="flex" alignItems="center">
                      <Chip
                        icon={<AccessTimeIcon />}
                        size="small"
                        sx={{
                          fontWeight: 500,
                          fontSize: { xs: '0.625rem', sm: '0.75rem' },
                          padding: '2px 6px',
                          maxWidth: '60px',
                          display: 'inline-flex',
                          whiteSpace: 'nowrap',
                        }}
                        aria-label={`Tempo utilizado: ${Math.floor(Number(questao.tempo_utilizado) / 60)} minutos e ${Number(questao.tempo_utilizado) % 60} segundos`}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          ml: 1,
                          fontSize: { xs: '0.75rem', sm: '0.875rem' },
                          fontWeight: 500
                        }}
                      >
                        {`${Math.floor(Number(questao.tempo_utilizado) / 60)}m ${Number(questao.tempo_utilizado) % 60}s`}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Fade>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Box py={4}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              mb: 4,
              backgroundColor: 'background.paper',
              borderRadius: '16px',
              boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
            }}
          >
            {/* Cabeçalho Responsivo */}
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              justifyContent="space-between"
              mb={3}
            >
              <Box display="flex" alignItems="center" gap={2} mb={{ xs: 2, sm: 0 }}>
                <IconButton
                  onClick={() => navigate(-1)}
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                    width: { xs: 30, sm: 40 },
                    height: { xs: 30, sm: 40 },
                  }}
                  aria-label="Voltar"
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{
                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                  }}
                >
                  Detalhes da Prova
                </Typography>
              </Box>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={1}
                width={{ xs: '100%', sm: 'auto' }}
              >
                <Chip
                  icon={<QuizIcon />}
                  label={`Total: ${estatisticas.total}`}
                  color="primary"
                  sx={{
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                />
                <Chip
                  icon={<CheckCircleIcon />}
                  label={`Corretas: ${estatisticas.corretas}`}
                  color="success"
                  sx={{
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                />
                <Chip
                  icon={<CancelIcon />}
                  label={`Incorretas: ${estatisticas.incorretas}`}
                  color="error"
                  sx={{
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                />
              </Stack>
            </Box>

            {/* Barra de Pesquisa Responsiva */}
            <TextField
              fullWidth
              placeholder="Buscar..."
              variant="outlined"
              size="medium"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: 1,
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
              aria-label="Buscar por enunciado ou alternativas"
            />
          </Paper>

          {renderContent()}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default DetalhesProva;
