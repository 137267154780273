// src/pages/Home.js

import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  CircularProgress,
  useTheme,
  Chip,
  Fade,
  Alert,
  Snackbar,
  IconButton,
  Divider,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  School as SchoolIcon,
  Notifications as NotificationsIcon,
  HelpOutline as HelpOutlineIcon,
  Timer as TimerIcon,
  QuestionAnswer as QuestionAnswerIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon, // Para total de provas realizadas
  Equalizer as EqualizerIcon,
  AccessTime as AccessTimeIcon,
  Announcement as AnnouncementIcon,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Instrucoes from './Instrucoes';
import MateriasDisponiveis from './MateriasDisponiveis';
import { alpha } from '@mui/material/styles';

const Home = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [materias, setMaterias] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [avisosPage, setAvisosPage] = useState(1);
  const [materiasPage, setMateriasPage] = useState(1);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Declarado no nível superior
  const [relatorios, setRelatorios] = useState(null);
  const [horaAtual, setHoraAtual] = useState(new Date());
  const [mostrarInstrucoes, setMostrarInstrucoes] = useState(false);
  const [instrucoesVisiveis, setInstrucoesVisiveis] = useState(true);
  const [userName, setUserName] = useState(auth.user?.nome || '');

  const itemsPerPage = 5;

  const isPremium = (
    auth?.user?.status === 'premium' ||
    auth?.user?.status === 'premium_cancelado'
  );

  // Função para verificar se um valor é um número válido ou uma string numérica
  const isValidNumber = (value) => {
    if (typeof value === 'number' && !isNaN(value)) {
      return true;
    }
    if (typeof value === 'string' && value.trim() !== '' && !isNaN(Number(value))) {
      return true;
    }
    return false;
  };

  // Função para converter o valor para número, se possível
  const toNumber = (value) => {
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? null : parsed;
    }
    return null;
  };

  // Função para formatar o aproveitamento médio
  const formatAproveitamentoMedio = (value) => {
    if (isValidNumber(value)) {
      const num = toNumber(value);
      return `${num.toFixed(2)}%`;
    }
    return 'Não disponível';
  };

  // Função para formatar o tempo médio em provas
  const formatTempoMedioProva = (value) => {
    if (isValidNumber(value)) {
      const num = toNumber(value);
      if (num >= 60) {
        return `${(num / 60).toFixed(2)} minutos`;
      }
      return `${num.toFixed(2)} segundos`;
    }
    return 'Não disponível';
  };

  // Função para lidar com o clique na prova
  const handleProvaClick = (prova) => {
    if (prova.access_level === 'premium') {
      if (isPremium) {
        navigate('/provas', { state: { provaId: prova.id } });
      } else {
        navigate('/upgrade');
      }
    } else {
      // Prova gratuita, sempre redireciona para /provas
      navigate('/provas', { state: { provaId: prova.id } });
    }
  };

  // Sincronizar nome do usuário com auth.user
  useEffect(() => {
    if (auth.user?.nome) {
      setUserName(auth.user.nome);
    } else {
      setUserName('');
    }
  }, [auth.user]);

  useEffect(() => {
    const fetchRelatorios = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          throw new Error('Token de autenticação não encontrado.');
        }

        const response = await fetch('https://medchoices.com.br:3001/admin/relatorios/usuario', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Adicionado para garantir compatibilidade
          },
        });

        if (!response.ok) {
          console.error('Resposta da API:', response.status, response.statusText);
          throw new Error('Erro ao buscar relatórios do usuário.');
        }

        const data = await response.json();
        console.log('Dados recebidos da API (relatorios):', data); // Log para verificar a estrutura do retorno
        setRelatorios(data);
      } catch (error) {
        console.error('Erro ao carregar relatórios do usuário:', error);
        setError('Erro ao carregar os relatórios. Tente novamente mais tarde.');
        setShowSnackbar(true);
      }
    };

    fetchRelatorios();
  }, []);

  useEffect(() => {
    if (mostrarInstrucoes) {
      document.body.style.overflow = 'hidden'; // Desativa a rolagem
    } else {
      document.body.style.overflow = 'auto'; // Ativa novamente
    }

    return () => {
      document.body.style.overflow = 'auto'; // Garante que a rolagem é restaurada ao desmontar
    };
  }, [mostrarInstrucoes]);

  useEffect(() => {
    const verificarPrimeiroAcesso = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token não encontrado.');
        }

        const response = await fetch('https://medchoices.com.br:3001/admin/primeiro-acesso', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.error('Erro ao verificar primeiro acesso:', response.status, response.statusText);
          throw new Error('Erro ao verificar primeiro acesso.');
        }

        const data = await response.json();
        console.log('Resposta da API - Primeiro Acesso:', data);

        if (data?.primeiro_acesso) {
          setMostrarInstrucoes(true); // Exibe as instruções
          setInstrucoesVisiveis(true); // Adiciona transição
        } else {
          setMostrarInstrucoes(false); // Garante que não exibe instruções
        }
      } catch (error) {
        console.error('Erro ao verificar primeiro acesso:', error.message);
        setMostrarInstrucoes(false); // Evita exibir instruções em caso de erro
      }
    };

    verificarPrimeiroAcesso();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setHoraAtual(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  const fecharInstrucoes = async () => {
    setInstrucoesVisiveis(false); // Ocultar com transição
    setTimeout(() => setMostrarInstrucoes(false), 300); // Remover do DOM após 300ms

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token não encontrado.');
      }

      const response = await fetch('https://medchoices.com.br:3001/admin/primeiro-acesso', {
        method: 'POST', // Verifique o método correto (POST ou PUT)
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ primeiro_acesso: 0 }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar primeiro acesso.');
      }

      console.log('Primeiro acesso atualizado com sucesso.');
    } catch (error) {
      console.error('Erro ao atualizar primeiro acesso:', error.message);
    }
  };

  useEffect(() => {
    setInstrucoesVisiveis(true);
  }, []);

  useEffect(() => {
    const fetchDados = async () => {
      try {
        const materiasResponse = await fetch('https://medchoices.com.br:3001/admin/materias', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const provasResponse = await fetch('https://medchoices.com.br:3001/admin/provas', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const avisosResponse = await fetch('https://medchoices.com.br:3001/admin/avisos', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!materiasResponse.ok || !provasResponse.ok || !avisosResponse.ok) {
          throw new Error('Erro ao buscar dados.');
        }

        const materiasData = await materiasResponse.json();
        const provasData = await provasResponse.json();
        const avisosData = await avisosResponse.json();

        if (materiasData.success && provasData.success) {
          const materiasComProvas = materiasData.materias.map((materia) => ({
            ...materia,
            provas: provasData.provas.filter(
              (prova) => prova.materia_id === materia.id && prova.total_questoes > 0
            ),
          }));
          setMaterias(materiasComProvas.filter((materia) => materia.provas.length > 0));
        } else {
          setMaterias([]);
        }

        if (avisosData.success) {
          setAvisos(avisosData.avisos || []);
        } else {
          setAvisos([]);
        }
      } catch (error) {
        setError('Erro ao carregar dados. Por favor, tente novamente mais tarde.');
        setShowSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchDados();
  }, []);

  const filteredMaterias = materias.filter((materia) =>
    materia.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedMaterias = filteredMaterias.slice(
    (materiasPage - 1) * itemsPerPage,
    materiasPage * itemsPerPage
  );

  const paginatedAvisos = avisos.slice(
    (avisosPage - 1) * itemsPerPage,
    avisosPage * itemsPerPage
  );

  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography color="error">Erro ao carregar dados.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 6 }}>
          {/* Cabeçalho Bem-vindo e Relatórios */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: { xs: 'center', md: 'left' },
              py: 4,
              px: 2,
              bgcolor: 'grey.100',
              borderRadius: 2,
            }}
          >
            {/* Cabeçalho Bem-vindo */}
            <Box sx={{ flex: 1, mb: { xs: 3, md: 0 }, pl: { xs: 2, md: 4 } }}>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mb: 2 }}>
                <SchoolIcon
                  sx={{
                    fontSize: { xs: 30, md: 40 }, // Menor no mobile
                    color: theme.palette.primary.main,
                    mr: 1,
                  }}
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '1.5rem', md: '2rem' }, // Menor no mobile
                    color: theme.palette.primary.main,
                  }}
                >
                  Bem-vindo, {userName}!
                </Typography>
              </Box>
              <Typography
                variant="h6"
                sx={{
                  color: 'text.secondary',
                  fontSize: { xs: '0.875rem', md: '1rem' }, // Menor no mobile
                  mb: 1,
                }}
              >
                Hoje é {horaAtual.toLocaleDateString('pt-BR', {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}.
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: 'text.secondary',
                  fontSize: { xs: '0.875rem', md: '1rem' }, // Menor no mobile
                }}
              >
                Agora são {horaAtual.toLocaleTimeString('pt-BR')}.
              </Typography>
              {/* <Button onClick={() => setMostrarInstrucoes(true)}>Testar Instruções</Button> */}

            </Box>

            {/* Detalhes do Relatório */}
            {relatorios && (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                  gap: 2,
                  flex: 1,
                  textAlign: 'center',
                }}
              >
                {/* Última Prova Feita */}
                <Box>
                  <QuestionAnswerIcon sx={{ fontSize: 32, color: 'grey.600' }} />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', color: 'grey.800', mt: 1 }}
                  >
                    Última prova feita
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {relatorios.ultima_prova_feita
                      ? `${relatorios.ultima_prova_feita} em ${new Date(
                        relatorios.data_ultima_prova
                      ).toLocaleString('pt-BR')}`
                      : 'Não disponível'}
                  </Typography>
                </Box>

                {/* Total de Provas Realizadas */}
                <Box>
                  <AssignmentTurnedInIcon sx={{ fontSize: 32, color: 'grey.600' }} />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', color: 'grey.800', mt: 1 }}
                  >
                    Total de provas realizadas
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {relatorios.total_provas_realizadas || 0}
                  </Typography>
                </Box>

                {/* Aproveitamento Médio */}
                <Box>
                  <EqualizerIcon sx={{ fontSize: 32, color: 'grey.600' }} />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', color: 'grey.800', mt: 1 }}
                  >
                    Aproveitamento médio
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {formatAproveitamentoMedio(relatorios.aproveitamento_medio)}
                  </Typography>
                </Box>

                {/* Tempo Médio em Provas */}
                <Box>
                  <AccessTimeIcon sx={{ fontSize: 32, color: 'grey.600' }} />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', color: 'grey.800', mt: 1 }}
                  >
                    Tempo médio em provas
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {formatTempoMedioProva(relatorios.tempo_medio_prova)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* Indicador */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4, // Espaço acima do indicador
              mb: 4, // Espaço abaixo do indicador
            }}
          >
            {isSmallScreen && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'grey.500',
                    mb: 1,
                    textAlign: 'center',
                    animation: 'bounce 2s infinite',
                    '@keyframes bounce': {
                      '0%, 100%': { transform: 'translateY(0)' },
                      '50%': { transform: 'translateY(8px)' },
                    },
                  }}
                >
                  Desça para ver as instruções e avisos.
                </Typography>
                <ExpandMoreIcon
                  sx={{
                    fontSize: 40,
                    color: 'grey.500',
                    animation: 'bounce 2s infinite',
                  }}
                />
              </>
            )}
          </Box>

          {mostrarInstrucoes && (
            <Instrucoes onFinalizar={fecharInstrucoes} auth={auth} />
          )}

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Fade in timeout={500}>
                <Box
                  sx={{
                    height: '100%',
                    transition: '0.3s',
                    p: 3,
                    bgcolor: 'background.paper',
                    border: `1px solid #f5f5f5`,
                    borderRadius: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <IconButton
                      sx={{
                        bgcolor: 'primary.light',
                        color: 'white',
                        mr: 2,
                        '&:hover': { bgcolor: 'primary.main' },
                      }}
                    >
                      <AnnouncementIcon />
                    </IconButton>
                    <Typography variant="h5">Instruções do Sistema</Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  <List>
                    {[
                      <>
                        Acesse o menu no canto superior e selecione a opção{' '}
                        <Typography
                          component="span"
                          sx={{ color: 'primary.main', cursor: 'pointer' }}
                          onClick={() => navigate('/provas')} // Usando useNavigate
                        >
                          "Provas"
                        </Typography>
                        .
                      </>,
                      <>
                        Escolha a{' '}
                        <Typography
                          component="span"
                          sx={{ color: 'primary.main', cursor: 'pointer' }}
                          onClick={() => navigate('/materiasdisponiveis')} // Tornando "matéria" clicável
                        >
                          "matéria"
                        </Typography>{' '}
                        desejada e, em seguida, selecione a prova que deseja realizar.
                      </>,
                      'Durante a prova, fique atento ao limite de tempo, pois ela será finalizada automaticamente ao término do cronômetro.',
                      'Ao concluir, clique em "Finalizar" para revisar suas questões e conferir seus resultados.',
                      <>
                        Você pode acessar o menu{' '}
                        <Typography
                          component="span"
                          sx={{ color: 'primary.main', cursor: 'pointer' }}
                          onClick={() => navigate('/historico')} // Usando useNavigate
                        >
                          "Histórico"
                        </Typography>{' '}
                        para revisar as respostas, identificar erros e acompanhar seu desempenho.
                      </>,
                      'Se identificar algum problema em uma questão, reporte o erro para que nossa equipe possa revisá-lo e corrigi-lo, se necessário.',
                      <>
                        Para desbloquear todas as funcionalidades do MedChoices, acesse o menu{' '}
                        <Typography
                          component="span"
                          sx={{ color: 'primary.main', cursor: 'pointer' }}
                          onClick={() => navigate('/assinatura')} // Usando useNavigate
                        >
                          "Assinatura"
                        </Typography>
                        .
                      </>,
                    ].map((text, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 2,
                          mb: 1,
                        }}
                      >
                        <Chip
                          label={index + 1}
                          size="small"
                          color="primary"
                          sx={{ mt: 0.5 }}
                        />
                        <Typography variant="body1">{text}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Fade>
            </Grid>

            {/* Avisos do Medchoices */}
            <Grid item xs={12} md={6}>
              <Fade in timeout={700}>
                <Box
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    border: `1px solid #f5f5f5`,
                    transition: '0.3s',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <IconButton
                      sx={{
                        bgcolor: 'secondary.light',
                        color: 'white',
                        mr: 2,
                        '&:hover': { bgcolor: 'secondary.main' },
                      }}
                    >
                      <NotificationsIcon />
                    </IconButton>
                    <Typography variant="h5">Avisos do Medchoices</Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  {paginatedAvisos.length > 0 ? (
                    <List>
                      {paginatedAvisos.map((aviso, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: 'grey.50',
                            borderRadius: 2,
                            mb: 1,
                            p: 2,
                            '&:hover': {
                              bgcolor: 'grey.100',
                            },
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 'bold', color: 'text.primary', mb: 0.5 }}
                            >
                              {aviso.titulo || 'Aviso Importante'}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ color: 'text.secondary', mb: 1 }}
                            >
                              {aviso.criado_em
                                ? new Date(aviso.criado_em).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })
                                : 'Data não disponível'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {aviso.mensagem}
                            </Typography>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Alert
                      severity="info"
                      sx={{
                        borderRadius: 2,
                        '& .MuiAlert-icon': { alignItems: 'center' },
                      }}
                    >
                      Nenhum aviso disponível no momento.
                    </Alert>
                  )}
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant="outlined"
                      disabled={avisosPage === 1}
                      onClick={() => setAvisosPage((prev) => prev - 1)}
                      startIcon={<ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />}
                    >
                      Anterior
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={avisosPage * itemsPerPage >= avisos.length}
                      onClick={() => setAvisosPage((prev) => prev + 1)}
                      endIcon={<ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
                    >
                      Próximo
                    </Button>
                  </Box>
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </Box>

        {/* Snackbar para Erros */}
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShowSnackbar(false)}
            severity="error"
            sx={{
              width: '100%',
              borderRadius: 2,
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Home;
