// frontend/src/pages/QuestoesForm.js

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Grid,
  Checkbox,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Divider,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
  Fade,
} from '@mui/material';
import {
  X as LucideCloseIcon,
  Trash as LucideTrashIcon,
  Eye as LucideEyeIcon,
  Upload as LucideUploadIcon,
  Save as LucideSaveIcon,
  ArrowLeft as LucideArrowLeftIcon,
  ArrowRight as LucideArrowRightIcon,
  Image as LucideImageIcon,
  Check as LucideCheckIcon,
} from 'lucide-react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const QuestoesForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Informações Básicas', 'Opções de Resposta', 'Imagem (Opcional)', 'Revisão'];

  const [materias, setMaterias] = useState([]);
  const [provas, setProvas] = useState([]);

  const [materia_id, setMateriaId] = useState('');
  const [prova_id, setProvaId] = useState('');
  const [enunciado, setEnunciado] = useState('');
  const [opcao_a, setOpcaoA] = useState('');
  const [opcao_b, setOpcaoB] = useState('');
  const [opcao_c, setOpcaoC] = useState('');
  const [opcao_d, setOpcaoD] = useState('');
  const [resposta_correta, setRespostaCorreta] = useState('');

  const [imageSource, setImageSource] = useState('none');
  const [imagem, setImagem] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('500'); // Default inicial

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [removeImage, setRemoveImage] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryProvaId = params.get('prova_id');
  const queryMateriaId = params.get('materia_id');

  const disableMateriaProva = Boolean(queryProvaId && queryMateriaId) || Boolean(id);

  useEffect(() => {
    const fetchMaterias = async () => {
      try {
        const response = await fetch('https://medchoices.com.br:3001/admin/materias', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const data = await response.json();

        if (data.success) {
          setMaterias(data.materias);
        } else {
          setError(data.message || 'Erro ao buscar Matérias.');
        }
      } catch (error) {
        console.error('Erro ao buscar Matérias:', error);
        setError('Erro ao buscar Matérias.');
      }
    };

    const fetchQuestao = async () => {
      if (id) {
        try {
          const response = await fetch(`https://medchoices.com.br:3001/admin/questoes/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });

          const data = await response.json();

          if (data.success) {
            const questao = data.questao;
            if (questao) {
              setMateriaId(questao.prova_materia_id.toString());
              setProvaId(questao.prova_id.toString());
              setEnunciado(questao.enunciado);
              setOpcaoA(questao.opcao_a);
              setOpcaoB(questao.opcao_b);
              setOpcaoC(questao.opcao_c);
              setOpcaoD(questao.opcao_d);
              setRespostaCorreta(questao.resposta_correta);

              // Definir imageHeight a partir do backend, se disponível
              if (questao.image_height) {
                setImageHeight(questao.image_height.toString());
              } else {
                setImageHeight('500'); // Default caso não esteja definido
              }

              if (questao.imagem) {
                if (questao.imagem.startsWith('http')) {
                  setImageSource('url');
                  setImageURL(questao.imagem);
                } else {
                  setImageSource('upload');
                  setImagem({
                    file: null, // O arquivo real não está disponível; apenas a URL de visualização
                    preview: `https://medchoices.com.br:3001/${questao.imagem}`,
                  });
                }
              } else {
                setImageSource('none');
              }
            } else {
              setError('Questão não encontrada.');
            }
          } else {
            setError(data.message || 'Erro ao buscar Questão.');
          }
        } catch (error) {
          console.error('Erro ao buscar Questão:', error);
          setError('Erro ao buscar Questão.');
        }
      } else {
        if (queryProvaId) {
          setProvaId(queryProvaId);
        }
        if (queryMateriaId) {
          setMateriaId(queryMateriaId);
        }
      }
    };

    const initialize = async () => {
      await fetchMaterias();
      await fetchQuestao();
      setLoading(false);
    };

    initialize();
  }, [id, queryProvaId, queryMateriaId]);

  useEffect(() => {
    const fetchProvas = async () => {
      if (materia_id) {
        try {
          const response = await fetch('https://medchoices.com.br:3001/admin/provas', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });

          const data = await response.json();

          if (data.success) {
            const provasFiltradas = data.provas.filter((p) => p.materia_id === parseInt(materia_id));
            setProvas(provasFiltradas);
          } else {
            setError(data.message || 'Erro ao buscar Provas.');
          }
        } catch (error) {
          console.error('Erro ao buscar Provas:', error);
          setError('Erro ao buscar Provas.');
        }
      } else {
        setProvas([]);
        if (!id) setProvaId('');
      }
    };

    fetchProvas();
  }, [materia_id, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');

    const formData = new FormData();
    formData.append('prova_id', prova_id);
    formData.append('enunciado', enunciado);
    formData.append('opcao_a', opcao_a);
    formData.append('opcao_b', opcao_b);
    formData.append('opcao_c', opcao_c);
    formData.append('opcao_d', opcao_d);
    formData.append('resposta_correta', resposta_correta);

    if (imageSource === 'url' && imageURL) {
      formData.append('image_url', imageURL);
      formData.append('image_height', imageHeight);
    } else if (imageSource === 'upload' && imagem) {
      if (imagem.file) {
        formData.append('imagem', imagem.file);
      }
      formData.append('image_height', imageHeight);
    }

    if (removeImage && id) {
      formData.append('remove_image', 'true');
    }

    try {
      const response = await fetch(
        id ? `https://medchoices.com.br:3001/admin/questoes/${id}` : `https://medchoices.com.br:3001/admin/questoes`,
        {
          method: id ? 'PUT' : 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        }
      );

      const contentType = response.headers.get('content-type');
      let resData;
      if (contentType && contentType.indexOf('application/json') !== -1) {
        resData = await response.json();
      } else {
        const text = await response.text();
        throw new Error(`Resposta inesperada do servidor: ${text}`);
      }

      if (resData.success) {
        setSnackbar({
          open: true,
          message: id ? 'Questão atualizada com sucesso!' : 'Questão criada com sucesso!',
          severity: 'success'
        });
        navigate(`/admin/questoes?prova_id=${prova_id}&materia_id=${materia_id}`);
      } else {
        setError(resData.message || 'Erro ao salvar Questão.');
      }
    } catch (error) {
      console.error('Erro ao salvar Questão:', error);
      setError(error.message || 'Erro ao salvar Questão.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        return materia_id && prova_id && enunciado;
      case 1:
        return opcao_a && opcao_b && opcao_c && opcao_d && resposta_correta;
      case 2:
        return true; // Imagem é opcional
      case 3:
        return true; // Revisão
      default:
        return false;
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel>Matéria</InputLabel>
                  <Select
                    label="Matéria"
                    value={materia_id}
                    onChange={(e) => setMateriaId(e.target.value)}
                    disabled={disableMateriaProva}
                  >
                    {materias.map((materia) => (
                      <MenuItem key={materia.id} value={materia.id}>
                        {materia.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel>Prova</InputLabel>
                  <Select
                    label="Prova"
                    value={prova_id}
                    onChange={(e) => setProvaId(e.target.value)}
                    disabled={!materia_id || disableMateriaProva}
                  >
                    {provas.map((prova) => (
                      <MenuItem key={prova.id} value={prova.id}>
                        {prova.titulo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Enunciado"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  value={enunciado}
                  onChange={(e) => setEnunciado(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        );

      case 1:
        return (
          <Box sx={{ p: 3 }}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                Opções de Resposta
              </FormLabel>
              <Grid container spacing={3}>
                {[
                  { label: 'A', value: opcao_a, setter: setOpcaoA },
                  { label: 'B', value: opcao_b, setter: setOpcaoB },
                  { label: 'C', value: opcao_c, setter: setOpcaoC },
                  { label: 'D', value: opcao_d, setter: setOpcaoD },
                ].map((option) => (
                  <Grid item xs={12} md={6} key={option.label}>
                    <Card
                      variant="outlined"
                      sx={{
                        borderColor: resposta_correta === option.label.toLowerCase()
                          ? theme.palette.success.main
                          : theme.palette.divider
                      }}
                    >
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <FormControlLabel
                            value={option.label.toLowerCase()}
                            control={
                              <Radio
                                checked={resposta_correta === option.label.toLowerCase()}
                                onChange={(e) => setRespostaCorreta(e.target.value)}
                              />
                            }
                            label={`Opção ${option.label}`}
                          />
                          {resposta_correta === option.label.toLowerCase() && (
                            <Typography
                              variant="caption"
                              color="success.main"
                              sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
                            >
                              <LucideCheckIcon size={16} style={{ marginRight: 4 }} />
                              Resposta Correta
                            </Typography>
                          )}
                        </Box>
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          variant="outlined"
                          value={option.value}
                          onChange={(e) => option.setter(e.target.value)}
                          placeholder={`Digite a opção ${option.label}`}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </Box>
        );

      case 2:
        return (
          <Box sx={{ p: 3 }}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Tipo de Imagem</FormLabel>
              <RadioGroup
                row
                value={imageSource}
                onChange={(e) => {
                  setImageSource(e.target.value);
                  if (e.target.value === 'none') {
                    setImagem(null);
                    setImageURL('');
                    setImageWidth('');
                    setImageHeight('500');
                    setRemoveImage(false);
                  } else {
                    setImageHeight('500');
                  }
                }}
              >
                <FormControlLabel value="none" control={<Radio />} label="Nenhuma" />
                <FormControlLabel value="url" control={<Radio />} label="Imagem via URL" />
                <FormControlLabel value="upload" control={<Radio />} label="Upload de Imagem" />
              </RadioGroup>
            </FormControl>

            {imageSource !== 'none' && (
              <Paper variant="outlined" sx={{ mt: 3, p: 3 }}>
                {imageSource === 'url' ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="URL da Imagem"
                        variant="outlined"
                        fullWidth
                        value={imageURL}
                        onChange={(e) => setImageURL(e.target.value)}
                        required
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        label="Altura da Imagem (px)"
                        variant="outlined"
                        fullWidth
                        value={imageHeight}
                        onChange={(e) => setImageHeight(e.target.value)}
                        type="number"
                      />
                    </Grid> */}
                    {imageURL && (
                      <Grid item xs={12}>
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Pré-visualização:
                          </Typography>
                          <img
                            src={imageURL}
                            alt="Pré-visualização"
                            style={{
                              maxWidth: '100%',
                              height: `${imageHeight}px`,
                              objectFit: 'contain',
                              borderRadius: '8px',
                              boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                            }}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        component="label"
                        fullWidth
                        startIcon={<LucideUploadIcon size={20} />}
                        sx={{ height: 56 }}
                      >
                        {imagem ? 'Alterar Imagem' : 'Adicionar Imagem'}
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                              setImagem({
                                file: e.target.files[0],
                                preview: URL.createObjectURL(e.target.files[0]),
                              });
                            }
                          }}
                        />
                      </Button>
                    </Grid>
                    {imagem && (
                      <>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <LucideImageIcon size={20} />
                            <Typography variant="body2">
                              {imagem.file ? imagem.file.name : 'Imagem selecionada'}
                            </Typography>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => setImagem(null)}
                            >
                              <LucideTrashIcon size={16} />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Altura da Imagem (px)"
                            variant="outlined"
                            fullWidth
                            value={imageHeight}
                            onChange={(e) => setImageHeight(e.target.value)}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ mt: 2, textAlign: 'center' }}>
                            <Typography variant="subtitle1" gutterBottom>
                              Pré-visualização:
                            </Typography>
                            <img
                              src={imagem.preview}
                              alt="Pré-visualização"
                              style={{
                                maxWidth: '100%',
                                height: `${imageHeight}px`,
                                objectFit: 'contain',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                              }}
                            />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </Paper>
            )}
          </Box>
        );

      case 3:
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Revisão da Questão
            </Typography>
            <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Matéria
                  </Typography>
                  <Typography variant="body1">
                    {materias.find(m => m.id === parseInt(materia_id))?.nome || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Prova
                  </Typography>
                  <Typography variant="body1">
                    {provas.find(p => p.id === parseInt(prova_id))?.titulo || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle2" color="textSecondary">
                    Enunciado
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {enunciado}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    Opções de Resposta
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      { label: 'A', value: opcao_a },
                      { label: 'B', value: opcao_b },
                      { label: 'C', value: opcao_c },
                      { label: 'D', value: opcao_d },
                    ].map((option) => (
                      <Grid item xs={12} sm={6} key={option.label}>
                        <Paper
                          variant="outlined"
                          sx={{
                            p: 2,
                            bgcolor: resposta_correta === option.label.toLowerCase()
                              ? 'success.light'
                              : 'background.paper'
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            color={resposta_correta === option.label.toLowerCase() ? 'success.dark' : 'textSecondary'}
                          >
                            Opção {option.label}
                            {resposta_correta === option.label.toLowerCase() && ' (Correta)'}
                          </Typography>
                          <Typography variant="body2">{option.value}</Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {imageSource !== 'none' && (imagem?.preview || imageURL) && (
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Imagem
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                      <img
                        src={imagem?.preview || imageURL}
                        alt="Imagem da questão"
                        style={{
                          maxWidth: '100%',
                          height: `${imageHeight}px`,
                          objectFit: 'contain',
                          borderRadius: '8px',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Box>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth="xl" sx={{ mx: 'auto', p: { xs: 2, sm: 3 } }}>
      <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
        <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', p: 3 }}>
          <Typography variant="h5" component="h1">
            {id ? 'Editar Questão' : 'Criar Nova Questão'}
          </Typography>
        </Box>

        {error && (
          <Box sx={{ p: 2 }}>
            <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          </Box>
        )}

        <Stepper
          activeStep={activeStep}
          alternativeLabel={!isMobile}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          sx={{ pt: 3, px: { xs: 2, sm: 3 } }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <form onSubmit={handleSubmit}>
          {renderStepContent()}

          <Box sx={{ p: 3, bgcolor: 'background.default' }}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  startIcon={<LucideArrowLeftIcon size={20} />}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {activeStep === steps.length - 1 ? (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                        startIcon={<LucideArrowLeftIcon size={20} />}
                      >
                        Voltar para Revisão
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        startIcon={submitting ? <CircularProgress size={20} /> : <LucideSaveIcon size={20} />}
                      >
                        {submitting ? 'Salvando...' : (id ? 'Atualizar' : 'Criar')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={!isStepValid()}
                      endIcon={<LucideArrowRightIcon size={20} />}
                    >
                      Próximo
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QuestoesForm;
