// frontend/src/components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../context/AuthContext';
import { CircularProgress, Typography, Box } from '@mui/material';

const PrivateRoute = ({ children, requiredRole, requiredStatus }) => {
  const { auth, loading } = useAuth();

  // console.log('🔑 [PrivateRoute]: Iniciando verificação de acesso.');

  if (loading) {
    console.log('🔄 [PrivateRoute]: Estado de carregamento ativo.');
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
        }}
      >
        {/* <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Carregando...
        </Typography> */}
      </Box>
    );
  }

  if (!auth.isAuthenticated) {
    console.warn('⚠️ [PrivateRoute]: Usuário não autenticado. Redirecionando para /login.');
    return <Navigate to="/login" replace />;
  }

  if (requiredRole && auth.user?.funcao !== requiredRole) {
    console.warn(
      `⚠️ [PrivateRoute]: Usuário com função "${auth.user.funcao}" não tem permissão para acessar esta rota. Requer "${requiredRole}". Redirecionando para /inicio.`
    );
    return <Navigate to="/inicio" replace />;
  }

  if (requiredStatus && auth.user?.status !== requiredStatus) {
    console.warn(
      `⚠️ [PrivateRoute]: Usuário com status "${auth.user.status}" não tem permissão para acessar esta rota. Requer "${requiredStatus}". Redirecionando para /upgrade.`
    );
    return <Navigate to="/upgrade" replace />;
  }

  // console.log('✅ [PrivateRoute]: Acesso autorizado. Renderizando componente.');
  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  requiredRole: PropTypes.string,
  requiredStatus: PropTypes.string,
};

PrivateRoute.defaultProps = {
  requiredRole: null,
  requiredStatus: null,
};

export default PrivateRoute;
