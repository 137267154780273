import React, { useContext } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Checkout = () => {
  const navigate = useNavigate();
  const { auth, refreshUser, logout } = useContext(AuthContext);

  const handlePayment = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.warn('Token não encontrado. Realizando logout local.');
      logout();
      navigate('/login');
      return;
    }

    fetch('https://medchoices.com.br:3001/upgrade', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            console.log('✅ Upgrade realizado com sucesso:', data.message);
      
            // Atualiza o token no localStorage e no contexto
            localStorage.setItem('token', data.token);
            refreshUser(data.token); // Busca os dados atualizados do usuário
      
            navigate('/inicio');
          } else {
            console.warn('⚠️ Erro ao realizar upgrade:', data.message);
          }
        })
        .catch((error) => {
          console.error('❌ Erro ao processar o upgrade:', error);
        });
      
  };

  return (
    <Box sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Checkout Premium
      </Typography>
      <Typography variant="body1" gutterBottom>
        Processe seu pagamento para se tornar um usuário premium.
      </Typography>
      <Button variant="contained" color="primary" onClick={handlePayment}>
        Pagar Agora
      </Button>
    </Box>
  );
};

export default Checkout;
