// frontend/src/components/SubscriptionList.js

import React, { useState, useCallback, useMemo } from 'react';
import {
  Box, Card, CardContent, Typography, Button, Grid, TextField, FormControl,
  InputLabel, Select, MenuItem, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Checkbox, TableSortLabel,
  Pagination, Snackbar, Alert, Avatar, Chip, Tooltip, Skeleton, InputAdornment,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  useTheme, useMediaQuery, alpha, CircularProgress
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon,
  Person as PersonIcon,
  Add as AddIcon,
  SwapHoriz as SwapHorizIcon
} from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import debounce from 'lodash.debounce';
import UserForm from '../pages/Admin/components/UserForm'; // Assegure-se de ter este componente
import SubscriptionForm from './SubscriptionForm'; // Importando o SubscriptionForm

const queryClient = new QueryClient();

// Hook para gerenciar lógica de obtenção de dados
function useSubscriptionsData({ searchTerm, filterStatus, sortBy, order, currentPage }) {
  const query = useQuery({
    queryKey: ['subscriptions', searchTerm, filterStatus, sortBy, order, currentPage],
    queryFn: async () => {
      const params = new URLSearchParams({
        search: searchTerm || '',
        status: filterStatus || '',
        sortBy: sortBy || 'nome',
        order: order || 'asc',
        page: currentPage.toString(),
        limit: '50',
      });

      const response = await fetch(`https://medchoices.com.br:3001/admin/subscriptions?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const responseData = await response.json();

      if (!responseData.success) {
        throw new Error(responseData.message || 'Erro ao buscar assinaturas.');
      }

      // Log para depuração
      console.log('Resposta da API /admin/subscriptions:', responseData);

      return responseData;
    },
    keepPreviousData: true,
    staleTime: 1000 * 60, // 1 minuto
    retry: 1,
  });

  return query;
}

function useDebounceValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debounced = useCallback(
    debounce((val) => {
      setDebouncedValue(val);
    }, delay),
    [delay]
  );

  React.useEffect(() => {
    debounced(value);
    // Cancel the debounce on useEffect cleanup.
    return debounced.cancel;
  }, [value, debounced]);

  return debouncedValue;
}

const SubscriptionListInner = React.memo(function SubscriptionListInner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [rawSearchTerm, setRawSearchTerm] = useState('');
  const searchTerm = useDebounceValue(rawSearchTerm, 300);
  const [filterStatus, setFilterStatus] = useState('');
  const [sortBy, setSortBy] = useState('nome');
  const [order, setOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Modal states
  const [openUserForm, setOpenUserForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [cancelUserId, setCancelUserId] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false); // Feedback visual

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [upgradeUserId, setUpgradeUserId] = useState(null);
  const [upgradeLoading, setUpgradeLoading] = useState(false); // Feedback visual

  // Estados para Exclusão em Massa
  const [openBatchDeleteDialog, setOpenBatchDeleteDialog] = useState(false);
  const [batchDeleteLoading, setBatchDeleteLoading] = useState(false);

  // Estados para Exclusão Individual
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Estados para SubscriptionForm
  const [openSubscriptionForm, setOpenSubscriptionForm] = useState(false);
  const [subscriptionUser, setSubscriptionUser] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const { data, isLoading, error, refetch } = useSubscriptionsData({
    searchTerm, filterStatus, sortBy, order, currentPage
  });

  const users = data?.users || [];
  const totalUsers = data?.totalUsers || 0;
  const totalPages = data?.totalPages || 1;

  // Log para depuração
  console.log('Dados de assinaturas no frontend:', users);

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const handleSearchChange = (e) => {
    setRawSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterStatusChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field) => {
    const isAsc = sortBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);
  };

  const handleSelectAll = (event) => {
    setSelectedUsers(event.target.checked ? users.map(user => user.id) : []);
  };

  const handleSelectOne = (userId) => {
    setSelectedUsers(prev =>
      prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
    );
  };

  // Função para Cancelar Assinatura
  const handleCancelSubscription = async (userId) => {
    setCancelLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/subscriptions/${userId}/cancel`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({
          open: true,
          message: 'Assinatura marcada para cancelamento ao final do período.',
          severity: 'success',
        });
        refetch();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Erro ao cancelar assinatura:', error);
      setSnackbar({
        open: true,
        message: 'Erro ao cancelar assinatura.',
        severity: 'error',
      });
    } finally {
      setCancelLoading(false);
      setOpenCancelDialog(false);
      setCancelUserId(null);
    }
  };

  // Função para Atualizar para Premium
  const handleUpgradeSubscription = async (userId) => {
    setUpgradeLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/subscriptions/${userId}/upgrade`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({
          open: true,
          message: 'Usuário atualizado para Premium com sucesso.',
          severity: 'success',
        });
        refetch();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Erro ao atualizar para Premium:', error);
      setSnackbar({
        open: true,
        message: 'Erro ao atualizar para Premium.',
        severity: 'error',
      });
    } finally {
      setUpgradeLoading(false);
      setOpenUpgradeDialog(false);
      setUpgradeUserId(null);
    }
  };

  // Função para Exclusão em Massa
  const handleBatchDelete = async () => {
    setBatchDeleteLoading(true);
    try {
      if (selectedUsers.length === 0) {
        throw new Error('Nenhum usuário selecionado para exclusão.');
      }

      const deletePromises = selectedUsers.map(userId =>
        fetch(`https://medchoices.com.br:3001/admin/users/${userId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(res => res.json())
      );

      const results = await Promise.allSettled(deletePromises);

      const failedDeletes = [];
      results.forEach((result, index) => {
        const userId = selectedUsers[index];
        if (result.status === 'fulfilled') {
          if (!result.value.success) {
            failedDeletes.push({ userId, message: result.value.message || 'Erro desconhecido.' });
          }
        } else {
          failedDeletes.push({ userId, message: result.reason.message || 'Erro desconhecido.' });
        }
      });

      if (failedDeletes.length > 0) {
        const failedMessage = failedDeletes.map(fd => `ID ${fd.userId}: ${fd.message}`).join('\n');
        throw new Error(`Falha ao excluir ${failedDeletes.length} usuário(s):\n${failedMessage}`);
      }

      setSnackbar({
        open: true,
        message: 'Usuários excluídos com sucesso!',
        severity: 'success',
      });
      setSelectedUsers([]);
      refetch();
    } catch (error) {
      console.error('Erro ao excluir usuários em massa:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Erro ao excluir usuários em massa.',
        severity: 'error',
      });
    } finally {
      setBatchDeleteLoading(false);
      setOpenBatchDeleteDialog(false);
    }
  };

  const handleOpenUserForm = (user = null) => {
    setSelectedUser(user);
    setOpenUserForm(true);
  };

  const handleCloseUserForm = () => {
    setOpenUserForm(false);
    setSelectedUser(null);
  };

  const handleOpenCancelDialog = (userId) => {
    setCancelUserId(userId);
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setCancelUserId(null);
  };

  const handleOpenUpgradeDialog = (userId) => {
    setUpgradeUserId(userId);
    setOpenUpgradeDialog(true);
  };

  const handleCloseUpgradeDialog = () => {
    setOpenUpgradeDialog(false);
    setUpgradeUserId(null);
  };

  // Funções para Exclusão em Massa
  const handleOpenBatchDeleteDialog = () => {
    setOpenBatchDeleteDialog(true);
  };

  const handleCloseBatchDeleteDialog = () => {
    setOpenBatchDeleteDialog(false);
  };

  // Funções para Exclusão Individual
  const handleOpenDeleteDialog = (userId) => {
    setDeleteUserId(userId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteUserId(null);
  };

  const handleDeleteUser = async (userId) => {
    setDeleteLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/users/${userId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setSnackbar({
          open: true,
          message: 'Usuário excluído com sucesso.',
          severity: 'success',
        });
        setSelectedUsers(prev => prev.filter(id => id !== userId));
        refetch();
      } else {
        throw new Error(data.message || 'Erro ao excluir usuário.');
      }
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
      setSnackbar({
        open: true,
        message: 'Erro ao excluir usuário.',
        severity: 'error',
      });
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteDialog();
    }
  };

  // Função para abrir o SubscriptionForm
  const handleOpenSubscriptionForm = (user) => {
    setSubscriptionUser(user);
    setOpenSubscriptionForm(true);
  };

  // Função para fechar o SubscriptionForm
  const handleCloseSubscriptionForm = () => {
    setOpenSubscriptionForm(false);
    setSubscriptionUser(null);
  };

  return (
    <Box sx={{ maxWidth: 1400, margin: '0 auto', padding: 3 }}>
      {/* Header Card */}
      <Card elevation={0} sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.05), mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <Box>
              <Typography variant="h4" fontWeight="bold" color="primary" component="h1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PersonIcon /> Gerenciamento de Assinaturas
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {isLoading ? 'Carregando...' : error ? 'Erro ao carregar assinaturas' : `Total de ${totalUsers} assinaturas`}
              </Typography>
            </Box>
            {/* Botão de Exclusão em Massa */}
            {selectedUsers.length > 0 && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleOpenBatchDeleteDialog}
                sx={{ borderRadius: 2, textTransform: 'none', px: 3, py: 1.5 }}
              >
                Excluir Selecionados ({selectedUsers.length})
              </Button>
            )}
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpenUserForm(null)} // Criar novo usuário
              sx={{ borderRadius: 2, textTransform: 'none', px: 3, py: 1.5 }}
            >
              Novo Usuário
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Search and Filters Card */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          {error && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error" action={
                <Button color="inherit" size="small" onClick={() => refetch()}>
                  Tentar Novamente
                </Button>
              }>
                Ocorreu um erro ao carregar as assinaturas.
              </Alert>
            </Box>
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Buscar usuário..."
                value={rawSearchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                  sx: { borderRadius: 2 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Status da Assinatura</InputLabel>
                <Select
                  value={filterStatus}
                  onChange={handleFilterStatusChange}
                  label="Status da Assinatura"
                  startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="premium">Premium</MenuItem>
                  <MenuItem value="premium_cancelado">Premium Cancelado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Você pode adicionar mais filtros aqui se necessário */}
          </Grid>
        </CardContent>
      </Card>

      {/* Subscriptions Table Card */}
      <Card sx={{ borderRadius: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                    checked={users.length > 0 && selectedUsers.length === users.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'nome'}
                    direction={sortBy === 'nome' ? order : 'asc'}
                    onClick={() => handleSort('nome')}
                  >
                    Usuário
                  </TableSortLabel>
                </TableCell>
                {!isMobile && (
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'email'}
                      direction={sortBy === 'email' ? order : 'asc'}
                      onClick={() => handleSort('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'status'}
                    direction={sortBy === 'status' ? order : 'asc'}
                    onClick={() => handleSort('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'data_inicio'}
                    direction={sortBy === 'data_inicio' ? order : 'asc'}
                    onClick={() => handleSort('data_inicio')}
                  >
                    Data Início
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'data_fim'}
                    direction={sortBy === 'data_fim' ? order : 'asc'}
                    onClick={() => handleSort('data_fim')}
                  >
                    Data Fim
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Cancelamento
                </TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={isMobile ? 8 : 9}>
                      <Skeleton variant="rectangular" height={40} />
                    </TableCell>
                  </TableRow>
                ))
              ) : users.length > 0 ? (
                users.map((user) => (
                  <TableRow
                    key={user.id}
                    hover
                    selected={selectedUsers.includes(user.id)}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: user.bloqueado ? alpha(theme.palette.error.light, 0.2) : 'inherit',
                      '&:hover': {
                        bgcolor: user.bloqueado
                          ? alpha(theme.palette.error.light, 0.3)
                          : theme.palette.action.hover,
                      },
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleSelectOne(user.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                          {user.nome.charAt(0).toUpperCase()}
                        </Avatar>
                        <Box>
                          <Typography variant="subtitle2">
                            {user.nome} {user.sobrenome}
                          </Typography>
                          {isMobile && (
                            <Typography variant="caption" color="textSecondary">
                              {user.email}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    {!isMobile && <TableCell>{user.email}</TableCell>}
                    <TableCell>
                      <Chip
                        label={
                          user.status === 'free' ? 'Free' :
                          user.status === 'premium' ? 'Premium' :
                          user.status === 'premium_cancelado' ? 'Premium Cancelado' : 'Desconhecido'
                        }
                        color={
                          user.status === 'premium' ? 'success' :
                          user.status === 'premium_cancelado' ? 'warning' :
                          'default'
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {user.data_inicio ? (
                        <Typography variant="body2">
                          {format(parseISO(user.data_inicio), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}
                        </Typography>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {user.data_fim ? (
                        <Typography variant="body2">
                          {format(parseISO(user.data_fim), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}
                        </Typography>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {user.cancelado_em ? (
                        <Typography variant="body2">
                          {format(parseISO(user.cancelado_em), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}
                        </Typography>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {/* Botão para Editar Assinatura */}
                        <Tooltip title="Editar Assinatura">
                          <IconButton
                            size="small"
                            onClick={() => handleOpenSubscriptionForm(user)}
                            aria-label="editar assinatura"
                            color="secondary"
                            sx={{
                              backgroundColor: alpha(theme.palette.secondary.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                              },
                            }}
                          >
                            <AttachMoneyIcon />
                          </IconButton>
                        </Tooltip>

                        {/* Botão para Atualizar para Premium */}
                        {user.status !== 'premium' && (
                          <Tooltip title="Atualizar para Premium">
                            <IconButton
                              size="small"
                              onClick={() => handleOpenUpgradeDialog(user.id)}
                              aria-label="atualizar para premium"
                              color="success"
                              sx={{
                                backgroundColor: alpha(theme.palette.success.main, 0.1),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.success.main, 0.2),
                                },
                              }}
                            >
                              <SwapHorizIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {/* Botão para Cancelar Assinatura */}
                        {user.status === 'premium' && (
                          <Tooltip title="Cancelar Assinatura">
                            <IconButton
                              size="small"
                              onClick={() => handleOpenCancelDialog(user.id)}
                              aria-label="cancelar assinatura"
                              color="warning"
                              sx={{
                                backgroundColor: alpha(theme.palette.warning.main, 0.1),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.warning.main, 0.2),
                                },
                              }}
                            >
                              <BlockIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {/* Botões existentes: Editar e Excluir */}
                        {/* <Tooltip title="Editar Usuário">
                          <IconButton
                            size="small"
                            color="secondary"
                            aria-label="editar usuário"
                            onClick={() => handleOpenUserForm(user)}
                            sx={{
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                              },
                            }}
                          >
                            <EditIcon />
                          </IconButton> 
                        </Tooltip> */}
                        <Tooltip title="Excluir">
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => handleOpenDeleteDialog(user.id)} // Exclusão individual
                              aria-label="excluir usuário"
                              color="error"
                              disabled={deleteLoading}
                              sx={{
                                backgroundColor: alpha(theme.palette.error.main, 0.1),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.error.main, 0.2),
                                },
                              }}
                            >
                              {deleteLoading ? <CircularProgress size={18} /> : <DeleteIcon />}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={isMobile ? 8 : 9}>
                    <Box sx={{ py: 3, textAlign: 'center' }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Nenhuma assinatura encontrada
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        Tente ajustar os filtros ou adicionar um novo usuário.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        {totalPages > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, page) => setCurrentPage(page)}
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </Card>

      {/* User Form Modal */}
      {openUserForm && (
        <UserForm
          open={openUserForm}
          handleClose={handleCloseUserForm}
          user={selectedUser}
          refreshUsers={refetch}
        />
      )}

      {/* SubscriptionForm Modal */}
      {openSubscriptionForm && subscriptionUser && (
        <SubscriptionForm
          open={openSubscriptionForm}
          handleClose={handleCloseSubscriptionForm}
          user={subscriptionUser}
          refreshUsers={refetch}
        />
      )}

      {/* Cancel Subscription Confirmation Dialog */}
      <Dialog open={openCancelDialog} onClose={handleCloseCancelDialog}>
        <DialogTitle>Cancelar Assinatura Premium</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja cancelar a assinatura premium deste usuário? A assinatura será cancelada ao final do período atual.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog} color="inherit">Cancelar</Button>
          <Button onClick={() => handleCancelSubscription(cancelUserId)} color="warning" variant="contained" disabled={cancelLoading}>
            {cancelLoading ? <CircularProgress size={20} color="inherit" /> : 'Confirmar Cancelamento'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upgrade Subscription Confirmation Dialog */}
      <Dialog open={openUpgradeDialog} onClose={handleCloseUpgradeDialog}>
        <DialogTitle>Atualizar para Premium</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja atualizar a assinatura deste usuário para Premium imediatamente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpgradeDialog} color="inherit">Cancelar</Button>
          <Button onClick={() => handleUpgradeSubscription(upgradeUserId)} color="success" variant="contained" disabled={upgradeLoading}>
            {upgradeLoading ? <CircularProgress size={20} color="inherit" /> : 'Confirmar Atualização'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Batch Delete Confirmation Dialog */}
      <Dialog open={openBatchDeleteDialog} onClose={handleCloseBatchDeleteDialog}>
        <DialogTitle>Excluir Usuários Selecionados</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir os {selectedUsers.length} usuários selecionados? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBatchDeleteDialog} color="inherit">Cancelar</Button>
          <Button onClick={handleBatchDelete} color="error" variant="contained" disabled={batchDeleteLoading}>
            {batchDeleteLoading ? <CircularProgress size={20} color="inherit" /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Excluir Usuário</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir este usuário? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="inherit">Cancelar</Button>
          <Button onClick={() => handleDeleteUser(deleteUserId)} color="error" variant="contained" disabled={deleteLoading}>
            {deleteLoading ? <CircularProgress size={20} color="inherit" /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para Feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default function SubscriptionList() {
  return (
    <QueryClientProvider client={queryClient}>
      <SubscriptionListInner />
    </QueryClientProvider>
  );
}
