import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import {
  Box,
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Card,
  CardContent,
  Fade,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TableSortLabel,
} from '@mui/material';
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  History as HistoryIcon,
} from '@mui/icons-material';

const AdminReportes = () => {
  const [reportes, setReportes] = useState([]);
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('All'); // Estado para o filtro de status
  const [loading, setLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [total, setTotal] = useState(0);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [sortConfig, setSortConfig] = useState({
    field: null,
    direction: 'asc',
  });

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchReportes = async (query = '') => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const res = await fetch(
        `https://medchoices.com.br:3001/admin/reportes-questoes?search=${encodeURIComponent(query)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        setReportes(data.reportes);
        setTotal(data.total || 0);
      } else {
        showSnackbar(data.message || 'Erro ao carregar reportes', 'error');
      }
    } catch (error) {
      console.error('Erro ao carregar reportes:', error);
      showSnackbar('Erro ao carregar reportes do servidor.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchReportesDebounced = useCallback(
    debounce((query) => {
      fetchReportes(query);
    }, 500),
    []
  );

  useEffect(() => {
    fetchReportes();
  }, [refreshKey]);

  const atualizarStatus = async (id, novoStatus) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const res = await fetch(
        `https://medchoices.com.br:3001/admin/reportes-questoes/${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status: novoStatus }),
        }
      );
      const data = await res.json();
      if (data.success) {
        showSnackbar('Status atualizado com sucesso!');
        fetchReportes(search);
      } else {
        showSnackbar(data.message || 'Erro ao atualizar status.', 'error');
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      showSnackbar('Erro no servidor ao atualizar status.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteTargetId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteDialogOpen(false);
    if (!deleteTargetId) return;

    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const res = await fetch(
        `https://medchoices.com.br:3001/admin/reportes-questoes/${deleteTargetId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        showSnackbar('Reporte deletado com sucesso!');
        fetchReportes(search);
      } else {
        showSnackbar(data.message || 'Erro ao deletar reporte.', 'error');
      }
    } catch (error) {
      console.error('Erro ao deletar reporte:', error);
      showSnackbar('Erro no servidor ao deletar reporte.', 'error');
    } finally {
      setLoading(false);
      setDeleteTargetId(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteTargetId(null);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearch(query); // Atualiza o estado para refletir na interface
    fetchReportesDebounced(query); // Usa o debounce para evitar buscas excessivas
  };

  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };

  // Função para lidar com a alteração do filtro de status
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  // Função para lidar com a ordenação
  const handleSort = (field) => {
    let direction = 'asc';
    if (sortConfig.field === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ field, direction });
  };

  // Função para ordenar os reportes com base no sortConfig
  const sortedReportes = React.useMemo(() => {
    let sortableReportes = [...reportes];

    // Aplicar filtro de status
    if (statusFilter !== 'All') {
      sortableReportes = sortableReportes.filter(
        (r) => r.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    // Aplicar ordenação
    if (sortConfig.field) {
      sortableReportes.sort((a, b) => {
        let aField = a[sortConfig.field];
        let bField = b[sortConfig.field];

        // Se o campo for data/hora, converter para Date
        if (sortConfig.field === 'created_at') {
          aField = new Date(aField);
          bField = new Date(bField);
        } else {
          // Transformar para string e converter para minúsculas para comparação consistente
          aField = aField ? aField.toString().toLowerCase() : '';
          bField = bField ? bField.toString().toLowerCase() : '';
        }

        if (aField < bField) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aField > bField) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableReportes;
  }, [reportes, sortConfig, statusFilter]);

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Cabeçalho */}
      <Card
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main + '0A',
          mb: 3,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" color="primary">
                Reportes de Questões
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Visualize e gerencie os reportes feitos pelos usuários sobre questões.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary">
                Total de reportes: {total}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Barra de Busca, Filtro e Atualizar */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                variant="outlined"
                label="Pesquisar Reportes"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="status-filter-label">Filtrar por Status</InputLabel>
                <Select
                  labelId="status-filter-label"
                  label="Filtrar por Status"
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  disabled={loading}
                >
                  <MenuItem value="All">Todos</MenuItem>
                  <MenuItem value="pendente">Pendente</MenuItem>
                  <MenuItem value="em análise">Em Análise</MenuItem>
                  <MenuItem value="resolvido">Resolvido</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Tooltip title="Atualizar lista" arrow>
                <span>
                  <IconButton onClick={handleRefresh} color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Tabela de Reportes */}
      <Card sx={{ borderRadius: 2 }}>
        {loading && reportes.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, py: 4 }}>
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Fade in>
            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow
                    sx={{ backgroundColor: (theme) => theme.palette.primary.main + '1A' }}
                  >
                    {/* <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell> */}
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'usuario_nome'}
                        direction={
                          sortConfig.field === 'usuario_nome' ? sortConfig.direction : 'asc'
                        }
                        onClick={() => handleSort('usuario_nome')}
                      >
                        Usuário
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'questao_enunciado'}
                        direction={
                          sortConfig.field === 'questao_enunciado' ? sortConfig.direction : 'asc'
                        }
                        onClick={() => handleSort('questao_enunciado')}
                      >
                        Questão
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'prova_titulo'}
                        direction={
                          sortConfig.field === 'prova_titulo' ? sortConfig.direction : 'asc'
                        }
                        onClick={() => handleSort('prova_titulo')}
                      >
                        Prova
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'motivo'}
                        direction={sortConfig.field === 'motivo' ? sortConfig.direction : 'asc'}
                        onClick={() => handleSort('motivo')}
                      >
                        Motivo
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'mensagem'}
                        direction={
                          sortConfig.field === 'mensagem' ? sortConfig.direction : 'asc'
                        }
                        onClick={() => handleSort('mensagem')}
                      >
                        Mensagem
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'created_at'}
                        direction={
                          sortConfig.field === 'created_at' ? sortConfig.direction : 'asc'
                        }
                        onClick={() => handleSort('created_at')}
                      >
                        Data/Hora
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <TableSortLabel
                        active={sortConfig.field === 'status'}
                        direction={
                          sortConfig.field === 'status' ? sortConfig.direction : 'asc'
                        }
                        onClick={() => handleSort('status')}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedReportes.map((r) => (
                    <TableRow
                      key={r.id}
                      hover
                      sx={{
                        '&:hover': {
                          backgroundColor: (theme) =>
                            theme.palette.action.hover,
                        },
                      }}
                    >
                      {/* <TableCell>{r.id}</TableCell> */}
                      <TableCell>{`${r.usuario_nome} ${r.usuario_sobrenome}`}</TableCell>
                      <TableCell>{r.questao_enunciado}</TableCell>
                      <TableCell>{r.prova_titulo || 'N/A'}</TableCell>
                      <TableCell>{r.motivo || 'N/A'}</TableCell>
                      <TableCell>{r.mensagem || 'N/A'}</TableCell>
                      <TableCell>
                        {new Date(r.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={r.status.charAt(0).toUpperCase() + r.status.slice(1)}
                          color={
                            r.status.toLowerCase() === 'pendente'
                              ? 'warning'
                              : r.status.toLowerCase() === 'em análise'
                              ? 'info'
                              : 'success'
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Marcar como Em Análise">
                          <span>
                            <IconButton
                              onClick={() =>
                                atualizarStatus(r.id, 'em análise')
                              }
                              disabled={loading}
                              color="warning"
                            >
                              <HistoryIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Marcar como Resolvido">
                          <span>
                            <IconButton
                              onClick={() =>
                                atualizarStatus(r.id, 'resolvido')
                              }
                              disabled={loading}
                              color="success"
                            >
                              <CheckCircleIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Excluir reporte">
                          <span>
                            <IconButton
                              onClick={() => handleDeleteClick(r.id)}
                              disabled={loading}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  {sortedReportes.length === 0 && !loading && (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        align="center"
                        sx={{ py: 4 }}
                      >
                        <Typography variant="subtitle1" color="text.secondary">
                          Nenhum reporte encontrado.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Fade>
        )}
      </Card>

      {/* Diálogo de confirmação de deleção */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja excluir este reporte? Esta ação não pode ser
            desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} disabled={loading}>
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Excluir'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminReportes;
