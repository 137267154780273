// frontend/src/components/UserList.js

import React, { useState, useCallback, useMemo } from 'react';
import {
  Box, Card, CardContent, Typography, Button, Grid, TextField, FormControl,
  InputLabel, Select, MenuItem, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Checkbox, TableSortLabel,
  Pagination, Snackbar, Alert, Avatar, Chip, Tooltip, Skeleton, InputAdornment,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  useTheme, useMediaQuery, alpha, CircularProgress
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon,
  Person as PersonIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import debounce from 'lodash.debounce';
import UserForm from './UserForm'; // Assegure-se de ter este componente

const queryClient = new QueryClient();

// Hook para gerenciar lógica de obtenção de dados
function useUsersData({ searchTerm, filterStatus, filterFuncao, sortBy, order, currentPage }) {
  const query = useQuery({
    queryKey: ['users', searchTerm, filterStatus, filterFuncao, sortBy, order, currentPage],
    queryFn: async () => {
      const params = new URLSearchParams({
        search: searchTerm || '',
        filter: filterStatus || '',
        funcao: filterFuncao || '',
        sortBy: sortBy || 'id',
        order: order || 'desc',
        page: currentPage.toString(),
        limit: '50',
      });

      const response = await fetch(`https://medchoices.com.br:3001/admin/users?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const responseData = await response.json();

      if (!responseData.success) {
        throw new Error(responseData.message || 'Erro ao buscar usuários.');
      }

      // Log para depuração
      console.log('Resposta da API /admin/users:', responseData);

      return responseData;
    },
    keepPreviousData: true,
    staleTime: 1000 * 60, // 1 minuto
    retry: 1,
  });

  return query;
}

function useDebounceValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debounced = useCallback(
    debounce((val) => {
      setDebouncedValue(val);
    }, delay),
    [delay]
  );

  React.useEffect(() => {
    debounced(value);
    // Cancel the debounce on useEffect cleanup.
    return debounced.cancel;
  }, [value, debounced]);

  return debouncedValue;
}

const UserListInner = React.memo(function UserListInner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [rawSearchTerm, setRawSearchTerm] = useState('');
  const searchTerm = useDebounceValue(rawSearchTerm, 300);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterFuncao, setFilterFuncao] = useState('');
  const [sortBy, setSortBy] = useState('id');
  const [order, setOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Modal states
  const [openUserForm, setOpenUserForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [blockUserId, setBlockUserId] = useState(null);
  const [blockAction, setBlockAction] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false); // Feedback visual

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false); // Feedback visual

  // **Novo: Estados para Exclusão em Massa**
  const [openBatchDeleteDialog, setOpenBatchDeleteDialog] = useState(false);
  const [batchDeleteLoading, setBatchDeleteLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const { data, isLoading, error, refetch } = useUsersData({
    searchTerm, filterStatus, filterFuncao, sortBy, order, currentPage
  });

  const users = data?.users || [];
  const totalUsers = data?.totalUsers || 0;
  const totalPages = data?.totalPages || 1;

  // Adicione este log para depuração
  console.log('Dados de usuários no frontend:', users);

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const handleSearchChange = (e) => {
    setRawSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterStatusChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterFuncaoChange = (e) => {
    setFilterFuncao(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field) => {
    const isAsc = sortBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);
  };

  const handleSelectAll = (event) => {
    setSelectedUsers(event.target.checked ? users.map(user => user.id) : []);
  };

  const handleSelectOne = (userId) => {
    setSelectedUsers(prev =>
      prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
    );
  };

  const handleBlock = async (userId, action) => {
    setBlockLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/users/${userId}/block`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ bloquear: action }),
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({
          open: true,
          message: action ? 'Usuário bloqueado com sucesso!' : 'Usuário desbloqueado com sucesso!',
          severity: 'success',
        });
        refetch();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Erro ao alterar status do usuário:', error);
      setSnackbar({
        open: true,
        message: 'Erro ao alterar status do usuário.',
        severity: 'error',
      });
    } finally {
      setBlockLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    setDeleteLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/users/${userId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({
          open: true,
          message: 'Usuário excluído com sucesso!',
          severity: 'success',
        });
        refetch();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
      setSnackbar({
        open: true,
        message: 'Erro ao excluir usuário.',
        severity: 'error',
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  // **Novo: Função para Exclusão em Massa com Melhor Tratamento de Erros**
  const handleBatchDelete = async () => {
    setBatchDeleteLoading(true);
    try {
      // Verifica se há usuários selecionados
      if (selectedUsers.length === 0) {
        throw new Error('Nenhum usuário selecionado para exclusão.');
      }

      // Envia solicitações de exclusão em paralelo usando Promise.allSettled
      const deletePromises = selectedUsers.map(userId =>
        fetch(`https://medchoices.com.br:3001/admin/users/${userId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(res => res.json())
      );

      const results = await Promise.allSettled(deletePromises);

      // Processa os resultados
      const failedDeletes = [];
      results.forEach((result, index) => {
        const userId = selectedUsers[index];
        if (result.status === 'fulfilled') {
          if (!result.value.success) {
            failedDeletes.push({ userId, message: result.value.message || 'Erro desconhecido.' });
          }
        } else {
          failedDeletes.push({ userId, message: result.reason.message || 'Erro desconhecido.' });
        }
      });

      if (failedDeletes.length > 0) {
        // Constrói uma mensagem detalhada
        const failedMessage = failedDeletes.map(fd => `ID ${fd.userId}: ${fd.message}`).join('\n');
        throw new Error(`Falha ao excluir ${failedDeletes.length} usuário(s):\n${failedMessage}`);
      }

      // Se todas as exclusões foram bem-sucedidas
      setSnackbar({
        open: true,
        message: 'Usuários excluídos com sucesso!',
        severity: 'success',
      });
      setSelectedUsers([]); // Limpa a seleção após a exclusão
      refetch();
    } catch (error) {
      console.error('Erro ao excluir usuários em massa:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Erro ao excluir usuários em massa.',
        severity: 'error',
      });
    } finally {
      setBatchDeleteLoading(false);
      setOpenBatchDeleteDialog(false);
    }
  };

  const handleOpenUserForm = (user = null) => {
    setSelectedUser(user);
    setOpenUserForm(true);
  };

  const handleCloseUserForm = () => {
    setOpenUserForm(false);
    setSelectedUser(null);
  };

  const handleOpenBlockDialog = (userId, action) => {
    setBlockUserId(userId);
    setBlockAction(action);
    setOpenBlockDialog(true);
  };

  const handleCloseBlockDialog = () => {
    setOpenBlockDialog(false);
    setBlockUserId(null);
    setBlockAction(false);
  };

  const handleConfirmBlock = () => {
    if (blockUserId !== null) {
      handleBlock(blockUserId, blockAction);
    }
    handleCloseBlockDialog();
  };

  const handleOpenDeleteDialog = (userId) => {
    setDeleteUserId(userId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteUserId(null);
  };

  const handleConfirmDelete = () => {
    if (deleteUserId !== null) {
      handleDelete(deleteUserId);
    }
    handleCloseDeleteDialog();
  };

  // **Novo: Funções para Exclusão em Massa**
  const handleOpenBatchDeleteDialog = () => {
    setOpenBatchDeleteDialog(true);
  };

  const handleCloseBatchDeleteDialog = () => {
    setOpenBatchDeleteDialog(false);
  };

  return (
    <Box maxWidth="xl" >
      {/* Header Card */}
      <Card elevation={0} sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.05), mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <Box>
              <Typography variant="h4" fontWeight="bold" color="primary" component="h1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PersonIcon /> Gerenciamento de Usuários
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {isLoading ? 'Carregando...' : error ? 'Erro ao carregar usuários' : `Total de ${totalUsers} usuários cadastrados`}
              </Typography>
            </Box>
            {/* **Novo: Botão de Exclusão em Massa** */}
            {selectedUsers.length > 0 && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleOpenBatchDeleteDialog}
                sx={{ borderRadius: 2, textTransform: 'none', px: 3, py: 1.5 }}
              >
                Excluir Selecionados ({selectedUsers.length})
              </Button>
            )}
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpenUserForm(null)} // Criar novo usuário
              sx={{ borderRadius: 2, textTransform: 'none', px: 3, py: 1.5 }}
            >
              Novo Usuário
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Search and Filters Card */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          {error && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error" action={
                <Button color="inherit" size="small" onClick={() => refetch()}>
                  Tentar Novamente
                </Button>
              }>
                Ocorreu um erro ao carregar os usuários.
              </Alert>
            </Box>
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Buscar usuário..."
                value={rawSearchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                  sx: { borderRadius: 2 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filterStatus}
                  onChange={handleFilterStatusChange}
                  label="Status"
                  startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="ativo">Ativos</MenuItem>
                  <MenuItem value="bloqueado">Bloqueados</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Função</InputLabel>
                <Select
                  value={filterFuncao}
                  onChange={handleFilterFuncaoChange}
                  label="Função"
                  startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="editor">Editor</MenuItem> {/* **Novo: Opção Editor Adicionada** */}
                  <MenuItem value="usuario">Usuário</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Users Table Card */}
      <Card sx={{ borderRadius: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                    checked={users.length > 0 && selectedUsers.length === users.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'nome'}
                    direction={sortBy === 'nome' ? order : 'asc'}
                    onClick={() => handleSort('nome')}
                  >
                    Usuário
                  </TableSortLabel>
                </TableCell>
                {!isMobile && (
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'email'}
                      direction={sortBy === 'email' ? order : 'asc'}
                      onClick={() => handleSort('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'funcao'}
                    direction={sortBy === 'funcao' ? order : 'asc'}
                    onClick={() => handleSort('funcao')}
                  >
                    Função
                  </TableSortLabel>
                </TableCell>
                {/* **Nova Coluna: Faculdade** */}
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'faculdade'}
                    direction={sortBy === 'faculdade' ? order : 'asc'}
                    onClick={() => handleSort('faculdade')}
                  >
                    Faculdade
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'bloqueado'}
                    direction={sortBy === 'bloqueado' ? order : 'asc'}
                    onClick={() => handleSort('bloqueado')}
                  >
                    Ativo?
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'email_verificado'}
                    direction={sortBy === 'email_verificado' ? order : 'asc'}
                    onClick={() => handleSort('email_verificado')}
                  >
                    Verificado?
                  </TableSortLabel>
                </TableCell>
                {!isMobile && (
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'created_at'}
                      direction={sortBy === 'created_at' ? order : 'asc'}
                      onClick={() => handleSort('created_at')}
                    >
                      Data de Criação
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={isMobile ? 10 : 11}>
                      <Skeleton variant="rectangular" height={40} />
                    </TableCell>
                  </TableRow>
                ))
              ) : users.length > 0 ? (
                users.map((user) => (
                  <TableRow
                    key={user.id}
                    hover
                    selected={selectedUsers.includes(user.id)}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: user.bloqueado ? alpha(theme.palette.error.light, 0.2) : 'inherit',
                      '&:hover': {
                        bgcolor: user.bloqueado
                          ? alpha(theme.palette.error.light, 0.3)
                          : theme.palette.action.hover,
                      },
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleSelectOne(user.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                          {user.nome.charAt(0).toUpperCase()}
                        </Avatar>
                        <Box>
                          <Typography variant="subtitle2">
                            {user.nome} {user.sobrenome}
                          </Typography>
                          {isMobile && (
                            <Typography variant="caption" color="textSecondary">
                              {user.email}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    {!isMobile && <TableCell>{user.email}</TableCell>}
                    <TableCell>
                      {/* **Atualizado: Adicionada Lógica para a Função "Editor" com Cor Diferente** */}
                      <Chip
                        label={user.funcao.charAt(0).toUpperCase() + user.funcao.slice(1)}
                        color={
                          user.funcao === 'admin'
                            ? 'secondary'
                            : user.funcao === 'editor'
                              ? 'info' // **Cor Diferente para "Editor"**
                              : 'default'
                        }
                        size="small"
                      />
                    </TableCell>
                    {/* **Nova Coluna: Faculdade** */}
                    <TableCell>
                      {user.faculdade || 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={user.bloqueado ? 'Bloqueado' : 'Ativo'}
                        color={user.bloqueado ? 'error' : 'success'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {/* Log detalhado do status de verificação */}
                      {console.log(`Usuário ID ${user.id} - Email Verificado: ${user.email_verificado}`)}
                      {user.email_verificado ? (
                        <Tooltip title="E-mail Verificado">
                          <CheckCircleIcon color="success" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="E-mail Não Verificado">
                          <BlockIcon color="error" />
                        </Tooltip>
                      )}
                    </TableCell>
                    {!isMobile && (
                      <TableCell>
                        {user.created_at ? format(parseISO(user.created_at), 'dd/MM/yyyy', { locale: ptBR }) : 'N/A'}
                      </TableCell>
                    )}
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Editar">
                          <IconButton
                            size="small"
                            color="primary"
                            aria-label="editar usuário"
                            onClick={() => handleOpenUserForm(user)}
                            sx={{
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                              },
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={user.bloqueado ? 'Desbloquear' : 'Bloquear'}>
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => handleOpenBlockDialog(user.id, !user.bloqueado)}
                              aria-label={user.bloqueado ? 'desbloquear usuário' : 'bloquear usuário'}
                              color={user.bloqueado ? 'secondary' : 'warning'}
                              disabled={blockLoading}
                              sx={{
                                backgroundColor: user.bloqueado
                                  ? alpha(theme.palette.success.main, 0.1)
                                  : alpha(theme.palette.warning.main, 0.1),
                                '&:hover': {
                                  backgroundColor: user.bloqueado
                                    ? alpha(theme.palette.success.main, 0.2)
                                    : alpha(theme.palette.warning.main, 0.2),
                                },
                              }}
                            >
                              {blockLoading ? <CircularProgress size={18} /> : user.bloqueado ? <CheckCircleIcon /> : <BlockIcon />}
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Excluir">
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => handleOpenDeleteDialog(user.id)}
                              aria-label="excluir usuário"
                              color="error"
                              disabled={deleteLoading}
                              sx={{
                                backgroundColor: alpha(theme.palette.error.main, 0.1),
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.error.main, 0.2),
                                },
                              }}
                            >
                              {deleteLoading ? <CircularProgress size={18} /> : <DeleteIcon />}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={isMobile ? 10 : 11}>
                    <Box sx={{ py: 3, textAlign: 'center' }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Nenhum usuário encontrado
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        Tente ajustar os filtros ou adicionar um novo usuário.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        {totalPages > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, page) => setCurrentPage(page)}
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </Card>

      {/* User Form Modal */}
      {openUserForm && (
        <UserForm
          open={openUserForm}
          handleClose={() => handleCloseUserForm()}
          user={selectedUser}
          refreshUsers={refetch}
        />
      )}

      {/* Block/Unblock Confirmation Dialog */}
      <Dialog open={openBlockDialog} onClose={handleCloseBlockDialog}>
        <DialogTitle>{blockAction ? 'Bloquear Usuário' : 'Desbloquear Usuário'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {blockAction
              ? 'Tem certeza que deseja bloquear este usuário?'
              : 'Tem certeza que deseja desbloquear este usuário?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBlockDialog} color="inherit">Cancelar</Button>
          <Button onClick={handleConfirmBlock} color="primary" variant="contained" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Excluir Usuário</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir este usuário? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="inherit">Cancelar</Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      {/* **Novo: Batch Delete Confirmation Dialog** */}
      <Dialog open={openBatchDeleteDialog} onClose={handleCloseBatchDeleteDialog}>
        <DialogTitle>Excluir Usuários Selecionados</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir os {selectedUsers.length} usuários selecionados? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBatchDeleteDialog} color="inherit">Cancelar</Button>
          <Button onClick={handleBatchDelete} color="error" variant="contained" autoFocus disabled={batchDeleteLoading}>
            {batchDeleteLoading ? <CircularProgress size={20} /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para Feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default function UserList() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserListInner />
    </QueryClientProvider>
  );
}
