// src/pages/UserReportPage.js
import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CircularProgress,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  Button,
  Paper,
  Stack,
  TextField,
  ThemeProvider,
  createTheme,
  CssBaseline,
  IconButton,
  Tooltip,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {
  SaveAlt as SaveAltIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Refresh as RefreshIcon,
  Person as PersonIcon,
  Email as EmailIcon,
  CalendarToday as CalendarTodayIcon,
  Timer as TimerIcon,
} from '@mui/icons-material';
import { Line, Bar, Pie } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  ChartTooltip,
  Legend
);

// Funções de fetch
const fetchUserReport = async () => {
    const token = localStorage.getItem('token');
    const res = await fetch(`https://medchoices.com.br:3001/admin/relatorios/usuario/completo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) throw new Error('Erro ao obter relatório do usuário');
    return res.json();
  };

// Tema personalizado
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3D9A8B',
    },
    secondary: {
      main: '#114C5F',
    },
    success: {
      main: '#4CAF50',
    },
    info: {
      main: '#2196F3',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
        },
      },
    },
  },
});

// Função auxiliar para formatar números de forma segura
const formatNumber = (num, decimals = 2) => {
  return typeof num === 'number' && !isNaN(num) ? num.toFixed(decimals) : 'N/A';
};

// Componente de cartão de estatística
const StatCard = ({ title, value, icon: Icon, color = 'primary' }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ p: 1.5, borderRadius: 2, bgcolor: `${color}.light` }}>
          <Icon sx={{ color: `${color}.main` }} />
        </Box>
        <Box flex={1}>
          <Typography variant="subtitle2" color="text.secondary">
            {title}
          </Typography>
          <Typography variant="h5" component="div">
            {value}
          </Typography>
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

const UserReportPage = () => {
  // Estados principais
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch de dados do usuário
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['userReport'],
    queryFn: fetchUserReport,
    refetchOnWindowFocus: false,
    staleTime: 60000,
  });

  // Dados extraídos
  const reportData = useMemo(() => {
    if (data && data.success) {
      return data.reportData;
    }
    return null;
  }, [data]);

  // Hooks de useMemo para dados dos gráficos
  const chartDataEvolucao = useMemo(() => {
    if (!reportData || !reportData.evolucaoDesempenho) return null;
    const labels = reportData.evolucaoDesempenho.map(item => `${item.mes}/${item.ano}`);
    const valores = reportData.evolucaoDesempenho.map(item => item.media_pontuacao);
    return {
      labels,
      datasets: [
        {
          label: 'Média de Pontuação',
          data: valores,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
          fill: true,
          tension: 0.4,
        },
      ],
    };
  }, [reportData]);

  const chartOptionsEvolucao = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Evolução do Desempenho',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const chartDataCategoria = useMemo(() => {
    if (!reportData || !reportData.desempenho_por_categoria) return null;
    const labels = reportData.desempenho_por_categoria.map(item => item.categoria);
    const valores = reportData.desempenho_por_categoria.map(item => item.media_pontuacao);
    return {
      labels,
      datasets: [
        {
          label: 'Média de Pontuação',
          data: valores,
          backgroundColor: 'rgba(61, 154, 139, 0.5)',
          borderColor: 'rgba(61, 154, 139, 1)',
          borderWidth: 1,
        },
      ],
    };
  }, [reportData]);

  const chartOptionsCategoria = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Desempenho por Categoria',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };

  const chartDataDistribuicao = useMemo(() => {
    if (!reportData || !reportData.desempenho_por_categoria) return null;
    return {
      labels: reportData.desempenho_por_categoria.map(item => item.categoria),
      datasets: [
        {
          label: 'Pontuação Média',
          data: reportData.desempenho_por_categoria.map(item => item.media_pontuacao),
          backgroundColor: reportData.desempenho_por_categoria.map(
            () => `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, 0.5)`
          ),
          borderColor: reportData.desempenho_por_categoria.map(
            () => `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, 1)`
          ),
          borderWidth: 1,
        },
      ],
    };
  }, [reportData]);

  const chartOptionsDistribuicao = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Distribuição de Pontuação por Categoria',
      },
    },
  };

  // Função para gerar feedback
  const gerarFeedback = () => {
    if (reportData && reportData.feedback) {
      return reportData.feedback.feedback_texto;
    }
    return 'Nenhum feedback disponível.';
  };

  // Função para gerar recomendações
  const gerarRecomendacoes = () => {
    if (reportData && reportData.feedback) {
      return reportData.feedback.recomendacoes;
    }
    return 'Nenhuma recomendação disponível.';
  };

  // Exportação de Relatório para Excel
  const handleExportExcel = () => {
    if (!reportData) return;

    const wb = XLSX.utils.book_new();
    const wsData = [
      ['Relatório do Usuário'],
      ['Nome', `${reportData.userInfo.nome} ${reportData.userInfo.sobrenome}`],
      ['Email', reportData.userInfo.email],
      ['Função', reportData.userInfo.funcao],
      ['Criado em', format(new Date(reportData.userInfo.criado_em), 'dd/MM/yyyy')],
      ['Total de Provas Realizadas', reportData.total_provas_realizadas],
      ['Aproveitamento Médio', `${formatNumber(Number(reportData.aproveitamento_medio), 2)}%`],
      ['Tempo Médio por Prova', `${formatNumber(Number(reportData.tempo_medio_prova), 2)} minutos`],
      ['Última Prova Feita', reportData.ultima_prova_feita ? reportData.ultima_prova_feita.titulo : 'N/A'],
      [
        'Data da Última Prova',
        reportData.ultima_prova_feita
          ? format(new Date(reportData.ultima_prova_feita.data_realizacao), 'dd/MM/yyyy, HH:mm:ss')
          : 'N/A',
      ],
      [],
      ['Evolução do Desempenho'],
      ['Mês', 'Ano', 'Média de Pontuação'],
      ...reportData.evolucaoDesempenho.map(item => [item.mes, item.ano, item.media_pontuacao]),
      [],
      ['Desempenho por Categoria'],
      ['Categoria', 'Média de Pontuação'],
      ...reportData.desempenho_por_categoria.map(item => [item.categoria, item.media_pontuacao]),
      [],
      ['Feedback'],
      [reportData.feedback.feedback_texto],
      ['Recomendações'],
      [reportData.feedback.recomendacoes],
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
    XLSX.writeFile(wb, 'relatorio_usuario.xlsx');
    console.log('Exportado relatório para Excel.');
  };

  // Exportação de Relatório para PDF
  const handleExportPDF = () => {
    if (!reportData) return;

    const doc = new jsPDF();
    doc.text('Relatório do Usuário', 14, 20);

    // Dados Básicos
    doc.text(`Nome: ${reportData.userInfo.nome} ${reportData.userInfo.sobrenome}`, 14, 30);
    doc.text(`Email: ${reportData.userInfo.email}`, 14, 40);
    doc.text(`Função: ${reportData.userInfo.funcao}`, 14, 50);
    doc.text(`Criado em: ${format(new Date(reportData.userInfo.criado_em), 'dd/MM/yyyy')}`, 14, 60);
    doc.text(`Total de Provas Realizadas: ${reportData.total_provas_realizadas}`, 14, 70);
    doc.text(`Aproveitamento Médio: ${formatNumber(Number(reportData.aproveitamento_medio), 2)}%`, 14, 80);
    doc.text(`Tempo Médio por Prova: ${formatNumber(Number(reportData.tempo_medio_prova), 2)} minutos`, 14, 90);
    doc.text(`Última Prova Feita: ${reportData.ultima_prova_feita ? reportData.ultima_prova_feita.titulo : 'N/A'}`, 14, 100);
    doc.text(
      `Data da Última Prova: ${
        reportData.ultima_prova_feita
          ? format(new Date(reportData.ultima_prova_feita.data_realizacao), 'dd/MM/yyyy, HH:mm:ss')
          : 'N/A'
      }`,
      14,
      110
    );

    // Evolução do Desempenho
    doc.autoTable({
      startY: 120,
      head: [['Mês', 'Ano', 'Média de Pontuação']],
      body: reportData.evolucaoDesempenho.map(item => [item.mes, item.ano, item.media_pontuacao]),
      theme: 'grid',
      title: 'Evolução do Desempenho',
    });

    // Desempenho por Categoria
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.autoTable({
      startY: finalY,
      head: [['Categoria', 'Média de Pontuação']],
      body: reportData.desempenho_por_categoria.map(item => [item.categoria, item.media_pontuacao]),
      theme: 'grid',
      title: 'Desempenho por Categoria',
    });

    // Feedback e Recomendações
    const finalY2 = doc.lastAutoTable.finalY + 10;
    doc.text('Feedback', 14, finalY2);
    doc.text(reportData.feedback.feedback_texto, 14, finalY2 + 10);
    doc.text('Recomendações', 14, finalY2 + 20);
    doc.text(reportData.feedback.recomendacoes, 14, finalY2 + 30);

    doc.save('relatorio_usuario.pdf');
    console.log('Exportado relatório para PDF.');
  };

  if (isLoading || !reportData) {
    console.log('Carregando relatório do usuário...');
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !data.success) {
    console.error('Erro ao carregar relatório do usuário:', error);
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography color="error">Erro ao carregar dados.</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', p: isMobile ? 2 : 4 }}>
        {/* Ações: Atualizar e Exportar */}
        <Paper elevation={0} sx={{ p: 2, mb: 4, borderRadius: 2 }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Relatório Completo</Typography>
            <Stack direction="row" spacing={1}>
              <Tooltip title="Atualizar dados">
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Exportar PDF">
                <Button
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={handleExportPDF}
                >
                  {!isMobile && 'PDF'}
                </Button>
              </Tooltip>
              <Tooltip title="Exportar Excel">
                <Button
                  variant="outlined"
                  startIcon={<SaveAltIcon />}
                  onClick={handleExportExcel}
                >
                  {!isMobile && 'Excel'}
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
        </Paper>

        {/* Seções de Informações */}
        <Grid container spacing={3}>
          {/* Resumo Geral */}
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Resumo Geral
            </Typography>
          </Grid>

          {/* Cartões de Estatística */}
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total de Provas Realizadas"
              value={reportData.total_provas_realizadas}
              icon={PersonIcon}
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Aproveitamento Médio"
              value={`${formatNumber(Number(reportData.aproveitamento_medio), 2)}%`}
              icon={EmailIcon}
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Tempo Médio por Prova"
              value={`${formatNumber(Number(reportData.tempo_medio_prova), 2)} minutos`}
              icon={TimerIcon}
              color="success"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Última Prova Feita"
              value={reportData.ultima_prova_feita ? reportData.ultima_prova_feita.titulo : 'N/A'}
              icon={CalendarTodayIcon}
              color="info"
            />
          </Grid>

          {/* Evolução do Desempenho */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Evolução do Desempenho
                </Typography>
                <Box sx={{ height: 400, p: 1 }}>
                  {chartDataEvolucao ? (
                    <Line data={chartDataEvolucao} options={chartOptionsEvolucao} />
                  ) : (
                    <Typography>Nenhum dado disponível para exibir o gráfico.</Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Desempenho por Categoria */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Desempenho por Categoria
                </Typography>
                <Box sx={{ height: 400, p: 1 }}>
                  {chartDataCategoria ? (
                    <Bar data={chartDataCategoria} options={chartOptionsCategoria} />
                  ) : (
                    <Typography>Nenhum dado disponível para exibir o gráfico.</Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Distribuição de Pontuação por Categoria */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Distribuição de Pontuação por Categoria
                </Typography>
                <Box sx={{ height: 400, p: 1 }}>
                  {chartDataDistribuicao ? (
                    <Pie data={chartDataDistribuicao} options={chartOptionsDistribuicao} />
                  ) : (
                    <Typography>Nenhum dado disponível para exibir o gráfico.</Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Histórico de Provas */}
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Histórico de Provas
            </Typography>
            {/* Busca de Provas Realizadas */}
            <TextField
              label="Buscar Prova"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              // Implementar funcionalidade de busca conforme necessário
            />

            {/* Tabela de Provas Realizadas */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Título da Prova</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Pontuação</TableCell>
                    <TableCell>Tempo Gasto</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.historico_provas && reportData.historico_provas.length > 0 ? (
                    reportData.historico_provas.map((prova, index) => (
                      <TableRow key={index}>
                        <TableCell>{prova.titulo}</TableCell>
                        <TableCell>{format(new Date(prova.data), 'dd/MM/yyyy')}</TableCell>
                        <TableCell>{prova.pontuacao}%</TableCell>
                        <TableCell>{prova.tempo_gasto} minutos</TableCell>
                        <TableCell>
                          <Chip
                            label={prova.status}
                            color={
                              prova.status === 'Finalizada'
                                ? 'success'
                                : prova.status === 'Em Andamento'
                                ? 'info'
                                : 'error'
                            }
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography>Nenhuma prova realizada.</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Feedback e Recomendações */}
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Feedback e Recomendações
            </Typography>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Feedback
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {gerarFeedback()}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Recomendações
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {gerarRecomendacoes()}
                </Typography>
                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                  Ver Mais Recomendações
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default UserReportPage;
